import React, { createContext, FC, ReactNode, useState } from "react";
import { PopupT } from "../../models/models";
import Popup from "./Popup";
import { v4 } from "uuid";

/**
 * Show all opened popup window over the page
 * Managed with redux state
 * */

type PopupContextT = {
  createPopup: (item: PopupT) => void;
  removePopup: () => void;
  removeAllPopups: () => void;
};

export const PopupContext = createContext<PopupContextT>({} as PopupContextT);

type PopupProviderPropTypes = {
  children: ReactNode;
};

const PopupProvider: FC<PopupProviderPropTypes> = ({ children }) => {
  const [items, setItems] = useState<PopupT[]>([]);

  const createPopup = (item: PopupT) => {
    const popupID = v4();
    setItems((prev) => [...prev, { ...item, id: popupID }]);
  };

  const removePopup = () => {
    setItems((prev) => {
      const copy = prev.slice();
      copy.pop();

      return copy;
    });
  };

  const removeAllPopups = () => {
    setItems([]);
  };

  return (
    <PopupContext.Provider
      value={{
        createPopup,
        removePopup,
        removeAllPopups,
      }}
    >
      {items.map((popup) => (
        <Popup
          key={popup.id}
          body={popup.body}
          header={popup.header}
          footer={popup.footer}
          className={popup.className}
        />
      ))}
      {children}
    </PopupContext.Provider>
  );
};
export default PopupProvider;
