import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { FC, SetStateAction, useContext, useEffect, useState } from "react";
import { SubscriptionT } from "../../models/models";
import { v4 } from "uuid";
import addSubscriptionAdmin from "../../utils/queries/addSubscriptionAdmin";
import { AuthContext } from "../../services/Auth";
import dayjs from "dayjs";
import usePopup from "../../utils/hooks/usePopup";
import { toast } from "react-toastify";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { VISITS_AMOUNT } from "../../utils/constants";

//@ts-ignore
import styles from "./styles.module.scss";

type FormData = {
  subscription: string;
  visits: number;
  startDate: string;
};

type AddSubscriptionAdminPopupPropTypes = {
  userId: string;
  subscriptions: SubscriptionT[];
  getClientSubscriptions: () => {};
};

const schema = yup.object().shape({
  subscription: yup.string().required(),
  visits: yup.number().required(),
  startDate: yup.date(),
});

const AddSubscriptionAdminPopup: FC<AddSubscriptionAdminPopupPropTypes> = (
  props
) => {
  const { subscriptions, getClientSubscriptions, userId } = props;
  const { control, handleSubmit, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const [price, setPrice] = useState<number[]>([]);
  const selectedSubId = watch("subscription");
  const { removePopup } = usePopup();

  useEffect(() => {
    if (selectedSubId) {
      const selectedSub: SubscriptionT | undefined = subscriptions.find(
        ({ id }) => id === selectedSubId
      );
      setPrice(selectedSub!.price.split(",").map((val) => +val.trim()));
    }
  }, [selectedSubId]);

  const onSubmit = async (data: FormData) => {
    const payload = {
      subscriptionId: data.subscription,
      id: v4(),
      expireDate: dayjs(data.startDate || undefined).add(1, "month").toISOString(),
      startDate: dayjs(data.startDate || undefined).toISOString(),
      visits: data.visits,
    };
    const refetchUsers = new CustomEvent("refetch-users");
    document.dispatchEvent(refetchUsers);
    await addSubscriptionAdmin(userId, payload);
    await getClientSubscriptions();
    toast.success("Абонемент додано", {
      autoClose: 3000,
    });
    removePopup();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id="add-subscription-admin"
      className={styles.wrapper}
    >
      <Controller
        name="subscription"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel
              id="subscription-select-label"
              sx={{ background: "#fff" }}
            >
              Абонемент
            </InputLabel>
            <Select
              labelId="subscription-select-label"
              id="subscription-select"
              {...field}
            >
              {subscriptions.map((sub) => (
                <MenuItem value={sub.id}>{sub.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="visits"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel
              id="num-sessions-select-label"
              sx={{ background: "#fff" }}
            >
              Кількість занять
            </InputLabel>
            <Select
              labelId="num-sessions-select-label"
              id="num-sessions-select"
              {...field}
              disabled={!price.length}
            >
              {price.map((val, index) =>
                !!val ? (
                  <MenuItem key={index} value={VISITS_AMOUNT[index]}>
                    {VISITS_AMOUNT[index]}
                  </MenuItem>
                ) : (
                  <></>
                )
              )}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="startDate"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth sx={{ m: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Початок занять"
                inputFormat="DD-MM-YYYY"
                {...field}
                renderInput={(params) => <TextField {...params} {...field} />}
              />
            </LocalizationProvider>
          </FormControl>
        )}
      />
    </form>
  );
};

export default AddSubscriptionAdminPopup;
