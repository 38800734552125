import React, { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import addVisitAdmin from "../../utils/queries/addVisitAdmin";
import getUserById from "../../utils/queries/getUserById";
import dayjs from "dayjs";
import usePopup from "../../utils/hooks/usePopup";
import { Button } from "@mui/material";
import { toast } from "react-toastify";

//@ts-ignore
import styles from "./styles.module.scss";

const MarkVisitAdmin = () => {
  const [data, setData] = useState("");
  const { createPopup, removeAllPopups } = usePopup();
  useEffect(() => {
    if (data) {
      console.log(data, '!!!');
      
      try {
        const parsedData = JSON.parse(data);
        if (parsedData?.userId && parsedData?.subscriptionId) {
          getUserById(parsedData.userId).then((user: any) => {
            const visitDate = dayjs().format("DD-MM-YYYY HH:mm:ss");
            addVisitAdmin(
              parsedData.userId,
              parsedData.subscriptionId,
              visitDate,
              user.subscriptions
            ).then(() => {
              createPopup({
                header: <>Успішно!</>,
                body: <p>Відвідування успішно відмічено!</p>,
                footer: <Button onClick={removeAllPopups}>Закрити</Button>,
              });
            });
          });
        }
      } catch (err: any) {
        toast.error("Не вдалося прочитати QR код", {
          autoClose: 1000,
        });
        setData('');
      }
    }
  }, [data]);
  return (
    <div className={styles.wrapper}>
      <p>
        Проскануйте QR код абонементу клієнта щоб відмітити відвідування (на
        поточний день)
      </p>
      <QrReader
        onResult={(result: any, error) => {
          if (!!result) {
            setData(result?.text);
          }

          if (!!error) {
            console.info(error);
          }
        }}
        containerStyle={{ width: "100%" }}
        constraints={{
            facingMode: 'environment'
        }}
      />
    </div>
  );
};

export default MarkVisitAdmin;
