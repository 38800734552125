import React, { useContext, useEffect, useState } from "react";
import BasketCard from "../../components/BasketCard/BasketCard";
import ClientPageWrapper from "../../components/ClientPageWrapper/ClientPageWrapper";
import { SubscriptionT } from "../../models/models";
import { AuthContext } from "../../services/Auth";
import useFirestore from "../../utils/hooks/useFirestoreCollection";
import { clss } from "../../utils/helpers/styleHelpers";

//@ts-ignore
import styles from "./styles.module.scss";
import { useNavigate } from "react-router";
import { RoutesE } from "../../routes/routes";
import { Button } from "@mui/material";

const ClientSubscriptions = () => {
  const { currentUser } = useContext(AuthContext);
  const { items } = useFirestore<SubscriptionT>("subscriptions");
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Мої абонементи";
  }, []);

  useEffect(() => {
    if (
      currentUser &&
      (!currentUser?.birthDate || !currentUser?.fullName || !currentUser?.phone)
    ) {
      navigate(RoutesE.PROFILE);
    }
  }, [currentUser]);

  return (
    <ClientPageWrapper>
      {!!currentUser && (
        <div
          className={clss([
            styles.wrapper,
            currentUser?.subscriptions?.length
              ? styles.dataBg
              : styles.noDataBg,
          ])}
        >
          {!currentUser?.subscriptions?.length && (
            <div className={styles.noData}>
              <p>Абонементи відсутні</p>
              <Button
                onClick={() => navigate(RoutesE.CLIENT_SUBSCRIPTIONS)}
                variant="outlined"
                sx={{ color: "#fff", margin: 2 }}
              >
                Вибрати наявні
              </Button>
            </div>
          )}
          <ul>
            {currentUser?.subscriptions?.map((sub, index) => {
              const item = items.find((item) => item.id === sub.subscriptionId);
              const data = {
                label: item?.label,
                duration: item?.duration,
                groupType: item?.groupType,
                visitsPassed: sub.visitsPassed,
                visits: sub?.visits,
                expireDate: sub.expireDate,
                startDate: sub.startDate,
                id: sub.id,
                subscriptionId: sub.subscriptionId,
                comment: sub?.comment,
              };
              return <BasketCard item={data} index={index} isClientView />;
            })}
          </ul>
        </div>
      )}
    </ClientPageWrapper>
  );
};

export default ClientSubscriptions;
