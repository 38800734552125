import React, { useEffect } from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import UserList from "../../components/UserList/UserList";

const Clients = () => {
  useEffect(() => {
    document.title = "Клієнти";
  }, []);

  return (
    <Dashboard>
      <UserList />
    </Dashboard>
  );
};

export default Clients;
