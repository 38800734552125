import React, { FC } from "react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

//@ts-ignore
import styles from "./styles.module.scss";

type NextButtonPropTypes = {
  nextElementId: string;
};

const NextButton: FC<NextButtonPropTypes> = (props) => {
  const { nextElementId } = props;

  const handleNextSection = () => {
    const nextSection = document.getElementById(nextElementId);
    nextSection!.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <KeyboardDoubleArrowDownIcon
      sx={{ color: "#fff", zIndex: 10 }}
      onClick={handleNextSection}
      className={styles.nextButton}
      fontSize="large"
    />
  );
};

export default NextButton;
