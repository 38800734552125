import React, { FC } from "react";
import { IconButton, Tooltip } from "@mui/material";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import { CSVLink } from "react-csv";

type ExportToExcelPropTypes = {
  data: any;
};

const ExportToExcel: FC<ExportToExcelPropTypes> = ({ data }) => {
  return (
    <CSVLink data={data} filename='телефонні номери клієнтів.csv'>
      <IconButton>
        <Tooltip title="Експорт номерів телефонів">
          <SendToMobileIcon />
        </Tooltip>
      </IconButton>
    </CSVLink>
  );
};

export default ExportToExcel;
