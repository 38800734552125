import ClientNews from "../components/ClientNews/ClientNews";
import CartPage from "../pages/CartPage/CartPage";
import ClientContacts from "../pages/ClientContacts/ClientContacts";
import ClientSubscriptions from "../pages/ClientSubscriptions/ClientSubscriptions";
import ClientSubsriptionsWrapper from "../pages/ClientSubsriptionsWrapper/ClientSubsriptionsWrapper";
import DefaultPage from "../pages/DefaultPage/DefaultPage";
import DeleteAccount from "../pages/DeleteAccount/DeleteAccount";
import ProfilePage from "../pages/ProfilePage/ProfilePage";
import { RoutesE } from "./routes";

const privateRouterClient = [
  { path: RoutesE.HOME, element: <ClientSubscriptions /> },
  { path: RoutesE.BASKET, element: <CartPage /> },
  {
    path: RoutesE.CLIENT_SUBSCRIPTIONS,
    element: <ClientSubsriptionsWrapper />,
  },
  { path: RoutesE.CLIENT_CONTACTS, element: <ClientContacts /> },
  { path: RoutesE.CLIENT_NEWS, element: <ClientNews /> },
  { path: RoutesE.PROFILE, element: <ProfilePage /> },
  { path: RoutesE.DELETE_ACCOUNT, element: <DeleteAccount /> },
  { path: "*", element: <DefaultPage /> },
];

export default privateRouterClient;
