import React, { FC, useContext, useEffect, useState } from "react";
import { toPng } from "html-to-image";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import Groups2Icon from "@mui/icons-material/Groups2";
import GradeIcon from "@mui/icons-material/Grade";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ClientSubscriptionT, SubscriptionT } from "../../models/models";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { GroupTypeUkrE } from "../../models/enums";
import dayjs from "dayjs";
import { AuthContext } from "../../services/Auth";
import QRCode from "react-qr-code";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

//@ts-ignore
import styles from "./styles.module.scss";

type ExportSubscriptionPropTypes = {
  subscription: SubscriptionT;
};

const ExportSubscription: FC<ExportSubscriptionPropTypes> = (props) => {
  const { subscription } = props;
  const { currentUser } = useContext(AuthContext);

  const downloadImage = () => {
    const element = document.getElementById("subscription-markup");
    if (element) {
      toPng(element, {
        style: {
          opacity: "1",
        },
        canvasWidth: 1080,
        canvasHeight: 1920,
        pixelRatio: 0.5,
      }).then(function (dataUrl) {
        const link = document.createElement("a");
        link.download = "абонемент.png";
        link.href = dataUrl;
        link.click();
      });
    }
  };

  return (
    <>
      <div id="subscription-markup" className={styles.subscriptionWrapper}>
        <h2>{subscription.label}</h2>
        <Stack direction="row" alignItems="center" spacing={1}>
          <AccessTimeIcon sx={{ color: "#fff", fontSize: "1rem" }} />
          <Typography variant="subtitle1">
            Тривалість {subscription.duration} хв.
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          {subscription.groupType === 0 && (
            <PersonIcon sx={{ color: "#fff", fontSize: "1rem" }} />
          )}
          {subscription.groupType === 1 && (
            <PeopleIcon sx={{ color: "#fff", fontSize: "1rem" }} />
          )}
          {subscription.groupType === 2 && (
            <Groups2Icon sx={{ color: "#fff", fontSize: "1rem" }} />
          )}
          <Typography variant="subtitle1">
            {GroupTypeUkrE[subscription.groupType]}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="subtitle1">
            {subscription.visits} відвідувань(ня)
          </Typography>
        </Stack>
        <div>
          {[
            ...new Array(+(subscription?.visits as unknown as string) || 0),
          ].map((_, index) => (
            <GradeIcon sx={{ color: "#fff", fontSize: "1.5rem" }} key={index} />
          ))}
        </div>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ marginTop: 1 }}
        >
          {/*@ts-ignore*/}
          <Typography variant="subtitle1">
            Діє з{" "}
            {dayjs(
              (subscription as unknown as ClientSubscriptionT).startDate
            ).format("DD.MM.YYYY")}{" "}
            до{" "}
            {dayjs(
              (subscription as unknown as ClientSubscriptionT).expireDate
            ).format("DD.MM.YYYY")}
          </Typography>
        </Stack>
        <QRCode
          value={JSON.stringify({
            userId: currentUser!.id,
            subscriptionId: subscription.id,
          })}
          className={styles.qrCode}
        />
      </div>
      <Tooltip title="Завантажити абонемент (PNG)">
        <IconButton onClick={downloadImage} className={styles.downloadButton}>
          <SaveAltIcon sx={{ color: "#fff" }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ExportSubscription;
