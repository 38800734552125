import React, { FC, useCallback, useEffect, useState } from "react";
import { ClientSubscriptionT, SubscriptionT } from "../../../../models/models";
import useFirestore from "../../../../utils/hooks/useFirestoreCollection";
import isSubscriptionActive from "../../helpers/isSubscriptionActive";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import usePopup from "../../../../utils/hooks/usePopup";
import AddVisitAdmin from "../../../../Popups/AddVisitAdmin/AddVisitAdmin";
import { Alert, Button, Paper, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { clss } from "../../../../utils/helpers/styleHelpers";
import AddIcon from "@mui/icons-material/Add";
import AddSubscriptionAdminPopup from "../../../../Popups/AddSubscriptionAdminPopup/AddSubscriptionAdminPopup";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import removeSubscriptionAdmin from "../../../../utils/queries/removeSubscriptionAdmin";
import { toast } from "react-toastify";
import getUserById from "../../../../utils/queries/getUserById";
import EditIcon from "@mui/icons-material/Edit";
import EditClientSubscriptionPopup from "../EditClientSubscriptionPopup/EditClientSubscriptionPopup";

//@ts-ignore
import styles from "./styles.module.scss";
import { isMobileOrTablet } from "../../../../utils/helpers/mediaQueries";

type ClientSubscriptionsPropTypes = {
  userId: string;
};

const ClientSubscriptions: FC<ClientSubscriptionsPropTypes> = (props) => {
  const { userId } = props;
  const { items: subscriptionsData } =
    useFirestore<SubscriptionT>("subscriptions");
  const { createPopup, removePopup } = usePopup();
  const [subscriptions, setSubscriptions] = useState<ClientSubscriptionT[]>([]);
  const [starTooltipIndex, setStarTooltipIndex] = useState(-1);

  const handleClick = useCallback((e: any) => {
    setTimeout(() => {
      setStarTooltipIndex(-1);
    }, 3000);
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    getClientSubscriptions();
  }, [userId]);

  const getClientSubscriptions = async () => {
    const user: any = await getUserById(userId);
    if (user) {
      setSubscriptions(user?.subscriptions || []);
    }
  };

  const handleAddVisit = (subscriptionId: string) => {
    createPopup({
      header: <>Додати відвідування</>,
      body: (
        <AddVisitAdmin
          userId={userId}
          subscriptionId={subscriptionId}
          subscriptions={subscriptions}
          getClientSubscriptions={getClientSubscriptions}
        />
      ),
      footer: (
        <>
          <Button onClick={removePopup}>Закрити</Button>
          <Button
            type="submit"
            form="add-visit-admin"
            variant="contained"
            sx={{ color: "#fff" }}
          >
            Додати
          </Button>
        </>
      ),
    });
  };

  const handleAddSubscription = () => {
    createPopup({
      header: <>Додати абонемент</>,
      body: (
        <AddSubscriptionAdminPopup
          subscriptions={subscriptionsData}
          getClientSubscriptions={getClientSubscriptions}
          userId={userId}
        />
      ),
      footer: (
        <>
          <Button onClick={removePopup}>Закрити</Button>
          <Button type="submit" form="add-subscription-admin">
            Додати
          </Button>
        </>
      ),
    });
  };

  const handleEditSubscription = (sub: ClientSubscriptionT) => {
    createPopup({
      header: <>Редагувати дані абонементу</>,
      body: (
        <EditClientSubscriptionPopup
          subscription={sub}
          subscriptions={subscriptions}
          userId={userId}
          getClientSubscriptions={getClientSubscriptions}
        />
      ),
      footer: (
        <>
          <Button onClick={removePopup}>Відміна</Button>
          <Button
            variant="contained"
            type="submit"
            form="edit-client-subscription"
            sx={{ color: "#fff" }}
          >
            Зберегти
          </Button>
        </>
      ),
    });
  };

  const handleRemoveSubscription = (subscriptionId: string) => {
    const confirmRemove = async () => {
      await removeSubscriptionAdmin(userId, subscriptionId, subscriptions);
      await getClientSubscriptions();
      toast.success("Абонемент видалено", {
        autoClose: 3000,
      });
      removePopup();
    };

    createPopup({
      header: <>Підтвердіть видалення абонементу</>,
      body: (
        <Alert severity="error">
          Ви дійсно бажаєте видалити абонемент? Цю дію неможливо буде відмінити.
        </Alert>
      ),
      footer: (
        <>
          <Button onClick={removePopup}>Закрити</Button>
          <Button onClick={confirmRemove}>Видалити</Button>
        </>
      ),
    });
  };

  return (
    <>
      {!subscriptions.length && (
        <Alert severity="warning">
          У даного користувача абонементів не знайдено!
        </Alert>
      )}
      <ul className={styles.wrapper}>
        {subscriptions?.map((sub) => {
          const subData = subscriptionsData.find(
            (s) => s.id === sub.subscriptionId
          );
          const isActive = isSubscriptionActive(
            sub.startDate,
            sub.expireDate,
            sub.visits === Object.keys(sub?.visitsPassed).length
          );
          const visitsLeft = sub.visits - Object.keys(sub?.visitsPassed).length;
          return (
            <li className={clss([styles.subItem])} key={sub.id}>
              <Paper
                elevation={2}
                className={clss([
                  styles.subItemBody,
                  isActive ? styles.active : styles.nonActive,
                ])}
              >
                <div>
                  <h3>{subData?.label}</h3>
                  <p>
                    Відвідування ({Object.keys(sub?.visitsPassed)?.length}/
                    {sub?.visits}
                    ):{" "}
                  </p>
                  <div className={styles.visits}>
                    {Object.keys(sub?.visitsPassed)
                      ?.sort((a, b) => dayjs(a).diff(dayjs(b)))
                      .map((visit, index) => (
                        <Tooltip
                          title={dayjs(visit).format("DD.MM.YYYY")}
                          key={visit}
                          disableHoverListener={isMobileOrTablet()}
                          disableFocusListener={isMobileOrTablet()}
                          disableTouchListener={isMobileOrTablet()}
                          onClick={
                            isMobileOrTablet()
                              ? () => setStarTooltipIndex(index)
                              : undefined
                          }
                          open={
                            isMobileOrTablet()
                              ? index === starTooltipIndex
                              : undefined
                          }
                        >
                          <StarIcon
                            key={visit}
                            sx={{ fontSize: "2rem", color: "#ffbd2f" }}
                          />
                        </Tooltip>
                      ))}
                    {new Array(visitsLeft || 0)
                      .fill(null)
                      ?.map((visit, index) => (
                        <StarBorderIcon
                          key={index}
                          sx={{ fontSize: "2rem", color: "#ffbd2f" }}
                        />
                      ))}
                    {!!visitsLeft && (
                      <Tooltip title="Додати відвідування">
                        <AddCircleIcon
                          onClick={() => handleAddVisit(sub.id)}
                          className={styles.addButton}
                        />
                      </Tooltip>
                    )}
                  </div>
                  <div>
                    <p>
                      Діє з {dayjs(sub.startDate).format("DD.MM.YYYY")} по{" "}
                      {dayjs(sub.expireDate).format("DD.MM.YYYY")}
                    </p>
                  </div>
                  {sub?.comment && <p>Коментар: {sub.comment}</p>}
                </div>
                <div className={styles.controlSection}>
                  <Tooltip title="Редагувати абонемент">
                    <EditIcon
                      onClick={() => handleEditSubscription(sub)}
                      className={styles.editButton}
                    />
                  </Tooltip>
                  <Tooltip title="Видалити абонемент">
                    <DeleteForeverIcon
                      onClick={() => handleRemoveSubscription(sub.id)}
                      className={styles.deleteButton}
                    />
                  </Tooltip>
                </div>
              </Paper>
            </li>
          );
        })}
      </ul>
      <div className={styles.addButtonWrapper}>
        <Button
          onClick={handleAddSubscription}
          variant="contained"
          sx={{ color: "#fff" }}
          startIcon={<AddIcon sx={{ color: "#fff" }} />}
        >
          Додати абонемент
        </Button>
      </div>
    </>
  );
};

export default ClientSubscriptions;
