import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { isMobileOrTablet } from "../../utils/helpers/mediaQueries";
import DesktopAdminGrid from "../DesktopAdminGrid/DesktopAdminGrid";
import MobileAdminGrid from "../MobileAdminGrid/MobileAdminGrid";
import { SearchBar } from "../SearchBar/SearchBar";
import ExportToExcel from "../ExportToExcel/ExportToExcel";
import { useLocation } from "react-router";
import { RoutesE } from "../../routes/routes";
import { UserT } from "../../models/models";

//@ts-ignore
import styles from "./styles.module.scss";
import joinPhoneNumberString from "../ExportToExcel/helpers/joinPhoneNumberString";

type AdminTablePropTypes = {
  items: any[];
  tableData: { value: string; label: string; type?: "date"; list: any }[];
  actions: {
    icon: (item: any) => ReactNode;
    label: string;
    action: any;
  }[];
  searchFieldName: string;
  searchTerm?: string;
  pageSize?: number;
  sortDirection?: "asc" | "desc";
  setSearchTerm?: Dispatch<SetStateAction<string>>;
  setPageSize?: Dispatch<SetStateAction<number>>;
  setSearchField?: Dispatch<SetStateAction<string>>;
  setSortDirection?: Dispatch<SetStateAction<"asc" | "desc">>;
  loadMore?: () => void;
};

const AdminTable: FC<AdminTablePropTypes> = (props) => {
  const {
    items,
    tableData,
    actions,
    searchFieldName,
    searchTerm,
    setSearchTerm,
  } = props;
  const [data, setData] = useState<any>([]);
  const { pathname } = useLocation();

  useEffect(() => {
    setData(items);
  }, [items]);

  const gridProps = {
    items: data,
    data: tableData as any,
    actions,
  };

  const handleSearch = (value: string) => {
    setData(
      items.filter((item) =>
        item[searchFieldName].toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  return (
    <div>
      <div className={styles.filters}>
        <SearchBar onSearch={handleSearch} />
        {pathname === RoutesE.HOME && (
          <div className={styles.exportPhonesButtonWrapper}>
            <ExportToExcel
              data={data
                ?.filter((item: UserT) => !!item.phone)
                ?.map((item: UserT) => [joinPhoneNumberString(item.phone)])}
            />
          </div>
        )}
      </div>
      {isMobileOrTablet() ? (
        <MobileAdminGrid {...gridProps} />
      ) : (
        <DesktopAdminGrid {...gridProps} />
      )}
    </div>
  );
};

export default AdminTable;
