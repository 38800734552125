import dayjs from "dayjs";

const getDateFromTimestamp = (timestamp: { seconds: number, nanoseconds: number}) => {
    const unixTimestamp = timestamp.seconds + timestamp.nanoseconds / 1000000000;

    const dayjsObject = dayjs.unix(unixTimestamp);

    return dayjsObject;
};

export default getDateFromTimestamp;