import React, { useContext, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { AuthContext } from "../../../../services/Auth";
import { useLocation, useNavigate } from "react-router";
import { RoutesE } from "../../../../routes/routes";
import { Divider } from "@mui/material";
import ClientNav from "../../../ClientNav/ClientNav";
import { isMobileOrTablet } from "../../../../utils/helpers/mediaQueries";
import { getTitle } from "../../helpers/getTitle";

//@ts-ignore
import styles from "./styles.module.scss";

const ClientHeader = () => {
  const { logOut } = useContext(AuthContext);
  const { currentUser } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileNavX, setMobileNavX] = useState(-100);

  const toggleVisibility = () => {
    setMobileNavX((prev) => (prev === -100 ? 0 : -100));
  };

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = async () => {
    await logOut!();
    navigate(RoutesE.HOME);
  };

  const navigateToProfile = () => {
    navigate(RoutesE.PROFILE);
  };

  return (
    <div className={styles.root}>
      <AppBar elevation={0} className={styles.appBar}>
        <Toolbar className={styles.toolBar}>
          <ClientNav
            mobileNavX={mobileNavX}
            toggleVisibility={toggleVisibility}
          />
          {isMobileOrTablet() && (
            <h1 className={styles.title}>{getTitle(location.pathname)}</h1>
          )}
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={handleMenuOpen}
          >
            {currentUser?.photo ? (
              <img src={currentUser.photo} className={styles.photo} />
            ) : (
              <AccountCircleIcon sx={{ color: "#fff" }} />
            )}
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            className={styles.contextMenu}
          >
            <p className={styles.fullName}>{currentUser?.fullName}</p>
            <Divider />
            <MenuItem onClick={navigateToProfile}>Мій профіль</MenuItem>
            <MenuItem onClick={handleSignout}>Вийти</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default ClientHeader;
