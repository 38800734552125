import React, { useCallback, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  User,
} from "firebase/auth";
import { auth } from "../firebase";
import { UserT } from "../models/models";
import { RouterProvider } from "react-router-dom";
import {
  privateRouterAdmin,
  privateRouterClient,
  publicRouter,
} from "../routes/router";
import { RoutesE } from "../routes/routes";
import PopupProvider from "../components/Popup/PopupProvider";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import dayjs from "dayjs";
import { UserRoleE } from "../models/enums";
import DefaultPage from "../pages/DefaultPage/DefaultPage";
import BasketProvider from "./Basket";
import getUserById from "../utils/queries/getUserById";
import { collection, doc, getFirestore, setDoc } from "firebase/firestore";
import { updateProfile } from "firebase/auth";
import { toast } from "react-toastify";
import CongratsPopup from "../Popups/CongratsPopup/CongratsPopup";

type FormDataT = {
  email: string;
  password?: string;
  birthDate: string;
  fullName: string;
  phone?: string;
};

type AuthContextT = {
  currentUser?: UserT | null;
  signUp?: (email: string, password: string, data: FormDataT) => void;
  signIn?: (email: string, password: string) => void;
  logOut?: () => void;
  loading?: boolean;
  addNewUser?: (uid: string, user: any) => void;
};

export const AuthContext = React.createContext<AuthContextT>({});

export const AuthProvider = () => {
  const [showCongrats, setShowCongrats] = useState(false);
  const [currentUser, setCurrentUser] = useState<UserT | null>();
  const [pending, setPending] = useState(true);
  const [loading, setLoading] = useState(false);
  async function addNewUser(uid: string, user: any) {
    const db = getFirestore();
    const usersCollectionRef = collection(db, "users");
    const newUserRef = doc(usersCollectionRef, uid);
    const newUser = { id: uid, ...user };
    await setDoc(newUserRef, newUser);
  }

  useEffect(() => {
    document.addEventListener("update-current-user", handleUpdateCurrentUser);

    return () => {
      document.removeEventListener(
        "update-current-user",
        handleUpdateCurrentUser
      );
    };
  }, [currentUser?.id]);

  const handleUpdateCurrentUser = useCallback(() => {
    if (currentUser?.id) {
      getUserById(currentUser?.id).then((userData) => {
        if (userData) setCurrentUser(userData as unknown as UserT);
        setPending(false);
      });
    }
  }, [currentUser?.id]);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user && user.emailVerified) {
        const uid = user.uid;
        const userData = await getUserById(uid);
        if (userData) {
          setCurrentUser(userData as unknown as UserT);
        }

        if (auth.currentUser?.displayName !== userData?.role + "") {
          await updateProfile(auth.currentUser as User, {
            displayName: userData?.role + "",
          });
        }

        setPending(false);
        // ...
      } else {
        setPending(false);
        setCurrentUser(null);
      }
    });

    const isShown = localStorage.getItem("08.03.2023");
    if (!isShown && dayjs().format("DD.MM.YYYY") === "08.03.2023") {
      setShowCongrats(true);
    }
  }, []);

  const signUp = async (email: string, password: string, data: FormDataT) => {
    setLoading(true);
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await sendEmailVerification(user);
      await addNewUser(user.uid, {
        email: data.email,
        fullName: data.fullName,
        birthDate: data.birthDate,
        role: UserRoleE.CLIENT,
        registrationDate: dayjs().toISOString(),
        subscriptions: [],
        phone: data.phone,
        visits: 0,
      });
      updateProfile(user, {
        displayName: UserRoleE.CLIENT + "",
      });
      toast.success("Вітаємо! Ви успішно зареєструвались!");
      setLoading(false);
      signOut(auth);
      window.location.pathname = RoutesE.EMAIL_NOT_VERIFIED;
    } catch (err: any) {
      if (err?.code === "auth/email-already-in-use") {
        toast.error("Вказана email адреса вже використовується");
      } else {
        toast.error(err?.message);
      }
      console.log({ err });
    } finally {
      setLoading(false);
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      setLoading(true);
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      setLoading(false);
      if (!user) throw "Failed to login. Try one more time";
      if (!user.emailVerified) {
        // await sendEmailVerification(user);
        await signOut(auth);
        window.location.pathname = RoutesE.EMAIL_NOT_VERIFIED;
      } else {
        window.location.pathname = RoutesE.EMAIL_NOT_VERIFIED;
        // window.location.pathname = RoutesE.HOME;
      }
    } catch (err: any) {
      setLoading(false);
      if (err?.code === "auth/wrong-password") {
        toast.error("Неправильний пароль", { autoClose: 3000 });
      } else if (err?.code === "auth/user-not-found") {
        toast.error("Користувача не знайдено", { autoClose: 3000 });
      } else {
        toast.error(err?.code, { autoClose: 3000 });
        console.log(err?.code);
      }
    }
  };

  const logOut = async () => {
    await signOut(auth);
    setCurrentUser(null);
    window.location.pathname = RoutesE.HOME;
  };

  if (pending) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        signUp,
        signIn,
        loading,
        logOut,
        addNewUser,
      }}
    >
      <BasketProvider>
        <PopupProvider>
          {showCongrats && currentUser && (
            <CongratsPopup setShowCongrats={setShowCongrats} />
          )}
          <RouterProvider
            router={
              !auth.currentUser
                ? publicRouter
                : auth.currentUser?.displayName === UserRoleE.ADMIN + ""
                ? privateRouterAdmin
                : privateRouterClient
            }
            fallbackElement={<DefaultPage />}
          />
        </PopupProvider>
      </BasketProvider>
    </AuthContext.Provider>
  );
};
