import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ClientPageWrapper from "../../components/ClientPageWrapper/ClientPageWrapper";
import { AuthContext } from "../../services/Auth";
import * as yup from "yup";
import {
  atLeastOneSpaceRegExp,
  maskPhoneRegex,
} from "../../utils/helpers/regexp";
import dayjs from "dayjs";
import { TextField, Grid, Paper, Button, Alert } from "@mui/material";
import InputMask from "react-input-mask";
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { isMobileOrTablet } from "../../utils/helpers/mediaQueries";
import ukLocale from "date-fns/locale/uk";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ImageUploadField from "../../components/ImageUploadField/ImageUploadField";
import updateClient from "../../utils/queries/updateClient";
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";

//@ts-ignore
import styles from "./styles.module.scss";
import { toast } from "react-toastify";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router";
import { RoutesE } from "../../routes/routes";

const schema = yup.object().shape({
  email: yup.string().email("Невірний формат").required("Email обов'язковий."),
  fullName: yup
    .string()
    .matches(atLeastOneSpaceRegExp, "Прізвище та ім'я обов'язкові.")
    .required("Прізвище та ім'я обов'язкові."),
  birthDate: yup.string().required("Обов'язкове поле."),
  phone: yup
    .string()
    .matches(maskPhoneRegex, "Неправильний номер телефону")
    .required("Телефон обов'язковий"),
});

const ProfilePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: currentUser?.email,
      phone: currentUser?.phone || "+380",
      fullName: currentUser?.fullName,
    },
  });
  const [birthDate, setBirthDate] = useState(
    currentUser?.birthDate ? dayjs(currentUser?.birthDate) : null
  );

  const onSubmit = async (data: any) => {
    setLoading(true);
    const payload = {
      ...currentUser,
      ...data,
      birthDate: birthDate?.toISOString(),
    };
    await updateClient(currentUser?.id as string, payload);
    setLoading(false);
    toast.success("Зміни збережено", {
      autoClose: 1000,
    });
    setIsUpdated(true);
  };

  return (
    <ClientPageWrapper>
      <div className={styles.wrapper}>
        {(!currentUser?.phone ||
          !currentUser.birthDate ||
          !currentUser.fullName) &&
          !isUpdated && (
            <Alert severity="error" className={styles.alert}>
              Будь ласка, заповніть обов'язкові поля для більш ефективної
              взаємодії з сайтом.
            </Alert>
          )}
        <Paper className={styles.formWrapper}>
          <form
            onSubmit={handleSubmit((data) => onSubmit({ ...data, birthDate }))}
            id="profile-form"
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Прізвище та ім'я"
                  error={!!errors.fullName}
                  helperText={errors?.fullName?.message as string}
                  {...register("fullName")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  error={!!errors.email}
                  helperText={errors?.email?.message as string}
                  {...register("email")}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"phone"}
                  render={({ field }) => (
                    <InputMask
                      mask="+38 (099) 999 99 99"
                      value={field.value}
                      onChange={field.onChange}
                    >
                      {/*@ts-ignore*/}
                      {() => (
                        <TextField
                          label="Телефон"
                          sx={{ width: "100%" }}
                          error={!!errors.phone}
                          helperText={errors?.phone?.message as string}
                        />
                      )}
                    </InputMask>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={ukLocale}
                >
                  {isMobileOrTablet() ? (
                    <MobileDatePicker
                      label="Дата народження"
                      inputFormat="DD.MM.YYYY"
                      value={birthDate}
                      onChange={setBirthDate as any}
                      minDate={dayjs()
                        .subtract(80, "years")
                        .format("YYYY-DD-MM")}
                      maxDate={dayjs()
                        .subtract(18, "years")
                        .format("YYYY-DD-MM")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...register("birthDate")}
                          error={!!errors.birthDate}
                          helperText={errors?.birthDate?.message as string}
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  ) : (
                    <DesktopDatePicker
                      label="Дата народження"
                      inputFormat="DD.MM.YYYY"
                      value={birthDate}
                      onChange={setBirthDate as any}
                      minDate={dayjs()
                        .subtract(80, "years")
                        .format("YYYY-DD-MM")}
                      maxDate={dayjs()
                        .subtract(18, "years")
                        .format("YYYY-DD-MM")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...register("birthDate")}
                          error={!!errors.birthDate}
                          helperText={errors?.birthDate?.message as string}
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  )}
                </LocalizationProvider>
              </Grid>
              {/* <ImageUploadField defaultImage={currentUser?.photo} /> */}
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 4,
                }}
              >
                <Button
                  form="profile-form"
                  type="submit"
                  variant="contained"
                  disabled={loading}
                  sx={{ width: "100%", color: "#fff" }}
                  startIcon={
                    loading ? <CircularProgress size="small" /> : <SaveIcon />
                  }
                >
                  Зберегти зміни
                </Button>
              </Grid>
              {isUpdated && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 4,
                  }}
                >
                  <Button
                    onClick={() => (window.location.pathname = RoutesE.HOME)}
                    variant="outlined"
                    sx={{ width: "100%" }}
                  >
                    Мої абонементи
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </Paper>
      </div>
    </ClientPageWrapper>
  );
};

export default ProfilePage;
