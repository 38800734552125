import dayjs from "dayjs";
import React, { FC, useContext } from "react";
import AnimatedText from "react-animated-text-content";
import { AuthContext } from "../../services/Auth";

//@ts-ignore
import styles from "./styles.module.scss";

const CongratsPopup: FC<{ setShowCongrats: any }> = ({ setShowCongrats }) => {
  const { currentUser } = useContext(AuthContext);
  const greet = `Вітаємо, ${currentUser?.fullName}!`;
  const handleClose = () => {
    const date = dayjs().format("DD.MM.YYYY");
    localStorage.setItem(date, "true");
    setShowCongrats(false);
  };
  return (
    <div className={styles.overlay}>
      <div className={styles.popup}>
        <AnimatedText
          type="words"
          animation={{
            x: "200px",
            y: "-20px",
            scale: 1.1,
            ease: "ease-in-out",
          }}
          animationType="blocks"
          interval={0.01}
          duration={1}
          tag="h2"
          includeWhiteSpaces
          threshold={1}
          rootMargin="0%"
        >
          {greet}
        </AnimatedText>
        <AnimatedText
          type="words"
          animation={{
            x: "200px",
            y: "-20px",
            scale: 1.1,
            ease: "ease-in-out",
          }}
          animationType="blocks"
          interval={0.01}
          duration={2}
          tag="p"
          includeWhiteSpaces
          threshold={1}
          rootMargin="0%"
        >
          З нагоди Міжнародного жіночого дня, бажаємо Вам щастя, здоров'я та
          невичерпної енергії у танцях та у всіх життєвих подорожах!
        </AnimatedText>
        <AnimatedText
          type="words"
          animation={{
            x: "200px",
            y: "-20px",
            scale: 1.1,
            ease: "ease-in-out",
          }}
          animationType="blocks"
          interval={0.01}
          duration={2}
          tag="p"
          includeWhiteSpaces
          threshold={1}
          rootMargin="0%"
        >
          Нехай Ваші рухи завжди будуть легкими та граціозними, а кожне
          тренування – незабутнім враженням.
        </AnimatedText>
        <AnimatedText
          type="words"
          animation={{
            x: "200px",
            y: "-20px",
            scale: 1.1,
            ease: "ease-in-out",
          }}
          animationType="blocks"
          interval={0.01}
          duration={2}
          tag="p"
          includeWhiteSpaces
          threshold={1}
          rootMargin="0%"
        >
          З повагою, Команда студії WhyNotBallet
        </AnimatedText>
        <button className={styles.button} onClick={handleClose}>
          Закрити
        </button>
      </div>
    </div>
  );
};

export default CongratsPopup;
