import React from "react";
import { IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSwiper } from "swiper/react";

//@ts-ignore
import styles from "./styles.module.scss";

const SwiperNav = () => {
  const swiper = useSwiper();
  return (
    <IconButton
      sx={{ position: "absolute", zIndex: 2, right: 30, top: "47vh" }}
      onClick={() => swiper.slideNext()}
    >
      <ArrowForwardIosIcon sx={{ color: "#ffffff55", fontSize: "4rem" }} />
    </IconButton>
  );
};

export default SwiperNav;
