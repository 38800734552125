import { Badge, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutesE } from "../../../routes/routes";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";
import {
  isMobile,
  isMobileOrTablet,
} from "../../../utils/helpers/mediaQueries";
import CloseIcon from "@mui/icons-material/Close";
import { BasketContext } from "../../../services/Basket";

//@ts-ignore
import styles from "./styles.module.scss";

const PromoNav = () => {
  const navigate = useNavigate();
  const [mobileNavX, setMobileNavX] = useState(-100);
  const [itemsCount, setItemsCount] = useState(0);
  const { items } = useContext(BasketContext);

  useEffect(() => {
    setItemsCount(items?.length || 0);
  }, [items?.length]);

  const handleLogin = () => {
    navigate(RoutesE.LOGIN);
  };

  const handleSignUp = () => {
    navigate(RoutesE.SIGN_UP);
  };

  const handleScroll = (elementId: string) => {
    if (isMobileOrTablet()) {
      setMobileNavX(-100);
    }
    const el = document.getElementById(elementId);
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const toggleVisibility = () => {
    setMobileNavX((prev) => (prev === -100 ? 0 : -100));
  };

  const Nav = () => (
    <nav>
      <ul>
        <li onClick={() => handleScroll("splash-section")}>WNB</li>
        <li onClick={() => handleScroll("about-us-section")}>Про нас</li>
        <li onClick={() => handleScroll("why-section")}>Чому ми?</li>
        <li onClick={() => handleScroll("subscriptions-section")}>
          Абонементи
        </li>
        <li onClick={() => handleScroll("teachers-section")}>Викладачі</li>
        <li onClick={() => handleScroll("gallery-section")}>Галерея</li>
        <li onClick={() => handleScroll("news-section")}>Новини</li>
        <li onClick={() => handleScroll("reviews-section")}>Відгуки</li>
        <li onClick={() => handleScroll("contacts-section")}>Контакти</li>
      </ul>
    </nav>
  );

  const navigateToBasket = () => {
    navigate(RoutesE.BASKET);
  };

  const Auth = () => (
    <div className={styles.authSection}>
      <button className={styles.loginButton} onClick={handleLogin}>
        Вхід
      </button>
      <button className={styles.signUpButton} onClick={handleSignUp}>
        Реєстрація
      </button>
      {!!itemsCount && (
        <div onClick={navigateToBasket}>
          <IconButton aria-label="basket" disabled color="primary">
            <Badge
              badgeContent={itemsCount}
              color="primary"
              className={styles.basketIcon}
            >
              <ShoppingCartIcon sx={{ color: "#fff" }} />
            </Badge>
          </IconButton>
        </div>
      )}
    </div>
  );

  return isMobile() ? (
    <div className={styles.mobileNawWrapper}>
      <div className={styles.mobileMenuHeader}>
        <IconButton
          color="primary"
          aria-label="menu"
          className={styles.mobileMenuIcon}
          onClick={toggleVisibility}
        >
          {mobileNavX === 0 ? (
            <CloseIcon sx={{ color: "#fff" }} />
          ) : (
            <MenuIcon sx={{ color: "#fff" }} />
          )}
        </IconButton>
        <Auth />
      </div>
      <div className={styles.mobileNav} style={{ left: `${mobileNavX}%` }}>
        <Nav />
      </div>
    </div>
  ) : (
    <div className={styles.navWrapper}>
      <Nav />
      <Auth />
    </div>
  );
};

export default PromoNav;
