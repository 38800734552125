import React, { ReactNode, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
} from "@material-ui/core";
import dayjs from "dayjs";
import getDateFromTimestamp from "../../utils/helpers/getDateFromTimestamp";

//@ts-ignore
import styles from "./styles.module.scss";

interface Props {
  items: any[];
  data: {
    value: string;
    label: string;
    type?: "date" | "timestamp";
    list?: any;
  }[];
  actions?: {
    icon: any;
    label: string;
    action: any;
  }[];
}

const MobileAdminGrid: React.FC<Props> = ({ items, data, actions }) => {
  const tableData = items.map((item) => data.map(({ value }) => item[value]));
  const [menuIndex, setMenuIndex] = useState(-1);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };

  const handleClose = () => {
    setMenuIndex(-1);
    setAnchorEl(null);
  };

  return (
    <Swiper spaceBetween={0} slidesPerView={2}>
      {data.map(({ label }, index) => (
        <SwiperSlide key={label}>
          <TableContainer sx={{ overflow: "hidden" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{label}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, rowIndex) =>
                  data[index]?.type === "date" ? (
                    <TableRow
                      key={rowIndex}
                      onClick={(e: any) => handleClick(e, rowIndex)}
                      sx={{
                        backgroundColor:
                          menuIndex === rowIndex ? "#f6f6f6" : "#fff",
                        height: 90,
                      }}
                    >
                      <TableCell
                        sx={{
                          height: 90,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {dayjs(row[index]).format("DD.MM.YYYY")}
                      </TableCell>
                    </TableRow>
                  ) : data[index]?.type === "timestamp" ? (
                    <TableRow
                      key={rowIndex}
                      onClick={(e: any) => handleClick(e, rowIndex)}
                      sx={{
                        backgroundColor:
                          menuIndex === rowIndex ? "#f6f6f6" : "#fff",
                        height: 90,
                      }}
                    >
                      <TableCell
                        sx={{
                          height: 90,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {getDateFromTimestamp(row[index]).format("DD.MM.YYYY")}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow
                      key={rowIndex}
                      onClick={(e: any) => handleClick(e, rowIndex)}
                      sx={{
                        backgroundColor:
                          menuIndex === rowIndex ? "#f6f6f6" : "#fff",
                        height: 90,
                      }}
                      className={styles.scroll}
                    >
                      <TableCell
                        sx={{
                          overflow: "hidden",
                          height: 90,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {data[index]?.list
                          ? data[index].list[row[index]]
                          : row[index]}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            elevation={2}
          >
            {actions?.map(({ icon, label, action }) => (
              <MenuItem
                onClick={() => {
                  action(items[menuIndex]);
                  setAnchorEl(null);
                  setMenuIndex(-1);
                }}
                sx={{ display: "flex", gap: 2 }}
              >
                {icon(items[menuIndex])}
                {label}
              </MenuItem>
            ))}
          </Menu>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default MobileAdminGrid;
