import React, { useCallback, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import SubscriptionSlide from "./components/SubscriptionSlide/SubscriptionSlide";
import TitleSlide from "./components/TitleSlide/TitleSlide";
import { clss } from "../../../../utils/helpers/styleHelpers";
import throttle from "../../../../utils/helpers/throttle";
import useFirestore from "../../../../utils/hooks/useFirestoreCollection";
import { SubscriptionT } from "../../../../models/models";
import { isMobileOrTablet } from "../../../../utils/helpers/mediaQueries";
import SwipeHand from "../../../../components/SwipeHand/SwipeHand";
import SwiperNav from "../../../../components/SwiperNav/SwiperNav";
import scrollIFSectionVisible from "../../../../utils/helpers/scrollIFSectionVisible";

//@ts-ignore
import styles from "./styles.module.scss";

const SubscriptionsSection = () => {
  const { items: subscriptions } = useFirestore<SubscriptionT>("subscriptions");
  const elementRef = useRef(null as unknown as HTMLDivElement);
  const [isShown, setIsShown] = useState(
    !localStorage.getItem("subscriptions-section")
  );

  const handleScroll = useCallback(() => {
    const element: HTMLDivElement = elementRef.current;
    const throttledHandleScroll = throttle(() => {
      const { top } = element.getBoundingClientRect();
      const newPosition = top * 0.1 + (isMobileOrTablet() ? -10 : +400);
      element.style.backgroundPositionX = `${newPosition}px`;
    }, 200);
    throttledHandleScroll();
  }, [elementRef.current]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleSlideChange = () => {
    localStorage.setItem("subscriptions-section", "subscriptions-section");
    setIsShown(false);
    const section = document.getElementById("subscriptions-section");

    if (!section) return;
    scrollIFSectionVisible(section);
  };

  return !!subscriptions?.length ? (
    <div className={styles.wrapper} id="subscriptions-section" ref={elementRef}>
      {isShown && <SwipeHand />}
      <Swiper
        slidesPerView={1}
        pagination={{ clickable: true }}
        modules={[Pagination]}
        grabCursor
        loop
        className={styles.swiperWrapper}
        onSlideChange={handleSlideChange}
      >
        {!isMobileOrTablet() && <SwiperNav />}
        <SwiperSlide
          className={clss([
            styles.swiperSlide,
            styles.swiperSlideTitle,
            "swiper-class",
          ])}
        >
          <TitleSlide
            subNameList={subscriptions
              ?.slice()
              .sort((a, b) => a.label.localeCompare(b.label))
              .map(({ label }) => label)}
          />
        </SwiperSlide>
        {subscriptions
          ?.slice()
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((sub) => (
            <SwiperSlide
              key={sub.id}
              className={clss([styles.swiperSlide, "swiper-class"])}
              id={sub.label}
            >
              <SubscriptionSlide sub={sub} />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  ) : (
    <></>
  );
};

export default SubscriptionsSection;
