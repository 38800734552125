import { createBrowserRouter } from "react-router-dom";
import privateRoutesAdmin from "./privateRoutesAdmin";
import privateRoutesClient from "./privateRoutesClient";
import publicRoutes from "./publicRoutes";

const publicRouter = createBrowserRouter(publicRoutes);
const privateRouterAdmin = createBrowserRouter(privateRoutesAdmin);
const privateRouterClient = createBrowserRouter(privateRoutesClient);

export {
    publicRouter,
    privateRouterAdmin,
    privateRouterClient,
};
