import { yupResolver } from "@hookform/resolvers/yup";
import React, { FC, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ClientSubscriptionT, SubscriptionT } from "../../../../models/models";
import * as yup from "yup";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

//@ts-ignore
import styles from "./styles.module.scss";
import updateClientSubscription from "../../../../utils/queries/updateClientSubscription";
import { toast } from "react-toastify";
import usePopup from "../../../../utils/hooks/usePopup";

type EditClientSubscriptionPopupPropTypes = {
  subscription: ClientSubscriptionT;
  subscriptions: ClientSubscriptionT[];
  userId: string;
  getClientSubscriptions: () => void;
};

type FormData = {
  comment: string;
  startDate: Dayjs;
  expireDate: Dayjs;
  visitsPassed: {};
};

const schema = yup.object().shape({
  comment: yup.string(),
  startDate: yup.date(),
  expireDate: yup.date(),
  // visitsPassed: yup.object().optional(),
});

const EditClientSubscriptionPopup: FC<EditClientSubscriptionPopupPropTypes> = (
  props
) => {
  const { subscription, subscriptions, userId, getClientSubscriptions } = props;
  const { control, handleSubmit, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      comment: subscription?.comment,
      startDate: dayjs(subscription.startDate),
      expireDate: dayjs(subscription.expireDate),
      visitsPassed: subscription?.visitsPassed || {},
    },
  });
  const [visitsPassed, setVisitsPassed] = useState(subscription?.visitsPassed);
  const { removePopup } = usePopup();

  const onSubmit = async (data: FormData) => {
    const newVisitsPassed: any = {};

    for (const key in visitsPassed) {
      if (visitsPassed[key]) {
        newVisitsPassed[key] = visitsPassed[key];
      }
    }

    const payload = {
      comment: data.comment || "",
      startDate: data.startDate.toISOString(),
      expireDate: data.expireDate.toISOString(),
      visitsPassed: newVisitsPassed,
    };

    await updateClientSubscription(
      userId,
      subscription.id,
      payload,
      subscriptions
    );
    const refetchUsers = new CustomEvent("refetch-users");
    document.dispatchEvent(refetchUsers);
    getClientSubscriptions();
    toast.success("Дані абонементу змінено");
    removePopup();
  };

  return (
    <div>
      <form id="edit-client-subscription" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField {...field} label="Коментар" />
            </FormControl>
          )}
        />
        <Controller
          name="startDate"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth sx={{ m: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Діє з"
                  inputFormat="DD-MM-YYYY"
                  {...field}
                  renderInput={(params) => <TextField {...params} {...field} />}
                />
              </LocalizationProvider>
            </FormControl>
          )}
        />
        <Controller
          name="expireDate"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth sx={{ m: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Діє до"
                  inputFormat="DD-MM-YYYY"
                  {...field}
                  renderInput={(params) => <TextField {...params} {...field} />}
                />
              </LocalizationProvider>
            </FormControl>
          )}
        />
        <InputLabel>Відвідування:</InputLabel>
        <FormGroup>
          {Object.keys(subscription?.visitsPassed)?.map((visit) => (
            <FormControlLabel
              key={visit}
              name={visit}
              control={
                <Checkbox
                  defaultChecked
                  onChange={(e) => {
                    setVisitsPassed((prev) => ({
                      ...prev,
                      [visit]: e.target.checked,
                    }));
                  }}
                />
              }
              label={dayjs(visit).format("DD.MM.YYYY")}
            />
          ))}
        </FormGroup>
      </form>
    </div>
  );
};

export default EditClientSubscriptionPopup;
