import React from "react";

//@ts-ignore
import styles from "./styles.module.scss";

const PrivacyPolicy = () => {
  return (
    <div className={styles.wrapper}>
      <body className="c7 doc-content">
        <p className="c1">
          <span className="c3">
            &#1062;&#1103;
            &#1055;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1072;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1089;&#1082;&#1083;&#1072;&#1076;&#1077;&#1085;&#1072;
            &nbsp;&#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
            &#1076;&#1086; &#1074;&#1080;&#1084;&#1086;&#1075;
            &#1047;&#1072;&#1082;&#1086;&#1085;&#1110;&#1074;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080;.
            &#1055;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1072;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1086;&#1087;&#1080;&#1089;&#1091;&#1108;
            &#1089;&#1087;&#1086;&#1089;&#1086;&#1073;&#1080;, &#1079;&#1072;
            &#1076;&#1086;&#1087;&#1086;&#1084;&#1086;&#1075;&#1086;&#1102;
            &#1103;&#1082;&#1080;&#1093;
            &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;,
            &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1110;
            &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;
            &#1087;&#1088;&#1086;
            &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1110;&#1074;,
            &#1103;&#1082;&#1110;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1102;&#1090;&#1100;
            https://whynotballet.com (&#1076;&#1072;&#1083;&#1110; -
            &#1057;&#1072;&#1081;&#1090;).{" "}
          </span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1042;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1102;&#1095;&#1080;
            &#1094;&#1077;&#1081; &#1057;&#1072;&#1081;&#1090;, &#1042;&#1080;
            &#1087;&#1086;&#1075;&#1086;&#1076;&#1078;&#1091;&#1108;&#1090;&#1077;&#1089;&#1103;
            &#1085;&#1072; &#1079;&#1073;&#1110;&#1088; &#1110;
            &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
            &#1085;&#1072; &#1091;&#1084;&#1086;&#1074;&#1072;&#1093;,
            &#1079;&#1072;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1080;&#1093;
            &#1085;&#1080;&#1078;&#1095;&#1077; &#1074; &#1094;&#1110;&#1081;
            &#1055;&#1086;&#1083;&#1110;&#1090;&#1080;&#1094;&#1110;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1044;&#1083;&#1103; &#1090;&#1086;&#1075;&#1086;
            &#1097;&#1086;&#1073;
            &#1079;&#1088;&#1086;&#1073;&#1080;&#1090;&#1080;
            &#1079;&#1072;&#1084;&#1086;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1090;&#1086;&#1074;&#1072;&#1088;&#1110;&#1074;/&#1087;&#1086;&#1089;&#1083;&#1091;&#1075;,
            &#1073;&#1088;&#1072;&#1090;&#1080;
            &#1091;&#1095;&#1072;&#1089;&#1090;&#1100; &#1091;
            &#1072;&#1082;&#1094;&#1110;&#1103;&#1093;,
            &#1076;&#1086;&#1089;&#1083;&#1110;&#1076;&#1078;&#1077;&#1085;&#1085;&#1103;&#1093;
            &#1072;&#1073;&#1086; &#1110;&#1085;&#1096;&#1080;&#1084;
            &#1095;&#1080;&#1085;&#1086;&#1084;
            &#1074;&#1079;&#1072;&#1108;&#1084;&#1086;&#1076;&#1110;&#1103;&#1090;&#1080;
            &#1079; &#1085;&#1072;&#1084;&#1080;, &#1042;&#1080;
            &#1087;&#1086;&#1074;&#1080;&#1085;&#1085;&#1110;
            &#1091;&#1074;&#1072;&#1078;&#1085;&#1086;
            &#1086;&#1079;&#1085;&#1072;&#1081;&#1086;&#1084;&#1080;&#1090;&#1080;&#1089;&#1103;
            &#1079; &#1042;&#1072;&#1096;&#1080;&#1084;&#1080;
            &#1087;&#1088;&#1072;&#1074;&#1072;&#1084;&#1080; &#1090;&#1072;
            &#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1082;&#1072;&#1084;&#1080;
            &#1097;&#1086;&#1076;&#1086;
            &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1080;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;, &#1103;&#1082;&#1110;
            &#1079;&#1072;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1110;
            &#1074; &#1089;&#1090;. 8 &#1047;.&#1059;.
            &laquo;&#1055;&#1088;&#1086;
            &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;&raquo; ,
            &#1091;&#1074;&#1072;&#1078;&#1085;&#1086;
            &#1086;&#1079;&#1085;&#1072;&#1081;&#1086;&#1084;&#1080;&#1090;&#1080;&#1089;&#1103;
            &#1079; &#1076;&#1072;&#1085;&#1086;&#1102;
            &#1055;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1086;&#1102;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;,
            &#1072; &#1090;&#1072;&#1082;&#1086;&#1078;
            &#1074;&#1080;&#1089;&#1083;&#1086;&#1074;&#1080;&#1090;&#1080;
            &#1089;&#1074;&#1086;&#1102; &#1087;&#1086;&#1074;&#1085;&#1091;
            &#1079;&#1075;&#1086;&#1076;&#1091; &#1079; &#1111;&#1093;
            &#1091;&#1084;&#1086;&#1074;&#1072;&#1084;&#1080;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1071;&#1082;&#1097;&#1086; &#1042;&#1080; &#1085;&#1077;
            &#1087;&#1086;&#1075;&#1086;&#1076;&#1078;&#1091;&#1108;&#1090;&#1077;&#1089;&#1103;
            &#1079; &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1086;&#1102;
            &#1079; &#1091;&#1084;&#1086;&#1074;
            &#1076;&#1072;&#1085;&#1086;&#1111;
            &#1055;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1080;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1090;&#1072;
            &#1074;&#1080;&#1097;&#1077;&#1079;&#1072;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1086;&#1075;&#1086;
            &#1055;&#1086;&#1083;&#1086;&#1078;&#1077;&#1085;&#1085;&#1103;
            &#1087;&#1088;&#1086; &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;, &#1073;&#1091;&#1076;&#1100;
            &#1083;&#1072;&#1089;&#1082;&#1072;, &#1085;&#1077;
            &#1085;&#1072;&#1076;&#1072;&#1074;&#1072;&#1081;&#1090;&#1077;
            &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c4 c2">
            &#1054;&#1073;&#1084;&#1077;&#1078;&#1077;&#1085;&#1085;&#1103;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1083;&#1100;&#1085;&#1086;&#1089;&#1090;&#1110;{" "}
          </span>
        </p>
        <p className="c0">
          <span className="c4 c2"></span>
        </p>
        <p className="c1">
          <span className="c3">
            WhyNotBallet
            &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1085;&#1072;&#1076;&#1072;&#1074;&#1072;&#1090;&#1080;
            &#1089;&#1074;&#1086;&#1111;&#1084;
            &#1082;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;&#1084;
            &#1103;&#1082;&#1110;&#1089;&#1085;&#1110;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080; &#1110;
            &#1079;&#1072;
            &#1076;&#1086;&#1087;&#1086;&#1084;&#1086;&#1075;&#1086;&#1102;
            &#1089;&#1072;&#1081;&#1090;&#1091;
            &#1089;&#1087;&#1088;&#1080;&#1103;&#1090;&#1080;
            &#1079;&#1088;&#1091;&#1095;&#1085;&#1086;&#1084;&#1091;
            &#1079;&#1072;&#1087;&#1080;&#1089;&#1091;,
            &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1090;&#1080;
            &#1110;&#1089;&#1090;&#1086;&#1088;&#1110;&#1102;
            &#1074;&#1079;&#1072;&#1108;&#1084;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;&#1089;&#1080;&#1085;-&#1074;&#1110;&#1079;&#1080;&#1090;&#1110;&#1074;
            &#1082;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;, &#1072;
            &#1090;&#1072;&#1082;&#1086;&#1078;
            &#1085;&#1072;&#1076;&#1072;&#1074;&#1072;&#1090;&#1080;
            &#1084;&#1072;&#1082;&#1089;&#1080;&#1084;&#1072;&#1083;&#1100;&#1085;&#1086;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1110;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1081;
            &#1089;&#1077;&#1088;&#1074;&#1110;&#1089;. &#1044;&#1083;&#1103;
            &#1094;&#1100;&#1086;&#1075;&#1086; &#1085;&#1072;&#1084;
            &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1086;
            &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1090;&#1080;
            &#1110;
            &#1087;&#1077;&#1088;&#1110;&#1086;&#1076;&#1080;&#1095;&#1085;&#1086;
            &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1074;&#1072;&#1090;&#1080;
            &#1042;&#1072;&#1096;&#1110;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1110;
            &#1076;&#1072;&#1085;&#1110; (&#1110;&#1084;&rsquo;&#1103;,
            &#1087;&#1088;&#1110;&#1079;&#1074;&#1080;&#1097;&#1077;,
            &#1076;&#1072;&#1090;&#1091;
            &#1085;&#1072;&#1088;&#1086;&#1076;&#1078;&#1077;&#1085;&#1085;&#1103;,
            &#1087;&#1086;&#1089;&#1080;&#1083;&#1072;&#1085;&#1085;&#1103;
            &#1085;&#1072;
            &#1089;&#1086;&#1094;&#1110;&#1072;&#1083;&#1100;&#1085;&#1110;
            &#1084;&#1077;&#1088;&#1077;&#1078;&#1110;
            &#1082;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;,
            &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;&#1085;&#1080;&#1081;
            &#1085;&#1086;&#1084;&#1077;&#1088;
            &#1090;&#1077;&#1083;&#1077;&#1092;&#1086;&#1085;&#1091;,
            &#1072;&#1076;&#1088;&#1077;&#1089;&#1091;
            &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1111;
            &#1087;&#1086;&#1096;&#1090;&#1080;).
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1080;
            &#1085;&#1072;&#1096;&#1086;&#1111;
            &#1089;&#1090;&#1091;&#1076;&#1110;&#1111;
            &#1084;&#1072;&#1102;&#1090;&#1100; &#1073;&#1091;&#1090;&#1080;
            &#1074;&#1087;&#1077;&#1074;&#1085;&#1077;&#1085;&#1110; &#1074;
            &#1090;&#1086;&#1084;&#1091;, &#1097;&#1086; &#1084;&#1080;
            &#1088;&#1086;&#1079;&#1087;&#1086;&#1088;&#1103;&#1076;&#1078;&#1072;&#1108;&#1084;&#1086;&#1089;&#1103;
            &#1111;&#1093;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1084;&#1080;
            &#1076;&#1072;&#1085;&#1080;&#1084;&#1080; &#1079;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1083;&#1100;&#1085;&#1110;&#1089;&#1090;&#1102;,
            &#1087;&#1077;&#1088;&#1077;&#1076;&#1073;&#1072;&#1095;&#1077;&#1085;&#1086;&#1102;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1086;&#1084;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c4 c2">
            &#1047;&#1073;&#1110;&#1088;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;{" "}
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1055;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1110;
            &#1076;&#1072;&#1085;&#1110; &ndash; &#1094;&#1077;
            &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1072;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;,
            &#1097;&#1086;
            &#1076;&#1086;&#1079;&#1074;&#1086;&#1083;&#1103;&#1108;
            &#1085;&#1072;&#1084;
            &#1110;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1110;&#1082;&#1091;&#1074;&#1072;&#1090;&#1080;
            &#1042;&#1072;&#1096;&#1091;
            &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1110;&#1089;&#1090;&#1100;
            &#1090;&#1072;
            &#1079;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1090;&#1080;&#1089;&#1103;
            &#1079; &#1042;&#1072;&#1084;&#1080;. &#1052;&#1080;
            &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1084;&#1086;
            &#1090;&#1072;
            &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1108;&#1084;&#1086;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
            &#1082;&#1086;&#1083;&#1080; &#1042;&#1080;
            &#1089;&#1090;&#1072;&#1108;&#1090;&#1077;
            &#1085;&#1072;&#1096;&#1080;&#1084;
            &#1082;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;/
            &#1088;&#1077;&#1108;&#1089;&#1090;&#1088;&#1091;&#1108;&#1090;&#1077;&#1089;&#1100;
            &#1085;&#1072; &#1089;&#1072;&#1081;&#1090;&#1110;
            &#1090;&#1072;/&#1095;&#1080;
            &#1079;&#1076;&#1110;&#1081;&#1089;&#1085;&#1102;&#1108;&#1090;&#1077;
            &#1074;&#1093;&#1110;&#1076; &#1074;
            &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1091;
            &#1076;&#1083;&#1103;
            &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;
            &#1085;&#1072;&#1096;&#1086;&#1075;&#1086;
            &#1089;&#1072;&#1081;&#1090;&#1091;,
            &#1079;&#1072;&#1076;&#1072;&#1108;&#1090;&#1077;
            &#1087;&#1080;&#1090;&#1072;&#1085;&#1085;&#1103;,
            &#1074;&#1082;&#1072;&#1079;&#1091;&#1108;&#1090;&#1077;
            &#1084;&#1110;&#1089;&#1090;&#1086;,
            &#1079;&#1072;&#1087;&#1086;&#1074;&#1085;&#1102;&#1108;&#1090;&#1077;
            &#1092;&#1086;&#1088;&#1084;&#1080;,
            &#1079;&#1072;&#1083;&#1080;&#1096;&#1072;&#1108;&#1090;&#1077;
            &#1079;&#1072;&#1103;&#1074;&#1082;&#1091; &#1085;&#1072;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1089;&#1090;&#1091;&#1076;&#1110;&#1111;,
            &#1074;&#1110;&#1076;&#1087;&#1088;&#1072;&#1074;&#1083;&#1103;&#1108;&#1090;&#1077;
            &#1085;&#1072;&#1084;
            &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1102;
            &#1087;&#1086;&#1096;&#1090;&#1086;&#1102; &#1095;&#1080; &#1074;
            &#1084;&#1077;&#1089;&#1077;&#1085;&#1076;&#1078;&#1077;&#1088;&#1080;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            WhyNotBallet
            &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1108;
            &#1042;&#1072;&#1096;&#1110;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1110;
            &#1076;&#1072;&#1085;&#1110; &#1076;&#1083;&#1103;
            &#1079;&#1072;&#1087;&#1080;&#1089;&#1091; &#1085;&#1072;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;,
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
            &#1087;&#1086;&#1075;&#1086;&#1076;&#1078;&#1077;&#1085;&#1080;&#1093;
            &#1090;&#1088;&#1072;&#1085;&#1089;&#1072;&#1082;&#1094;&#1110;&#1081;,
            &#1087;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1080;&#1093;
            &#1079; &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;&#1084;
            &#1042;&#1072;&#1084; &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;,
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1110;
            &#1085;&#1072; &#1042;&#1072;&#1096;&#1110;
            &#1087;&#1080;&#1090;&#1072;&#1085;&#1085;&#1103; &#1090;&#1072;
            &#1079;&#1072;&#1087;&#1080;&#1090;&#1080;,
            &#1074;&#1110;&#1076;&#1087;&#1088;&#1072;&#1074;&#1082;&#1080;
            &#1089;&#1087;&#1086;&#1074;&#1110;&#1097;&#1077;&#1085;&#1100;
            &#1087;&#1088;&#1086;
            &#1085;&#1072;&#1081;&#1073;&#1083;&#1080;&#1078;&#1095;&#1110;
            &#1072;&#1082;&#1094;&#1110;&#1111; &#1090;&#1072;
            &#1088;&#1077;&#1082;&#1083;&#1072;&#1084;&#1085;&#1110;
            &#1084;&#1072;&#1090;&#1077;&#1088;&#1110;&#1072;&#1083;&#1080;,
            &#1086;&#1089;&#1090;&#1072;&#1085;&#1085;&#1110;&#1093;
            &#1085;&#1086;&#1074;&#1080;&#1085; &#1095;&#1080;
            &#1089;&#1087;&#1077;&#1094;&#1110;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1087;&#1088;&#1086;&#1087;&#1086;&#1079;&#1080;&#1094;&#1110;&#1081;,
            &#1103;&#1082;&#1110;, &#1085;&#1072; &#1085;&#1072;&#1096;
            &#1087;&#1086;&#1075;&#1083;&#1103;&#1076;,
            &#1084;&#1086;&#1078;&#1091;&#1090;&#1100; &#1042;&#1072;&#1089;
            &#1079;&#1072;&#1094;&#1110;&#1082;&#1072;&#1074;&#1080;&#1090;&#1080;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c4 c2">
            &#1060;&#1040;&#1049;&#1051;&#1048; COOKIE
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span>&#1053;&#1072; &#1089;&#1072;&#1081;&#1090;&#1110; </span>
          <span className="c5">
            <a
              className="c6"
              href="https://www.google.com/url?q=https://spor&amp;sa=D&amp;source=editors&amp;ust=1677883653246094&amp;usg=AOvVaw3I6CsJBrOdLpF4X4MDCGOi"
            >
              https://whynotballet.com
            </a>{" "}
          </span>
          <span className="c3">
            &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1082;&#1091;&#1082;&#1110; (Cookies) &#1090;&#1072;
            &#1076;&#1072;&#1085;&#1110; &#1087;&#1088;&#1086;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1074;&#1072;&#1095;&#1110;&#1074;
            &#1089;&#1077;&#1088;&#1074;&#1110;&#1089;&#1091; Google{" "}
          </span>
        </p>
        <p className="c1">
          <span className="c3">
            Analytics. &#1047;&#1072;
            &#1076;&#1086;&#1087;&#1086;&#1084;&#1086;&#1075;&#1086;&#1102;
            &#1094;&#1080;&#1093; &#1076;&#1072;&#1085;&#1080;&#1093;
            &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;
            &#1087;&#1088;&#1086; &#1076;&#1110;&#1111;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1074;&#1072;&#1095;&#1110;&#1074;
            &#1085;&#1072; &#1089;&#1072;&#1081;&#1090;&#1110; &#1079;
            &#1084;&#1077;&#1090;&#1086;&#1102;
            &#1087;&#1086;&#1083;&#1110;&#1087;&#1096;&#1077;&#1085;&#1085;&#1103;{" "}
          </span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1081;&#1086;&#1075;&#1086;
            &#1079;&#1084;&#1110;&#1089;&#1090;&#1091;,
            &#1074;&#1076;&#1086;&#1089;&#1082;&#1086;&#1085;&#1072;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1092;&#1091;&#1085;&#1082;&#1094;&#1110;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1084;&#1086;&#1078;&#1083;&#1080;&#1074;&#1086;&#1089;&#1090;&#1077;&#1081;
            &#1089;&#1072;&#1081;&#1090;&#1091;,
            &#1089;&#1090;&#1074;&#1086;&#1088;&#1077;&#1085;&#1085;&#1103;
            &#1103;&#1082;&#1110;&#1089;&#1085;&#1086;&#1075;&#1086;
            &#1082;&#1086;&#1085;&#1090;&#1077;&#1085;&#1090;&#1091;
            &#1090;&#1072; &#1089;&#1077;&#1088;&#1074;&#1110;&#1089;&#1091;{" "}
          </span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1076;&#1083;&#1103;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1074;&#1072;&#1095;&#1110;&#1074;.
            &#1042;&#1080; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077; &#1091;
            &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1081;
            &#1095;&#1072;&#1089;
            &#1079;&#1084;&#1110;&#1085;&#1080;&#1090;&#1080;
            &#1085;&#1072;&#1083;&#1072;&#1096;&#1090;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1089;&#1074;&#1086;&#1075;&#1086;
            &#1073;&#1088;&#1072;&#1091;&#1079;&#1077;&#1088;&#1091;
            &#1090;&#1072;&#1082;&#1080;&#1084;
            &#1095;&#1080;&#1085;&#1086;&#1084;, &#1097;&#1086;&#1073;{" "}
          </span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1073;&#1088;&#1072;&#1091;&#1079;&#1077;&#1088;
            &#1073;&#1083;&#1086;&#1082;&#1091;&#1074;&#1072;&#1074;
            &#1074;&#1089;&#1110; &#1092;&#1072;&#1081;&#1083;&#1080; cookie
            &#1095;&#1080;
            &#1089;&#1087;&#1086;&#1074;&#1110;&#1097;&#1072;&#1074;
            &#1087;&#1088;&#1086;
            &#1074;&#1110;&#1076;&#1087;&#1088;&#1072;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1090;&#1072;&#1082;&#1080;&#1093;
            &#1092;&#1072;&#1081;&#1083;&#1110;&#1074;.
            &#1055;&#1086;&#1090;&#1088;&#1110;&#1073;&#1085;&#1086;
            &#1074;&#1088;&#1072;&#1093;&#1091;&#1074;&#1072;&#1090;&#1080;,
            &#1097;&#1086; &#1087;&#1088;&#1080;{" "}
          </span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1094;&#1100;&#1086;&#1084;&#1091;
            &#1076;&#1077;&#1103;&#1082;&#1110;
            &#1092;&#1091;&#1085;&#1082;&#1094;&#1110;&#1111; &#1090;&#1072;
            &#1089;&#1077;&#1088;&#1074;&#1110;&#1089;&#1080; &#1085;&#1077;
            &#1079;&#1084;&#1086;&#1078;&#1091;&#1090;&#1100;
            &#1087;&#1088;&#1072;&#1094;&#1102;&#1074;&#1072;&#1090;&#1080;
            &#1085;&#1072;&#1083;&#1077;&#1078;&#1085;&#1080;&#1084;
            &#1095;&#1080;&#1085;&#1086;&#1084;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c4 c2">
            &#1047;&#1040;&#1041;&#1045;&#1047;&#1055;&#1045;&#1063;&#1045;&#1053;&#1053;&#1071;
            &#1041;&#1045;&#1047;&#1055;&#1045;&#1050;&#1048;
            &#1044;&#1040;&#1053;&#1048;&#1061;
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1041;&#1077;&#1079;&#1087;&#1077;&#1082;&#1072;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;, &#1097;&#1086;
            &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1040;&#1076;&#1084;&#1110;&#1085;&#1110;&#1089;&#1090;&#1088;&#1072;&#1094;&#1110;&#1108;&#1102;,
            &#1079;&#1072;&#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1096;&#1083;&#1103;&#1093;&#1086;&#1084;
            &#1088;&#1077;&#1072;&#1083;&#1110;&#1079;&#1072;&#1094;&#1110;&#1111;
            &#1087;&#1088;&#1072;&#1074;&#1086;&#1074;&#1080;&#1093;,
            &#1086;&#1088;&#1075;&#1072;&#1085;&#1110;&#1079;&#1072;&#1094;&#1110;&#1081;&#1085;&#1080;&#1093;
            &#1090;&#1072;
            &#1090;&#1077;&#1093;&#1085;&#1110;&#1095;&#1085;&#1080;&#1093;
            &#1079;&#1072;&#1093;&#1086;&#1076;&#1110;&#1074;,
            &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1080;&#1093;
            &#1076;&#1083;&#1103;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
            &#1091; &#1087;&#1086;&#1074;&#1085;&#1086;&#1084;&#1091;
            &#1086;&#1073;&#1089;&#1103;&#1079;&#1110;
            &#1074;&#1080;&#1084;&#1086;&#1075;
            &#1095;&#1080;&#1085;&#1085;&#1086;&#1075;&#1086;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1072;
            &#1091; &#1089;&#1092;&#1077;&#1088;&#1110;
            &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;&#1091;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1059;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1079;
            &#1087;&#1088;&#1072;&#1074;&#1080;&#1083;&#1072;&#1084;&#1080;
            &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1085;&#1085;&#1103;
            &#1090;&#1072;
            &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;
            &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
            &#1110;
            &#1085;&#1072;&#1089;&#1082;&#1110;&#1083;&#1100;&#1082;&#1080;
            &#1094;&#1077;
            &#1087;&#1088;&#1080;&#1081;&#1085;&#1103;&#1090;&#1085;&#1086;
            &#1079;
            &#1082;&#1086;&#1084;&#1077;&#1088;&#1094;&#1110;&#1081;&#1085;&#1086;&#1111;
            &#1090;&#1086;&#1095;&#1082;&#1080; &#1079;&#1086;&#1088;&#1091;,
            &#1084;&#1080;
            &#1086;&#1073;&#1084;&#1077;&#1078;&#1091;&#1108;&#1084;&#1086;
            &#1076;&#1086;&#1089;&#1090;&#1091;&#1087; &#1076;&#1086;
            &#1042;&#1072;&#1096;&#1080;&#1093;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;.
            &#1055;&#1088;&#1086;&#1090;&#1077; &#1042;&#1072;&#1096;&#1110;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1110;
            &#1076;&#1072;&#1085;&#1110;
            &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1110;
            &#1085;&#1072;&#1096;&#1080;&#1084;
            &#1072;&#1076;&#1084;&#1110;&#1085;&#1110;&#1089;&#1090;&#1088;&#1072;&#1090;&#1086;&#1088;&#1072;&#1084;,
            &#1089;&#1087;&#1110;&#1074;&#1088;&#1086;&#1073;&#1110;&#1090;&#1085;&#1080;&#1082;&#1072;&#1084;,
            &#1097;&#1086;&#1073; &#1074;&#1086;&#1085;&#1080;
            &#1084;&#1086;&#1075;&#1083;&#1080;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1090;&#1080;
            &#1089;&#1074;&#1086;&#1111;
            &#1079;&#1072;&#1076;&#1072;&#1095;&#1110; &#1079;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1052;&#1080; &#1085;&#1077;
            &#1086;&#1073;&#1084;&#1110;&#1085;&#1102;&#1108;&#1084;&#1086;&#1089;&#1100;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1108;&#1102;
            &#1079; &#1090;&#1088;&#1077;&#1090;&#1110;&#1084;&#1080;
            &#1086;&#1089;&#1086;&#1073;&#1072;&#1084;&#1080;, &#1091;
            &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1091;,
            &#1103;&#1082;&#1097;&#1086; &#1111;&#1093;
            &#1084;&#1077;&#1090;&#1086;&#1102; &#1108;
            &#1087;&#1088;&#1103;&#1084;&#1080;&#1081;
            &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1055;&#1088;&#1080;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1110;
            &#1082;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;&#1084;
            &#1089;&#1090;&#1091;&#1076;&#1110;&#1111;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075; &#1084;&#1080;
            &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
            &#1088;&#1086;&#1079;&#1082;&#1088;&#1080;&#1074;&#1072;&#1090;&#1080;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1110;
            &#1076;&#1072;&#1085;&#1110;
            &#1090;&#1088;&#1077;&#1090;&#1110;&#1084;
            &#1086;&#1089;&#1086;&#1073;&#1072;&#1084;. &#1062;&#1077;
            &#1089;&#1090;&#1086;&#1089;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1082;&#1086;&#1084;&#1087;&#1072;&#1085;&#1110;&#1081;,
            &#1103;&#1082;&#1110;
            &#1085;&#1072;&#1076;&#1072;&#1102;&#1090;&#1100;
            &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1110;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080; &#1090;&#1072;
            &#1079;&#1072;&#1081;&#1084;&#1072;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1110;&#1085;&#1096;&#1080;&#1084;&#1080;
            &#1073;&#1110;&#1079;&#1085;&#1077;&#1089;
            &#1086;&#1087;&#1077;&#1088;&#1072;&#1094;&#1110;&#1103;&#1084;&#1080;,
            &#1072; &#1090;&#1072;&#1082;&#1086;&#1078;
            &#1082;&#1086;&#1084;&#1087;&#1072;&#1085;&#1110;&#1103;&#1084;&#1080;,
            &#1097;&#1086; &#1085;&#1072;&#1076;&#1072;&#1102;&#1090;&#1100;
            &#1085;&#1072;&#1084;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1052;&#1080; &#1090;&#1072;&#1082;&#1086;&#1078;
            &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
            &#1088;&#1086;&#1079;&#1082;&#1088;&#1080;&#1074;&#1072;&#1090;&#1080;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1110;
            &#1076;&#1072;&#1085;&#1110;
            &#1090;&#1088;&#1077;&#1090;&#1110;&#1084;
            &#1086;&#1089;&#1086;&#1073;&#1072;&#1084;:
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &bull;&#1091; &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1091;
            &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1095;&#1080;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1085;&#1080;&#1093;
            &#1087;&#1086;&#1074;&#1085;&#1086;&#1074;&#1072;&#1078;&#1077;&#1085;&#1100;
            &#1085;&#1072;
            &#1088;&#1086;&#1079;&#1082;&#1088;&#1080;&#1090;&#1090;&#1103;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;; &#1090;&#1072;/&#1095;&#1080;
          </span>
        </p>
        <p className="c1">
          <span className="c3">
            &bull;&#1079; &#1042;&#1072;&#1096;&#1086;&#1075;&#1086;
            &#1076;&#1086;&#1079;&#1074;&#1086;&#1083;&#1091;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1052;&#1080; &#1090;&#1072;&#1082;&#1086;&#1078;
            &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
            &#1088;&#1086;&#1079;&#1082;&#1088;&#1080;&#1074;&#1072;&#1090;&#1080;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1110;
            &#1076;&#1072;&#1085;&#1110; &#1079;&#1072;
            &#1091;&#1084;&#1086;&#1074;&#1080;, &#1097;&#1086;
            &#1090;&#1072;&#1082;&#1077;
            &#1088;&#1086;&#1079;&#1075;&#1086;&#1083;&#1086;&#1096;&#1077;&#1085;&#1085;&#1103;
            &#1108;
            &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1080;&#1084;
            &#1076;&#1083;&#1103;
            &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;&#1091;
            &#1085;&#1072;&#1096;&#1080;&#1093; &#1087;&#1088;&#1072;&#1074;
            &#1095;&#1080;
            &#1074;&#1083;&#1072;&#1089;&#1085;&#1086;&#1089;&#1090;&#1110;,
            &#1072;&#1073;&#1086; &#1087;&#1088;&#1072;&#1074; &#1095;&#1080;
            &#1074;&#1083;&#1072;&#1089;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1110;&#1074;
            &#1085;&#1072;&#1096;&#1086;&#1075;&#1086;
            &#1089;&#1072;&#1081;&#1090;&#1091;. &#1062;&#1077;
            &#1090;&#1072;&#1082;&#1086;&#1078;
            &#1084;&#1086;&#1078;&#1083;&#1080;&#1074;&#1086; &#1091;
            &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1091;
            &#1085;&#1072;&#1076;&#1079;&#1074;&#1080;&#1095;&#1072;&#1081;&#1085;&#1080;&#1093;
            &#1086;&#1073;&#1089;&#1090;&#1072;&#1074;&#1080;&#1085;,
            &#1097;&#1086;
            &#1087;&#1077;&#1088;&#1077;&#1076;&#1073;&#1072;&#1095;&#1072;&#1102;&#1090;&#1100;
            &#1076;&#1110;&#1111; &#1079;
            &#1079;&#1072;&#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1077;&#1085;&#1085;&#1103;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1086;&#1111;
            &#1073;&#1077;&#1079;&#1087;&#1077;&#1082;&#1080;
            &#1085;&#1072;&#1096;&#1086;&#1075;&#1086;
            &#1089;&#1072;&#1081;&#1090;&#1091; &#1095;&#1080;
            &#1085;&#1077;&#1086;&#1073;&#1084;&#1077;&#1078;&#1077;&#1085;&#1086;&#1075;&#1086;
            &#1082;&#1086;&#1083;&#1072; &#1086;&#1089;&#1110;&#1073;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c4 c2">
            &#1059;&#1052;&#1054;&#1042;&#1048;
            &#1053;&#1040;&#1044;&#1040;&#1053;&#1053;&#1071;
            &#1030;&#1053;&#1060;&#1054;&#1056;&#1052;&#1040;&#1062;&#1030;&#1031;
          </span>
        </p>
        <p className="c0">
          <span className="c4 c2"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1057;&#1072;&#1081;&#1090; &#1084;&#1086;&#1078;&#1077;
            &#1084;&#1110;&#1089;&#1090;&#1080;&#1090;&#1080;
            &#1087;&#1086;&#1089;&#1080;&#1083;&#1072;&#1085;&#1085;&#1103;
            &#1085;&#1072; &#1110;&#1085;&#1096;&#1110;
            &#1089;&#1072;&#1081;&#1090;&#1080;. &#1058;&#1072;&#1082;&#1110;
            &#1087;&#1086;&#1089;&#1080;&#1083;&#1072;&#1085;&#1085;&#1103;
            &#1085;&#1072;&#1074;&#1077;&#1076;&#1077;&#1085;&#1110;
            &#1074;&#1080;&#1082;&#1083;&#1102;&#1095;&#1085;&#1086;
            &#1076;&#1083;&#1103;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1081;&#1085;&#1080;&#1093;
            &#1094;&#1110;&#1083;&#1077;&#1081; &#1110;
            &#1087;&#1077;&#1088;&#1077;&#1093;&#1110;&#1076; &#1079;&#1072;
            &#1090;&#1072;&#1082;&#1080;&#1084;&#1080;
            &#1087;&#1086;&#1089;&#1080;&#1083;&#1072;&#1085;&#1085;&#1103;&#1084;&#1080;
            &#1108;
            &#1074;&#1080;&#1082;&#1083;&#1102;&#1095;&#1085;&#1086;&#1102;
            &#1074;&#1086;&#1083;&#1077;&#1102;
            &#1050;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1072;.
            &#1056;&#1086;&#1079;&#1084;&#1110;&#1097;&#1077;&#1085;&#1085;&#1103;
            &#1040;&#1076;&#1084;&#1110;&#1085;&#1110;&#1089;&#1090;&#1088;&#1072;&#1094;&#1110;&#1108;&#1102;
            &#1085;&#1072; &#1057;&#1072;&#1081;&#1090;&#1110;
            &#1087;&#1086;&#1089;&#1080;&#1083;&#1072;&#1085;&#1100;
            &#1085;&#1072; &#1110;&#1085;&#1096;&#1080;&#1081;,
            &#1079;&#1086;&#1074;&#1085;&#1110;&#1096;&#1085;&#1110;&#1081;
            &#1088;&#1077;&#1089;&#1091;&#1088;&#1089;, &#1085;&#1077;
            &#1086;&#1079;&#1085;&#1072;&#1095;&#1072;&#1108;, &#1097;&#1086;
            &#1084;&#1080;
            &#1087;&#1086;&#1074;&#1085;&#1110;&#1089;&#1090;&#1102;
            &#1089;&#1093;&#1074;&#1072;&#1083;&#1102;&#1108;&#1084;&#1086;
            &#1074;&#1080;&#1082;&#1083;&#1072;&#1076;&#1077;&#1085;&#1110;
            &#1090;&#1072;&#1084;
            &#1087;&#1086;&#1079;&#1080;&#1094;&#1110;&#1111;
            &#1072;&#1073;&#1086; &#1110;&#1076;&#1077;&#1111;, &#1072;
            &#1090;&#1072;&#1082;&#1086;&#1078; &#1085;&#1077;
            &#1075;&#1072;&#1088;&#1072;&#1085;&#1090;&#1091;&#1108;&#1084;&#1086;
            &#1076;&#1086;&#1089;&#1090;&#1086;&#1074;&#1110;&#1088;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1088;&#1086;&#1079;&#1084;&#1110;&#1097;&#1077;&#1085;&#1086;&#1111;
            &#1090;&#1072;&#1084;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span>
            &#1040;&#1076;&#1084;&#1110;&#1085;&#1110;&#1089;&#1090;&#1088;&#1072;&#1094;&#1110;&#1103;
            &#1057;&#1072;&#1081;&#1090;&#1091; &#1085;&#1077;
            &#1085;&#1077;&#1089;&#1077;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1083;&#1100;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1079;&#1072;
            &#1087;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1091;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1072;&#1073;&#1086; &#1079;&#1084;&#1110;&#1089;&#1090;
            &#1079;&#1086;&#1074;&#1085;&#1110;&#1096;&#1085;&#1110;&#1093;
            &#1088;&#1077;&#1089;&#1091;&#1088;&#1089;&#1110;&#1074;. &#1059;
            &#1088;&#1072;&#1079;&#1110;
            &#1074;&#1080;&#1085;&#1080;&#1082;&#1085;&#1077;&#1085;&#1085;&#1103;
            &#1087;&#1080;&#1090;&#1072;&#1085;&#1100;, &#1042;&#1080;
            &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
            &#1079;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1090;&#1080;&#1089;&#1103;
            &#1079; &#1085;&#1072;&#1084;&#1080; &#1079;&#1072;
            &#1072;&#1076;&#1088;&#1077;&#1089;&#1086;&#1102;
            &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1111;
            &#1087;&#1086;&#1096;&#1090;&#1080;{" "}
          </span>
          <span className="c5">
            <a className="c6" href="mailto:whynotballet.official@gmail.com">
              whynotballet.contact@gmail.com
            </a>
          </span>
          <span className="c3">&nbsp;</span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c4 c2">
            &#1040;&#1042;&#1058;&#1054;&#1052;&#1040;&#1058;&#1048;&#1063;&#1053;&#1048;&#1049;
            &#1047;&#1041;&#1030;&#1056; &#1030;
            &#1042;&#1048;&#1050;&#1054;&#1056;&#1048;&#1057;&#1058;&#1040;&#1053;&#1053;&#1071;
            &#1030;&#1053;&#1060;&#1054;&#1056;&#1052;&#1040;&#1062;&#1030;&#1031;
          </span>
        </p>
        <p className="c0">
          <span className="c2 c4"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1042;
            &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1095;&#1085;&#1086;&#1084;&#1091;
            &#1088;&#1077;&#1078;&#1080;&#1084;&#1110;
            &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1110;
            &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1085;&#1072;&#1089;&#1090;&#1091;&#1087;&#1085;&#1072;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;:
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1076;&#1086;&#1084;&#1077;&#1085;&#1085;&#1077;
            &#1110;&#1084;&rsquo;&#1103;,
            &#1030;&#1056;-&#1072;&#1076;&#1088;&#1077;&#1089;&#1072;,
            &#1079;&#1072;
            &#1076;&#1086;&#1087;&#1086;&#1084;&#1086;&#1075;&#1086;&#1102;
            &#1103;&#1082;&#1080;&#1081; &#1074;&#1080;
            &#1079;&#1072;&#1093;&#1086;&#1076;&#1080;&#1090;&#1077;
            &#1085;&#1072; &#1057;&#1072;&#1081;&#1090;;
          </span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1074;&#1080;&#1076;
            &#1073;&#1088;&#1072;&#1091;&#1079;&#1077;&#1088;&#1072;
            &#1090;&#1072;
            &#1086;&#1087;&#1077;&#1088;&#1072;&#1094;&#1110;&#1081;&#1085;&#1086;&#1111;
            &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1080;;
          </span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1076;&#1072;&#1090;&#1072; &#1110; &#1095;&#1072;&#1089;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1057;&#1072;&#1081;&#1090;&#1091;;
          </span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1087;&#1077;&#1088;&#1077;&#1075;&#1083;&#1103;&#1085;&#1091;&#1090;&#1110;
            &#1089;&#1090;&#1086;&#1088;&#1110;&#1085;&#1082;&#1080;;
          </span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1103;&#1082;&#1097;&#1086; &#1042;&#1080;
            &#1087;&#1077;&#1088;&#1077;&#1081;&#1096;&#1083;&#1080;
            &#1085;&#1072; &#1057;&#1072;&#1081;&#1090; &#1087;&#1086;
            &#1087;&#1086;&#1089;&#1080;&#1083;&#1072;&#1085;&#1085;&#1102;
            &#1079; &#1110;&#1085;&#1096;&#1086;&#1075;&#1086;
            &#1089;&#1072;&#1081;&#1090;&#1091; -
            &#1072;&#1076;&#1088;&#1077;&#1089;&#1072;
            &#1094;&#1100;&#1086;&#1075;&#1086;
            &#1089;&#1072;&#1081;&#1090;&#1091;.
          </span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1040;&#1076;&#1084;&#1110;&#1085;&#1110;&#1089;&#1090;&#1088;&#1072;&#1094;&#1110;&#1103;
            &#1057;&#1072;&#1081;&#1090;&#1091; &#1084;&#1072;&#1108;
            &#1087;&#1088;&#1072;&#1074;&#1086;
            &#1085;&#1072;&#1076;&#1072;&#1090;&#1080;
            &#1085;&#1072;&#1103;&#1074;&#1085;&#1110; &#1091;
            &#1085;&#1077;&#1111;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1110;
            &#1076;&#1072;&#1085;&#1110;
            &#1050;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1110;&#1074;
            &#1057;&#1072;&#1081;&#1090;&#1091; &#1085;&#1072;
            &#1074;&#1080;&#1084;&#1086;&#1075;&#1091;
            &#1087;&#1088;&#1072;&#1074;&#1086;&#1086;&#1093;&#1086;&#1088;&#1086;&#1085;&#1085;&#1080;&#1093;
            &#1086;&#1088;&#1075;&#1072;&#1085;&#1110;&#1074;, &#1079;&#1072;
            &#1091;&#1093;&#1074;&#1072;&#1083;&#1086;&#1102;/&#1088;&#1110;&#1096;&#1077;&#1085;&#1085;&#1103;&#1084;
            &#1089;&#1091;&#1076;&#1091; &#1095;&#1080; &#1074;
            &#1088;&#1072;&#1084;&#1082;&#1072;&#1093;
            &#1110;&#1085;&#1096;&#1080;&#1093;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1085;&#1080;&#1093;
            &#1087;&#1088;&#1086;&#1094;&#1077;&#1076;&#1091;&#1088;,
            &#1072;&#1073;&#1086; &#1078; &#1085;&#1072;
            &#1110;&#1085;&#1096;&#1091;
            &#1087;&#1088;&#1072;&#1074;&#1086;&#1084;&#1110;&#1088;&#1085;&#1091;
            &#1074;&#1080;&#1084;&#1086;&#1075;&#1091;
            &#1079;&#1075;&#1110;&#1076;&#1085;&#1086; &#1079;
            &#1095;&#1080;&#1085;&#1085;&#1080;&#1084;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1086;&#1084;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1059; &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1091;,
            &#1103;&#1082;&#1097;&#1086; &#1076;&#1072;&#1085;&#1110;
            &#1088;&#1086;&#1079;&#1084;&#1110;&#1097;&#1077;&#1085;&#1110;
            &#1085;&#1072; &#1057;&#1072;&#1081;&#1090;&#1110; &#1085;&#1077;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1090;&#1080;&#1084;&#1091;&#1090;&#1100;
            &#1076;&#1110;&#1081;&#1089;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1110;
            &#1050;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;,
            &#1085;&#1077; &#1074;&#1078;&#1080;&#1074;&#1096;&#1080;
            &#1079;&#1072;&#1093;&#1086;&#1076;&#1110;&#1074;
            &#1076;&#1083;&#1103; &#1111;&#1093;
            &#1087;&#1077;&#1088;&#1077;&#1074;&#1110;&#1088;&#1082;&#1080;,
            &#1087;&#1086;&#1085;&#1077;&#1089;&#1077; &#1074;
            &#1079;&#1074;&rsquo;&#1103;&#1079;&#1082;&#1091; &#1079;
            &#1094;&#1080;&#1084;
            &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1110;
            &#1079;&#1073;&#1080;&#1090;&#1082;&#1080;,
            &#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;
            &#1079;&#1073;&#1080;&#1090;&#1082;&#1080; &#1074;&#1110;&#1076;
            &#1074;&#1090;&#1088;&#1072;&#1090;&#1080;
            &#1087;&#1088;&#1080;&#1073;&#1091;&#1090;&#1082;&#1091;,
            &#1074;&#1090;&#1088;&#1072;&#1090;&#1072;&#1084;&#1080;
            &#1072;&#1073;&#1086;
            &#1079;&#1073;&#1080;&#1090;&#1082;&#1072;&#1084;&#1080;,
            &#1087;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1080;&#1084;&#1080;
            &#1079; &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1084;
            &#1079;&#1084;&#1110;&#1089;&#1090;&#1086;&#1084;
            &#1057;&#1072;&#1081;&#1090;&#1091;,
            &#1088;&#1077;&#1108;&#1089;&#1090;&#1088;&#1072;&#1094;&#1110;&#1108;&#1102;
            &#1072;&#1074;&#1090;&#1086;&#1088;&#1089;&#1100;&#1082;&#1080;&#1093;
            &#1087;&#1088;&#1072;&#1074; &#1110;
            &#1074;&#1110;&#1076;&#1086;&#1084;&#1086;&#1089;&#1090;&#1103;&#1084;&#1080;
            &#1087;&#1088;&#1086; &#1090;&#1072;&#1082;&#1091;
            &#1088;&#1077;&#1108;&#1089;&#1090;&#1088;&#1072;&#1094;&#1110;&#1102;,
            &#1090;&#1086;&#1074;&#1072;&#1088;&#1072;&#1084;&#1080;
            &#1072;&#1073;&#1086;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1072;&#1084;&#1080;,
            &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;&#1085;&#1080;&#1084;&#1080;
            &#1085;&#1072; &#1072;&#1073;&#1086;
            &#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1080;&#1084;&#1080;
            &#1095;&#1077;&#1088;&#1077;&#1079;
            &#1079;&#1086;&#1074;&#1085;&#1110;&#1096;&#1085;&#1110;
            &#1089;&#1072;&#1081;&#1090;&#1080; &#1072;&#1073;&#1086;
            &#1088;&#1077;&#1089;&#1091;&#1088;&#1089;&#1080;
            &#1072;&#1073;&#1086; &#1110;&#1085;&#1096;&#1110;
            &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;&#1080;
            &#1050;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1072;,
            &#1074; &#1103;&#1082;&#1110; &#1074;&#1110;&#1085;
            &#1074;&#1089;&#1090;&#1091;&#1087;&#1080;&#1074;,
            &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1102;&#1095;&#1080;
            &#1088;&#1086;&#1079;&#1084;&#1110;&#1097;&#1077;&#1085;&#1091;
            &#1085;&#1072; &#1057;&#1072;&#1081;&#1090;&#1110;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
            &#1072;&#1073;&#1086;
            &#1087;&#1086;&#1089;&#1080;&#1083;&#1072;&#1085;&#1085;&#1103;
            &#1085;&#1072;
            &#1079;&#1086;&#1074;&#1085;&#1110;&#1096;&#1085;&#1110;
            &#1088;&#1077;&#1089;&#1091;&#1088;&#1089;&#1080;,
            &#1040;&#1076;&#1084;&#1110;&#1085;&#1110;&#1089;&#1090;&#1088;&#1072;&#1094;&#1110;&#1103;
            &#1085;&#1077; &#1085;&#1077;&#1089;&#1077;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1083;&#1100;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1079;&#1072; &#1090;&#1072;&#1082;&#1110;
            &#1079;&#1073;&#1080;&#1090;&#1082;&#1080;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c4 c2">
            &#1059;&#1057;&#1030; &#1055;&#1056;&#1040;&#1042;&#1040;
            &#1053;&#1040; &#1047;&#1052;&#1030;&#1057;&#1058;
            &#1057;&#1040;&#1049;&#1058;&#1059;
            &#1053;&#1040;&#1051;&#1045;&#1046;&#1040;&#1058;&#1068;
            &#1040;&#1044;&#1052;&#1030;&#1053;&#1030;&#1057;&#1058;&#1056;&#1040;&#1062;&#1030;&#1031;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1050;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1110;
            &#1084;&#1072;&#1102;&#1090;&#1100;
            &#1087;&#1088;&#1072;&#1074;&#1086;
            &#1087;&#1077;&#1088;&#1077;&#1075;&#1083;&#1103;&#1076;&#1072;&#1090;&#1080;
            &#1110;
            &#1079;&#1072;&#1074;&#1072;&#1085;&#1090;&#1072;&#1078;&#1091;&#1074;&#1072;&#1090;&#1080;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
            &#1110;
            &#1084;&#1072;&#1090;&#1077;&#1088;&#1110;&#1072;&#1083;&#1080;
            &#1079; &#1089;&#1072;&#1081;&#1090;&#1091;
            &#1090;&#1110;&#1083;&#1100;&#1082;&#1080; &#1076;&#1083;&#1103;
            &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1075;&#1086;
            (&#1085;&#1077;&#1082;&#1086;&#1084;&#1077;&#1088;&#1094;&#1110;&#1081;&#1085;&#1086;&#1075;&#1086;)
            &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;.
            &#1050;&#1086;&#1084;&#1077;&#1088;&#1094;&#1110;&#1081;&#1085;&#1077;
            &#1090;&#1072;/&#1072;&#1073;&#1086;
            &#1087;&#1091;&#1073;&#1083;&#1110;&#1095;&#1085;&#1077;
            &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;
            &#1084;&#1072;&#1090;&#1077;&#1088;&#1110;&#1072;&#1083;&#1110;&#1074;,
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;,
            &#1110;&#1085;&#1096;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;,
            &#1088;&#1086;&#1079;&#1084;&#1110;&#1097;&#1077;&#1085;&#1080;&#1093;
            &#1085;&#1072; &#1089;&#1072;&#1081;&#1090;&#1110;, &#1072;
            &#1090;&#1072;&#1082;&#1086;&#1078;
            &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1077;
            &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;
            &#1084;&#1072;&#1090;&#1077;&#1088;&#1110;&#1072;&#1083;&#1110;&#1074;,
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;,
            &#1110;&#1085;&#1096;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;
            &#1057;&#1072;&#1081;&#1090;&#1091; &#1085;&#1072;
            &#1110;&#1085;&#1096;&#1080;&#1093;
            &#1089;&#1072;&#1081;&#1090;&#1072;&#1093;
            &#1090;&#1072;/&#1072;&#1073;&#1086;
            &#1079;&#1072;&#1089;&#1086;&#1073;&#1072;&#1093;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
            &#1073;&#1077;&#1079; &#1079;&#1075;&#1086;&#1076;&#1080;
            &#1040;&#1076;&#1084;&#1110;&#1085;&#1110;&#1089;&#1090;&#1088;&#1072;&#1094;&#1110;&#1111;
            &#1079;&#1072;&#1073;&#1086;&#1088;&#1086;&#1085;&#1077;&#1085;&#1086;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1050;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;
            &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1085;&#1077; &#1074;&#1095;&#1080;&#1085;&#1103;&#1090;&#1080;
            &#1076;&#1110;&#1081;, &#1103;&#1082;&#1110;
            &#1084;&#1086;&#1078;&#1091;&#1090;&#1100;
            &#1074;&#1074;&#1072;&#1078;&#1072;&#1090;&#1080;&#1089;&#1103;
            &#1090;&#1072;&#1082;&#1080;&#1084;&#1080;, &#1097;&#1086;
            &#1087;&#1086;&#1088;&#1091;&#1096;&#1091;&#1102;&#1090;&#1100;
            &#1095;&#1080;&#1085;&#1085;&#1077;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1086;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080;
            &#1090;&#1072;/&#1072;&#1073;&#1086;
            &#1085;&#1086;&#1088;&#1084;&#1080;
            &#1084;&#1110;&#1078;&#1085;&#1072;&#1088;&#1086;&#1076;&#1085;&#1086;&#1075;&#1086;
            &#1087;&#1088;&#1072;&#1074;&#1072;, &#1091;
            &#1090;&#1086;&#1084;&#1091; &#1095;&#1080;&#1089;&#1083;&#1110;
            &#1091; &#1089;&#1092;&#1077;&#1088;&#1110;
            &#1110;&#1085;&#1090;&#1077;&#1083;&#1077;&#1082;&#1090;&#1091;&#1072;&#1083;&#1100;&#1085;&#1086;&#1111;
            &#1074;&#1083;&#1072;&#1089;&#1085;&#1086;&#1089;&#1090;&#1110;,
            &#1072;&#1074;&#1090;&#1086;&#1088;&#1089;&#1100;&#1082;&#1080;&#1093;
            &#1090;&#1072;/&#1072;&#1073;&#1086;
            &#1089;&#1091;&#1084;&#1110;&#1078;&#1085;&#1080;&#1093;
            &#1087;&#1088;&#1072;&#1074;&#1072;&#1093;, &#1072;
            &#1090;&#1072;&#1082;&#1086;&#1078;
            &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1093;
            &#1076;&#1110;&#1081;, &#1097;&#1086;
            &#1087;&#1088;&#1080;&#1074;&#1086;&#1076;&#1103;&#1090;&#1100;
            &#1072;&#1073;&#1086; &#1084;&#1086;&#1078;&#1091;&#1090;&#1100;
            &#1087;&#1088;&#1080;&#1079;&#1074;&#1077;&#1089;&#1090;&#1080;
            &#1076;&#1086;
            &#1087;&#1086;&#1088;&#1091;&#1096;&#1077;&#1085;&#1085;&#1103;
            &#1085;&#1086;&#1088;&#1084;&#1072;&#1083;&#1100;&#1085;&#1086;&#1111;
            &#1088;&#1086;&#1073;&#1086;&#1090;&#1080;
            &#1057;&#1072;&#1081;&#1090;&#1091; &#1110;
            &#1081;&#1086;&#1075;&#1086;
            &#1089;&#1077;&#1088;&#1074;&#1110;&#1089;&#1110;&#1074;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1055;&#1088;&#1080;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1074;&#1072;&#1085;&#1085;&#1110;
            &#1057;&#1072;&#1081;&#1090;&#1091;,
            &#1050;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;
            &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1095;&#1085;&#1086;
            &#1087;&#1086;&#1075;&#1086;&#1076;&#1078;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1079; &#1076;&#1072;&#1085;&#1086;&#1102;
            &#1055;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1086;&#1102;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1090;&#1072; &#1111;&#1111;
            &#1091;&#1084;&#1086;&#1074;&#1072;&#1084;&#1080;.
            &#1071;&#1082;&#1097;&#1086; &#1042;&#1080; &#1085;&#1077;
            &#1087;&#1086;&#1075;&#1086;&#1076;&#1078;&#1091;&#1108;&#1090;&#1077;&#1089;&#1100;
            &#1079; &#1076;&#1072;&#1085;&#1086;&#1102;
            &#1055;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1086;&#1102;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1090;&#1072; &#1111;&#1111;
            &#1091;&#1084;&#1086;&#1074;&#1072;&#1084;&#1080;,
            &#1087;&#1088;&#1086;&#1087;&#1086;&#1085;&#1091;&#1108;&#1084;&#1086;
            &#1042;&#1072;&#1084;
            &#1079;&#1072;&#1083;&#1080;&#1096;&#1080;&#1090;&#1080;
            &#1094;&#1077;&#1081; &#1057;&#1072;&#1081;&#1090;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c4 c2">
            &#1055;&#1056;&#1040;&#1042;&#1040;
            &#1057;&#1059;&#1041;&rsquo;&#1028;&#1050;&#1058;&#1040;
            &#1055;&#1045;&#1056;&#1057;&#1054;&#1053;&#1040;&#1051;&#1068;&#1053;&#1048;&#1061;
            &#1044;&#1040;&#1053;&#1048;&#1061;
          </span>
        </p>
        <p className="c0">
          <span className="c4 c2"></span>
        </p>
        <p className="c1">
          <span>
            &#1047; &#1084;&#1077;&#1090;&#1086;&#1102;
            &#1086;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;,
            &#1074;&#1085;&#1077;&#1089;&#1077;&#1085;&#1085;&#1103;
            &#1079;&#1084;&#1110;&#1085;,
            &#1073;&#1083;&#1086;&#1082;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1072;&#1073;&#1086;
            &#1074;&#1080;&#1076;&#1072;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1089;&#1074;&#1086;&#1111;&#1093;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;,
            &#1074;&#1110;&#1076;&#1082;&#1083;&#1080;&#1082;&#1072;&#1085;&#1085;&#1103;
            &#1079;&#1075;&#1086;&#1076;&#1080; &#1085;&#1072;
            &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1091;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;, &#1103;&#1082;&#1110;
            &#1073;&#1091;&#1083;&#1080; &#1042;&#1072;&#1084;&#1080;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1110;
            &#1040;&#1076;&#1084;&#1110;&#1085;&#1110;&#1089;&#1090;&#1088;&#1072;&#1094;&#1110;&#1111;
            &#1057;&#1072;&#1081;&#1090;&#1091;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
            &#1076;&#1086; &#1076;&#1072;&#1085;&#1086;&#1111;
            &#1055;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1080;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;,
            &#1072;&#1073;&#1086; &#1074; &#1088;&#1072;&#1079;&#1110;
            &#1085;&#1072;&#1103;&#1074;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1093;
            &#1079;&#1072;&#1091;&#1074;&#1072;&#1078;&#1077;&#1085;&#1100;,
            &#1087;&#1086;&#1073;&#1072;&#1078;&#1072;&#1085;&#1100;
            &#1072;&#1073;&#1086;
            &#1087;&#1088;&#1077;&#1090;&#1077;&#1085;&#1079;&#1110;&#1081;
            &#1097;&#1086;&#1076;&#1086; &#1042;&#1072;&#1096;&#1080;&#1093;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;, &#1103;&#1082;&#1110;
            &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1040;&#1076;&#1084;&#1110;&#1085;&#1110;&#1089;&#1090;&#1088;&#1072;&#1094;&#1110;&#1108;&#1102;,
            &#1087;&#1088;&#1086;&#1089;&#1080;&#1084;&#1086;
            &#1079;&#1074;&#1077;&#1088;&#1090;&#1072;&#1081;&#1090;&#1077;&#1089;&#1103;
            &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1102;
          </span>
          <span className="c2">&nbsp;</span>
          <span>
            &#1087;&#1086;&#1096;&#1090;&#1086;&#1102; &#1085;&#1072;{" "}
          </span>
          <span className="c5">
            <a className="c6" href="mailto:whynotballet.official@gmail.com">
              whynotballet.contact@gmail.com
            </a>
          </span>
          <span className="c3">&nbsp;</span>
        </p>
        <p className="c0">
          <span className="c4 c2"></span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c4 c2">
            &#1047;&#1052;&#1030;&#1053;&#1040;
            &#1055;&#1054;&#1051;&#1030;&#1058;&#1048;&#1050;&#1048;
            &#1050;&#1054;&#1053;&#1060;&#1030;&#1044;&#1045;&#1053;&#1062;&#1030;&#1049;&#1053;&#1054;&#1057;&#1058;&#1030;
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1040;&#1076;&#1084;&#1110;&#1085;&#1110;&#1089;&#1090;&#1088;&#1072;&#1094;&#1110;&#1103;
            &#1057;&#1072;&#1081;&#1090;&#1091;
            &#1079;&#1072;&#1083;&#1080;&#1096;&#1072;&#1108; &#1079;&#1072;
            &#1089;&#1086;&#1073;&#1086;&#1102;
            &#1087;&#1088;&#1072;&#1074;&#1086;
            &#1079;&#1084;&#1110;&#1085;&#1102;&#1074;&#1072;&#1090;&#1080;
            &#1055;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1091;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c1">
          <span className="c3">
            &#1050;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;,
            &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1102;&#1095;&#1080;&#1089;&#1100;
            &#1057;&#1072;&#1081;&#1090;&#1086;&#1084;,
            &#1087;&#1088;&#1080;&#1081;&#1085;&#1103;&#1074;
            &#1091;&#1084;&#1086;&#1074;&#1080; &#1094;&#1110;&#1108;&#1111;
            &#1055;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1080;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;,
            &#1074;&#1088;&#1072;&#1093;&#1086;&#1074;&#1091;&#1108;
            &#1090;&#1072; &#1079;&#1075;&#1086;&#1076;&#1077;&#1085; &#1079;
            &#1090;&#1080;&#1084;, &#1097;&#1086; &#1076;&#1072;&#1085;&#1072;
            &#1055;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1080;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1084;&#1086;&#1078;&#1077; &#1095;&#1072;&#1089;
            &#1074;&#1110;&#1076; &#1095;&#1072;&#1089;&#1091;
            &#1079;&#1084;&#1110;&#1085;&#1102;&#1074;&#1072;&#1090;&#1080;&#1089;&#1103;.
            &#1047;&#1084;&#1110;&#1085;&#1080;, &#1097;&#1086;
            &#1074;&#1085;&#1086;&#1089;&#1103;&#1090;&#1100;&#1089;&#1103;
            &#1076;&#1086;
            &#1055;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1080;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;,
            &#1087;&#1091;&#1073;&#1083;&#1110;&#1082;&#1091;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1085;&#1072; &#1094;&#1110;&#1081;
            &#1089;&#1090;&#1086;&#1088;&#1110;&#1085;&#1094;&#1110;
            &#1057;&#1072;&#1081;&#1090;&#1091;.
          </span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
        <p className="c0">
          <span className="c3"></span>
        </p>
      </body>
    </div>
  );
};

export default PrivacyPolicy;
