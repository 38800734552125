import React, { FC } from "react";
import { useSwiper } from "swiper/react";
import { findSlideIndexById } from "../../helpers/findSlideIndexById";

//@ts-ignore
import styles from "./styles.module.scss";

type TitleSlidePropTypes = {
  subNameList: string[];
};

const TitleSlide: FC<TitleSlidePropTypes> = (props) => {
  const { subNameList } = props;
  const swiper = useSwiper();

  const handleNavigateToSlide = (id: string) => {
    const index = findSlideIndexById(id);
    swiper.slideTo(index);
  };

  return (
    <div className={styles.wrapper}>
      <h2>Абонементи</h2>
      <h3>Оберіть тип тренування</h3>
      <ul>
        {subNameList.map((el, index) => (
          <li key={el} onClick={() => handleNavigateToSlide(el)}>
            {el}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TitleSlide;
