//@ts-ignore
import wnb1 from "./wnb-splash-1.svg";
//@ts-ignore
import wnb2 from "./wnb-splash-2.svg";
//@ts-ignore
import wnb3 from "./wnb-splash-3.svg";
//@ts-ignore
import wnb4 from "./wnb-splash-4.svg";
//@ts-ignore
import wnb5 from "./wnb-splash-5.svg";
//@ts-ignore
import wnb6 from "./wnb-splash-6.svg";

export default {
  wnb1,
  wnb2,
  wnb3,
  wnb4,
  wnb5,
  wnb6,
};
