import CartPage from "../pages/CartPage/CartPage";
import DefaultPage from "../pages/DefaultPage/DefaultPage";
import DeleteAccount from "../pages/DeleteAccount/DeleteAccount";
import EmailNotVerified from "../pages/EmailNotVerified/EmailNotVerified";
import EmailVerificationSent from "../pages/EmailVerificationSent/EmailVerificationSent";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Login from "../pages/Login/Login";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Promo from "../pages/Promo/Promo";
import PublicOffer from "../pages/PublicOffer/PublicOffer";
import SignUp from "../pages/SignUp/SignUp";
import { RoutesE } from "./routes";

const publicRoutes = [
  { path: RoutesE.HOME, element: <Promo /> },
  { path: RoutesE.LOGIN, element: <Login /> },
  { path: RoutesE.SIGN_UP, element: <SignUp /> },
  { path: RoutesE.FORGOT_PASSWORD, element: <ForgotPassword /> },
  { path: RoutesE.BASKET, element: <CartPage /> },
  { path: RoutesE.EMAIL_NOT_VERIFIED, element: <EmailNotVerified /> },
  { path: RoutesE.EMAIL_VERIFICATION_SENT, element: <EmailVerificationSent /> },
  { path: RoutesE.PUBLIC_OFFER, element: <PublicOffer /> },
  { path: RoutesE.PRIVACY_POLICY, element: <PrivacyPolicy /> },
  { path: RoutesE.DELETE_ACCOUNT, element: <DeleteAccount /> },
  { path: "*", element: <DefaultPage /> },
];

export default publicRoutes;
