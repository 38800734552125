import React, { useContext } from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { AuthContext } from "../../services/Auth";
import Face4Icon from "@mui/icons-material/Face4";
import PaymentsIcon from "@mui/icons-material/Payments";
import GroupIcon from "@mui/icons-material/Group";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SchoolIcon from "@mui/icons-material/School";
import SettingsIcon from "@mui/icons-material/Settings";
import { useLocation, useNavigate } from "react-router";
import { RoutesE } from "../../routes/routes";
import MessageIcon from '@mui/icons-material/Message';
import NewspaperIcon from '@mui/icons-material/Newspaper';

//@ts-ignore
import styles from "./styles.module.scss";

const clientList = ["Мої абонементи", "Розклад", "Вчителі"];
const adminList = [
  { name: "Клієнти", icon: <GroupIcon />, route: RoutesE.HOME },
  { name: "Абонементи", icon: <PaymentsIcon />, route: RoutesE.SUBSCRIPTIONS },
  // { name: "Розклад", icon: <DateRangeIcon />, route: RoutesE.SCHEDULE },
  { name: "Вчителі", icon: <SchoolIcon />, route: RoutesE.TEACHERS },
  { name: "Відгуки", icon: <MessageIcon />, route: RoutesE.REVIEWS },
  { name: "Новини", icon: <NewspaperIcon />, route: RoutesE.NEWS },
  // { name: "Налаштування", icon: <SettingsIcon />, route: RoutesE.PROFILE },
];

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const handleClick = (route: RoutesE) => {
    navigate(route);
  };

  return (
    <>
      <Divider />
      <List>
        {adminList.map(({ name, icon, route }, index) => (
          <ListItem key={name} disablePadding>
            <ListItemButton
              selected={route === location.pathname}
              classes={{
                selected: styles.selected,
              }}
              onClick={() => handleClick(route)}
            >
              <ListItemIcon
                className={
                  route === location.pathname ? styles.selectedIcon : ""
                }
                sx={{ color: '#000'}}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {/* <ListItem disablePadding>
          <ListItemButton
            classes={{
              selected: styles.selected,
            }}
          >
            <ListItemIcon
              className={
                location.pathname === RoutesE.PROFILE ? styles.selectedIcon : ""
              }
            >
              <Face4Icon />
            </ListItemIcon>
            <ListItemText primary="Профіль" />
          </ListItemButton>
        </ListItem> */}
      </List>
    </>
  );
};

export default Sidebar;
