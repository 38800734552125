import React, { useState } from "react";
import AnimatedText from "react-animated-text-content";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { TeacherT } from "../../../../models/models";
import useFirestore from "../../../../utils/hooks/useFirestoreCollection";
import SwipeHand from "../../../../components/SwipeHand/SwipeHand";
import { isMobileOrTablet } from "../../../../utils/helpers/mediaQueries";
import SwiperNav from "../../../../components/SwiperNav/SwiperNav";
import scrollIFSectionVisible from "../../../../utils/helpers/scrollIFSectionVisible";

//@ts-ignore
import styles from "./styles.module.scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

const TeachersSection = () => {
  const { items } = useFirestore<TeacherT>("teachers");
  const [isShown, setIsShown] = useState(
    !localStorage.getItem("teachers-section")
  );

  const handleSlideChange = () => {
    localStorage.setItem("teachers-section", "teachers-section");
    setIsShown(false);
    const section = document.getElementById("teachers-section");

    if (!section) return;
    scrollIFSectionVisible(section);
  };

  return (
    <div className={styles.wrapper} id="teachers-section">
      {isShown && <SwipeHand />}
      {!!items && (
        <Swiper
          onSlideChange={handleSlideChange}
          loop
          pagination={{ clickable: true }}
          modules={[Pagination]}
          grabCursor
        >
          {!isMobileOrTablet() && <SwiperNav />}
          {items?.map((item, index) => (
            <SwiperSlide
              className={styles.swiperSlide}
              style={{ backgroundImage: `url(${item.photo})` }}
              key={index}
            >
              <AnimatedText
                type="words"
                animation={{
                  x: "200px",
                  y: "-20px",
                  scale: 1.1,
                  ease: "ease-in-out",
                }}
                animationType="float"
                interval={0.2}
                duration={0.5}
                tag="h2"
                className={styles.title}
                includeWhiteSpaces
                threshold={1}
                rootMargin="0%"
              >
                Викладачі
              </AnimatedText>
              <AnimatedText
                type="words"
                animation={{
                  x: "200px",
                  y: "-20px",
                  scale: 1.1,
                  ease: "ease-in-out",
                }}
                animationType="blocks"
                interval={0.3}
                duration={0.8}
                tag="h3"
                className={styles.title}
                includeWhiteSpaces
                threshold={1}
                rootMargin="0%"
              >
                {item.fullName}
              </AnimatedText>
              <div className={styles.description}>
                {item.description.split("<br>").map((par) => (
                  <p>{par}</p>
                ))}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default TeachersSection;
