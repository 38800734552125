import React, { FC, useState } from "react";
//@ts-ignore
import swipeHand from "../../assets/images/swipe.svg";
import { isMobileOrTablet } from "../../utils/helpers/mediaQueries";
//@ts-ignore
import styles from "./styles.module.scss";

type SwipeHandPropTypes = {};

const SwipeHand: FC<SwipeHandPropTypes> = ({}) => {
  return isMobileOrTablet() ? (
    <img src={swipeHand} alt="swipe" className={styles.swipe} />
  ) : (
    <></>
  );
};

export default SwipeHand;
