import React from "react";
import NewsSection from "../../pages/Promo/sections/NewsSection/NewsSection";
import ClientPageWrapper from "../ClientPageWrapper/ClientPageWrapper";

const ClientNews = () => {
  return (
    <ClientPageWrapper>
      <NewsSection />
    </ClientPageWrapper>
  );
};

export default ClientNews;
