import React, { useContext, useState } from "react";
import {
  TextField,
  CircularProgress,
  Paper,
  Button,
  InputAdornment,
  IconButton,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AuthContext } from "../../services/Auth";
import { useNavigate } from "react-router";
import { RoutesE } from "../../routes/routes";

//@ts-ignore
import styles from "./styles.module.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import GoogleSignInButton from "../../components/GoogleSignInButton/GoogleSIgnInButton";
import FacebookSignInButton from "../../components/FacebookSignInButton/FacebookSignInButton";

type FormDataT = {
  email: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormDataT>();
  const { signIn, loading } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = ({ email, password }: FormDataT) => {
    try {
      signIn!(email?.trim()?.toLowerCase(), password);
    } catch (err) {
      toast.error(err as any);
    }
  };

  const handleForgotPassword = () => {
    navigate(RoutesE.FORGOT_PASSWORD);
  };

  const handleSignUp = () => {
    navigate(RoutesE.SIGN_UP);
  };

  const handleHome = () => {
    navigate(RoutesE.HOME);
  };

  return (
    <div className={styles.wrapper}>
      <h1>Вхід</h1>
      <div style={{ display: "flex", flexDirection: "column", gap: 30 }}>
        <Paper elevation={3} className={styles.formWrapper}>
          <h2>Вхід</h2>
          <form onSubmit={handleSubmit(onSubmit)} id="login-form">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Email" variant="outlined" />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Пароль"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </form>
          <Button
            variant="contained"
            form="login-form"
            type="submit"
            sx={{ color: "#fff" }}
            disabled={loading}
            startIcon={
              loading && <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
          >
            Вхід
          </Button>
          <Divider />
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#ccc",
            }}
          >
            <Typography fontSize={14}>Або увійдіть за допомогою</Typography>
          </Grid>
          <Grid
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <GoogleSignInButton />
            <FacebookSignInButton />
          </Grid>
          <Divider />
          <div className={styles.navActionsWrapper}>
            <Button size="small" onClick={handleHome}>
              На головну
            </Button>
            <Button size="small" onClick={handleSignUp}>
              Реєстрація
            </Button>
          </div>
        </Paper>
        <Button
          size="small"
          onClick={handleForgotPassword}
          sx={{ color: "#fff" }}
        >
          Відновити пароль
        </Button>
      </div>
    </div>
  );
};

export default Login;
