import { RoutesE } from './../../../routes/routes';

export const getTitle = (pathname: string) => {
    switch (pathname) {
        case RoutesE.HOME: return 'Мої абонементи';
        case RoutesE.CLIENT_CONTACTS: return 'Контакти';
        case RoutesE.CLIENT_SUBSCRIPTIONS: return 'Абонементи';
        case RoutesE.PROFILE: return 'Профіль';
        default: return 'WhyNotBallet'
    }
}