import React, { useContext } from "react";
import { AuthContext } from "../../services/Auth";
import SubscriptionsAdmin from "../SubscriptionsAdmin/SubscriptionsAdmin";

const Subscriptions = () => {
  const { currentUser } = useContext(AuthContext);
  return <SubscriptionsAdmin />;
};

export default Subscriptions;
