import { doc, getFirestore, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { UserT } from "../../models/models";

const updateClient = async (id: string, updatedItem: UserT) => {
    const db = getFirestore();
    try {
        const itemRef = doc(db, 'users', id);
        await setDoc(itemRef, updatedItem);
      } catch (err: any) {
        toast.error(err?.code);
      }
};

export default updateClient;