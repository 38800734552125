import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ReviewT } from "../../../../models/models";
import { Pagination } from "swiper";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import useFirestore from "../../../../utils/hooks/useFirestoreCollection";
import Comment from "../../../Reviews/components/Comment/Comment";
import SwipeHand from "../../../../components/SwipeHand/SwipeHand";
import SwiperNav from "../../../../components/SwiperNav/SwiperNav";
import { isMobileOrTablet } from "../../../../utils/helpers/mediaQueries";
import scrollIFSectionVisible from "../../../../utils/helpers/scrollIFSectionVisible";

//@ts-ignore
import styles from "./styles.module.scss";

const ReviewsSection = () => {
  const [isShown, setIsShown] = useState(
    !localStorage.getItem("reviews-section")
  );
  const { items } = useFirestore<ReviewT>("reviews");

  const handleSlideChange = () => {
    localStorage.setItem("reviews-section", "reviews-section");
    setIsShown(false);

    const section = document.getElementById("reviews-section");
    if (!section) return;
    scrollIFSectionVisible(section);
  };
  return (
    <div className={styles.wrapper} id="reviews-section">
      <div className={styles.blurWrapper}>
        {isShown && <SwipeHand />}
        <h2 className={styles.header}>Відгуки</h2>
        <Swiper
          onSlideChange={handleSlideChange}
          slidesPerView={1}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          grabCursor
        >
          {!isMobileOrTablet() && <SwiperNav />}
          {items.map((item, index) => (
            <SwiperSlide className={styles.swiperSlide} key={index}>
              <Comment {...item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ReviewsSection;
