export enum GroupTypeE {
  INDIVIDUAL,
  SPLIT,
  GROUP,
}
export enum GroupTypeUkrE {
  "Індивідуально",
  "Спліт",
  "Група",
}

export enum SubscriptionLabelE {
  STANDART = "Стандарт",
}

export enum SubscriptionTypeE {
  BALLET,
  STRETCHING,
  POINTES,
  MIX,
  ANY,
}

export enum SubscriptionTypeUkrE {
  'Балет',
  'Стретчинг',
  'Пуанти',
  'Балет + стретчинг',
  'На вибір',
}

export enum UserRoleE {
  ADMIN,
  MANAGER,
  CLIENT,
  TEACHER,
}
