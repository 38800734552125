function getNumberBasedOnElementPosition(id: string): number {
  const element = document.getElementById(id);
  if (!element) return 0;
  const elementPosition = element!.getBoundingClientRect().top;
  const windowHeight = window.outerHeight;
  const positionPercent = (elementPosition / windowHeight) * 100;

  if (positionPercent <= 35) {
    return 1;
  } else if (positionPercent <= 44) {
    return 2;
  } else if (positionPercent <= 50) {
    return 3;
  } else if (positionPercent <= 57) {
    return 4;
  } else if (positionPercent <= 65) {
    return 5;
  } else if (positionPercent > 65) {
    return 6;
  } else return 6;
}

export default getNumberBasedOnElementPosition;
