import React from "react";
import { createRoot } from "react-dom/client";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import App from "./App";
import "./styles.css";
import "./utils/animations.scss";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(<App />);
