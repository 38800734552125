import React from "react";
import ClientPageWrapper from "../../components/ClientPageWrapper/ClientPageWrapper";
import SubscriptionsSection from "../Promo/sections/SubscriptionsSection/SubscriptionsSection";

//@ts-ignore
import styles from "./styles.module.scss";

const ClientSubsriptionsWrapper = () => {
  return (
    <ClientPageWrapper>
      <div className={styles.wrapper}>
        <SubscriptionsSection />
      </div>
    </ClientPageWrapper>
  );
};

export default ClientSubsriptionsWrapper;
