import React, { useContext } from "react";
import { PopupContext } from "../../components/Popup/PopupProvider";

/**
 * Returns few functions for manage popup windows
 * */

const usePopup = () => {
  const { createPopup, removePopup, removeAllPopups } = useContext(PopupContext);
  return { createPopup, removePopup, removeAllPopups };
};

export default usePopup;
