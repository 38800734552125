import React, { useEffect, useState } from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import { GroupTypeUkrE, SubscriptionTypeUkrE } from "../../models/enums";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import usePopup from "../../utils/hooks/usePopup";
import CreateEditSubscription from "../../Popups/CreateEditSubscription/CreateEditSubscription";
import Alert from "@mui/material/Alert";
import useFirestoreCollection from "../../utils/hooks/useFirestoreCollection";
import { SubscriptionT } from "../../models/models";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import { VISITS_AMOUNT } from "../../utils/constants";
import AdminTable from "../../components/AdminTable/AdminTable";
import { Tooltip } from "@material-ui/core";
import { isMobileOrTablet } from "../../utils/helpers/mediaQueries";

//@ts-ignore
import styles from "./styles.module.scss";

const tableData = [
  { value: "label", label: "Назва" },
  { value: "description", label: "Опис" },
  { value: "duration", label: "Тривалість (хв)" },
  {
    value: "price",
    label: isMobileOrTablet() ? "Ціна" : `Ціна за ${VISITS_AMOUNT} занять`,
  },
  { value: "type", label: "Тип занять", list: SubscriptionTypeUkrE },
  { value: "groupType", label: "Кільк. участників", list: GroupTypeUkrE },
];

const SubscriptionsAdmin = () => {
  const [searchField, setSearchField] = useState("description");
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const {
    items: subscriptions,
    addItem: createSubscriptions,
    deleteItem: deleteSubscriptions,
    updateItem: updateSubscriptions,
  } = useFirestoreCollection<SubscriptionT>(
    "subscriptions",
    searchField,
  );
  const { createPopup, removePopup, removeAllPopups } = usePopup();
  useEffect(() => {
    document.title = "Абонементи";
  }, []);

  const handleEdit = ({ id }: any) => {
    const subscription = subscriptions.find((sub) => sub.id === id);
    if (!subscription) return;
    createPopup({
      header: <>Редагування абонементу "{subscription.label}"</>,
      body: (
        <CreateEditSubscription
          item={subscription}
          onUpdate={updateSubscriptions}
        />
      ),
      footer: (
        <>
          <Button variant="outlined" onClick={removePopup}>
            Відміна
          </Button>
          <Button
            type="submit"
            form="subscription-form"
            variant="contained"
            sx={{ color: "#fff" }}
          >
            Зберегти зміни
          </Button>
        </>
      ),
    });
  };

  const handleDelete = ({ id }: any) => {
    const subscription = subscriptions.find((sub) => sub.id === id);
    if (!subscription) return;
    createPopup({
      header: <>Увага!</>,
      body: (
        <Alert severity="warning">
          Ви дійсно бажаєте видалити абонемент "{subscription.label}"? Видалені
          дані не можливо буде повернути.
        </Alert>
      ),
      footer: (
        <>
          <Button variant="outlined" onClick={removePopup}>
            Відміна
          </Button>
          <Button
            variant="contained"
            sx={{ color: "#fff" }}
            onClick={() => confirmDelete(id)}
          >
            Видалити
          </Button>
        </>
      ),
    });
  };

  const confirmDelete = async (id: string) => {
    await deleteSubscriptions(id);
    removeAllPopups();
  };

  const handleCreate = () => {
    createPopup({
      header: <>Створення абонементу</>,
      body: <CreateEditSubscription onCreate={createSubscriptions as any} />,
      footer: (
        <>
          <Button variant="outlined" onClick={removePopup}>
            Відміна
          </Button>
          <Button
            type="submit"
            form="subscription-form"
            variant="contained"
            sx={{ color: "#fff" }}
          >
            Створити
          </Button>
        </>
      ),
    });
  };

  const actions = [
    {
      icon: (item: any) => (
        <Tooltip title={"Редагувати абонемент"}>
          <ListItemIcon>
            <EditIcon onClick={() => handleEdit(item)} />
          </ListItemIcon>
        </Tooltip>
      ),
      label: "Редагувати абонемент",
      action: handleEdit,
    },
    {
      icon: (item: any) => (
        <Tooltip title={"Видалити абонемент"}>
          <ListItemIcon>
            <DeleteIcon onClick={() => handleDelete(item)} />
          </ListItemIcon>
        </Tooltip>
      ),
      label: "Видалити абонемент",
      action: handleDelete,
    },
  ];

  return (
    <Dashboard>
      <AdminTable
        items={subscriptions}
        tableData={tableData as any}
        actions={actions}
        searchFieldName={"label"}
      />
      <div className={styles.createButtonWrapper}>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          className={styles.createButton}
          onClick={handleCreate}
        >
          Створити абонемент
        </Button>
      </div>
    </Dashboard>
  );
};

export default SubscriptionsAdmin;
