import { useScroll } from "@react-spring/web";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
//@ts-ignore
import stretching from "../../assets/images/stretching";
import pointe from "../../assets/images/pointe";
import ballet from "../../assets/images/ballet";
import getNumberBasedOnElementPosition from "../../utils/helpers/getNumberBasedOnElementPosition";
import { isMobileOrTablet } from "../../utils/helpers/mediaQueries";
import { clss } from "../../utils/helpers/styleHelpers";
//@ts-ignore
import styles from "./styles.module.scss";

type FrameAnimationPropTypes = {
  animationName: string;
};

const FrameAnimation: FC<FrameAnimationPropTypes> = ({ animationName }) => {
  useEffect(() => {
    const img =
      animationName === "Стретчинг" || animationName === "Індивідуальні уроки"
        ? stretching
        : animationName === "Just Ballet" ||
          animationName === "Ballet Intensive"
        ? ballet
        : pointe;

    const handleScroll = () => {
      const element = document.getElementById("animation-" + animationName);
      const number = getNumberBasedOnElementPosition("frame-animation-trigger");
      const newBackgroundImage = `url(${(img as any)["wnb" + number]})`;
      if (element) {
        element.style.backgroundImage = newBackgroundImage;
      }
    };

    const handleMouseMove = (e: any) => {
      const element = document.getElementById("animation-" + animationName);
      if (element) {
        const part = window.innerWidth / 7;
        const number = Math.round(e.clientX / part);
        const newBackgroundImage = `url(${(img as any)["wnb" + number]})`;
        element.style.backgroundImage = newBackgroundImage;
      }
    };
    if (isMobileOrTablet()) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      if (isMobileOrTablet()) {
        window.removeEventListener("scroll", handleScroll);
      } else {
        window.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, []);

  return (
    <div
      className={clss([
        styles.wrapper,
        animationName === "Стретчинг" || animationName === "Індивідуальні уроки"
          ? styles.stretching
          : animationName === "Just Ballet" ||
            animationName === "Ballet Intensive"
          ? styles.intensive
          : styles.pointe,
      ])}
      id={"animation-" + animationName}
    ></div>
  );
};

export default FrameAnimation;
