//@ts-ignore
import gallery1 from "./gallery_1.jpg";
//@ts-ignore
import gallery2 from "./gallery_2.jpg";
//@ts-ignore
import gallery3 from "./gallery_3.jpg";
//@ts-ignore
import gallery4 from "./gallery_4.jpg";
//@ts-ignore
import gallery5 from "./gallery_5.jpg";
//@ts-ignore
import gallery6 from "./gallery_6.jpg";
//@ts-ignore
import gallery7 from "./gallery_7.jpg";
//@ts-ignore
import gallery8 from "./gallery_8.jpg";

export default {
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
};
