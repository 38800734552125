import { collection, doc, getDoc, getDocs, getFirestore, where } from "firebase/firestore";

const getUserById = async (uid: string) => {
  const db = getFirestore();
  const userRef = doc(collection(db, "users"), uid);
  const userSnapshot = await getDoc(userRef);
  if (userSnapshot.exists()) {
    const userData = userSnapshot.data();
    return userData;
  } else {
    return null;
  }
};

export default getUserById;
