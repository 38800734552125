import React, { useEffect } from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import { Button, Tooltip, ListItemIcon, Alert } from "@mui/material";
import useFirestore from "../../utils/hooks/useFirestoreCollection";
import { TeacherT } from "../../models/models";
import usePopup from "../../utils/hooks/usePopup";
import CreateEditTeacher from "./components/CreateEditTeacher/CreateEditTeacher";
import { Edit, Delete, Add } from "@mui/icons-material";
import AdminTable from "../../components/AdminTable/AdminTable";

//@ts-ignore
import styles from "./styles.module.scss";

const Teachers = () => {
  const {
    items: teachers,
    updateItem,
    deleteItem,
    addItem,
  } = useFirestore<TeacherT>("teachers");
  const { createPopup, removePopup } = usePopup();

  useEffect(() => {
    document.title = "Вчителі";
  }, []);

  const tableData = [
    { value: "fullName", label: "Прізвище та ім'я" },
    { value: "description", label: "Опис" },
  ];

  const handleEdit = (item: TeacherT) => {
    createPopup({
      header: <>Редагувати дані тренера {item?.fullName}</>,
      body: <CreateEditTeacher item={item} updateItem={updateItem} />,
      footer: (
        <>
          <Button onClick={removePopup}>Відміна</Button>
          <Button
            form="update-teacher"
            type="submit"
            variant="contained"
            sx={{ color: "#fff" }}
          >
            Зберегти
          </Button>
        </>
      ),
    });
  };

  const handleDelete = async ({ id }: any) => {
    const confirmDelete = async (id: string) => {
      await deleteItem(id);
    };

    createPopup({
      header: <>Видалити вчителя</>,
      body: (
        <Alert severity="warning">
          Ви дійсно бажаєте видалити вчителя? Видалені дані не можливо буде
          повернути.
        </Alert>
      ),
      footer: (
        <>
          <Button variant="outlined" onClick={removePopup}>
            Відміна
          </Button>
          <Button
            variant="contained"
            sx={{ color: "#fff" }}
            onClick={() => confirmDelete(id)}
          >
            Видалити
          </Button>
        </>
      ),
    });
  };

  const handleAdd = () => {
    createPopup({
      header: <>Створити тренера</>,
      body: <CreateEditTeacher createItem={addItem} />,
      footer: (
        <>
          <Button onClick={removePopup}>Відміна</Button>
          <Button form="update-teacher" type="submit">
            Зберегти
          </Button>
        </>
      ),
    });
  };

  const actions = [
    {
      icon: (item: any) => (
        <Tooltip title={"Редагувати викладача"}>
          <ListItemIcon>
            <Edit onClick={() => handleEdit(item)} />
          </ListItemIcon>
        </Tooltip>
      ),
      label: "Редагувати викладача",
      action: handleEdit,
    },
    {
      icon: (item: any) => (
        <Tooltip title={"Видалити викладача"}>
          <ListItemIcon>
            <Delete onClick={() => handleDelete(item)} />
          </ListItemIcon>
        </Tooltip>
      ),
      label: "Видалити викладача",
      action: handleDelete,
    },
  ];

  return (
    <Dashboard>
      <AdminTable
        tableData={tableData as any}
        actions={actions}
        searchFieldName="fullName"
        items={teachers}
      />
      <div className={styles.addWrapper}>
        <Button
          startIcon={<Add sx={{ color: "#fff" }} />}
          variant="contained"
          sx={{ color: "#fff" }}
          onClick={handleAdd}
        >
          Додати викладача
        </Button>
      </div>
    </Dashboard>
  );
};

export default Teachers;
