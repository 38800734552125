import React, { useEffect } from "react";
import Dashboard from "../../components/Dashboard/Dashboard";

const Schedule = () => {

  useEffect(() => {
    document.title = "Розклад";
  }, []);

  return <Dashboard>Розклад</Dashboard>;
};

export default Schedule;
