import { Paper } from "@mui/material";
import React, { FC } from "react";
import BasketCard from "../../components/BasketCard/BasketCard";
import { SubscriptionT } from "../../models/models";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";

//@ts-ignore
import styles from "./styles.module.scss";

type SubscribePopupPropTypes = {
  subscription?: SubscriptionT;
};

const SubscribePopup: FC<SubscribePopupPropTypes> = (props) => {
  const { subscription } = props;
  return (
    <div className={styles.wrapper}>
      <p>Запис на заняття та замовлення абонементів здійснюється через:</p>
      <ul>
        <li>
          <InstagramIcon />
          <a href="https://instagram.com/whynotballet" target="_blank">
            Instagram (дірект)
          </a>
        </li>
        <li>
          <TelegramIcon />
          <a href="https://t.me/+380971837000" target="_blank">
            Telegram
          </a>
        </li>
      </ul>
      {!!subscription && (
        <>
          <div className={styles.subscriptionWrapper}>
            <BasketCard item={subscription} index={0} withCopyItem />
          </div>
          <p className={styles.note}>
            Натисніть на обонемент для копіювання даних в буфер обміну.
          </p>
        </>
      )}
    </div>
  );
};

export default SubscribePopup;
