import React, { FC } from "react";
import { ReviewT } from "../../../../models/models";

//@ts-ignore
import styles from "./styles.module.scss";

type CommentPropTypes = Omit<ReviewT, "id">;

const Comment: FC<CommentPropTypes> = (props) => {
  const { avatar, text, fullName, link } = props;
  return (
    <div className={styles.commentBlock}>
      <div className={styles.commentAvatar}>
        <img src={avatar} alt="Avatar" />
      </div>
      <div className={styles.textList}>
        {text?.split("<br>").map((el, index, array) => (
          <div className={styles.commentContent} key={index}>
            <div className={styles.commentText}>{el}</div>
            {index === array.length - 1 && (
              <div className={styles.commentMeta}>
                <a href={link}>{fullName} &#8226;</a>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Comment;
