export enum RoutesE {
    HOME = '/',
    LOGIN = '/login',
    SIGN_UP = '/sign-up',
    FORGOT_PASSWORD = '/forgot-password',
    PROFILE = '/profile',
    SUBSCRIPTIONS = '/subscriptions',
    CLIENT_SUBSCRIPTIONS = '/client-subscriptions',
    CLIENT_CONTACTS = '/client-contacts',
    CLIENTS = '/clients',
    TEACHERS = '/teachers',
    SCHEDULE = '/schedule',
    BASKET = '/basket',
    EMAIL_NOT_VERIFIED = '/email-not-verified',
    EMAIL_VERIFICATION_SENT = '/email-verification-sent',
    REVIEWS = '/reviews',
    PUBLIC_OFFER = '/public-offer',
    PRIVACY_POLICY = '/privacy-policy',
    DELETE_ACCOUNT = '/delete-account',
    NEWS = '/news',
    CLIENT_NEWS = '/client-news',
}