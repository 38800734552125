import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { RoutesE } from "../../routes/routes";
import useFirestore from "../../utils/hooks/useFirestoreCollection";
import { SubscriptionT } from "../../models/models";
import BasketCard from "../../components/BasketCard/BasketCard";
import { BasketContext } from "../../services/Basket";

//@ts-ignore
import styles from "./styles.module.scss";

interface CartPageProps {}

const CartPage: React.FC<CartPageProps> = () => {
  const { items: basketItems } = React.useContext(BasketContext);
  const totalPrice = basketItems.reduce(
    (acc: number, item: any) => acc + item.price,
    0
  );
  const navigate = useNavigate();
  const { items } = useFirestore<SubscriptionT>("subscriptions");

  const handleContinueShopping = () => {
    navigate(RoutesE.HOME);
  };

  return (
    <div className={styles.wrapper}>
      <Typography variant="h2">Кошик</Typography>
      <div className={styles.items}>
        {basketItems.map(
          (item, index) => {
            const sub = items.find((i) => i.id === item.subscriptionId);
            return <BasketCard item={{ ...sub, ...item }} index={index} />;
          }
        )}
        {basketItems.length === 0 && <p>Кошик порожнній</p>}
      </div>
      <p className={styles.sum}>
        Сума до сплати: <span>{totalPrice}</span> грн.
      </p>
      {basketItems.length !== 0 && (
        <Button variant="contained" sx={{ color: "#fff" }}>
          Сплатити {totalPrice} грн.
        </Button>
      )}
      <Button onClick={handleContinueShopping} sx={{ color: "#fff" }}>
        Повернутись на головну
      </Button>
    </div>
  );
};

export default CartPage;
