import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { BasketItemT } from "../models/models";
import { AuthContext } from "./Auth";

type BasketProviderPropTypes = {
  children: ReactNode;
};

interface BasketI {
  items: BasketItemT[];
  addItem: (item: BasketItemT) => void;
  removeItem: (index: number) => void;
}

export const BasketContext = createContext<BasketI>({} as BasketI);

const BasketProvider: FC<BasketProviderPropTypes> = (props) => {
  const { children } = props;
  const { currentUser } = useContext(AuthContext);
  const [items, setItems] = useState<BasketItemT[]>([]);

  useEffect(() => {
    const basketName = `basket-${currentUser?.id || "guest"}`;
    const basketItemsString = localStorage.getItem(basketName);
    const basket = basketItemsString ? JSON.parse(basketItemsString) : [];
    setItems(basket);
  }, []);

  const addItem = (item: BasketItemT) => {
    const basketName = `basket-${currentUser?.id || "guest"}`;
    localStorage.setItem(basketName, JSON.stringify([...items, item]));
    setItems((prev) => [...prev, item]);
  };

  const removeItem = (index: number) => {
    const basketName = `basket-${currentUser?.id || "guest"}`;
    const remaining = items.slice();
    remaining.splice(index, 1);
    setItems(remaining);
    localStorage.setItem(basketName, JSON.stringify(remaining));
  };

  return (
    <BasketContext.Provider
      value={{
        items,
        addItem,
        removeItem,
      }}
    >
      {children}
    </BasketContext.Provider>
  );
};

export default BasketProvider;
