import React, { FC, useEffect } from "react";
import { TeacherT } from "../../../../models/models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, FormControl, InputLabel, Input, Alert } from "@mui/material";
import usePopup from "../../../../utils/hooks/usePopup";

//@ts-ignore
import styles from "./styles.module.scss";

type CreateEditTeacherPropTypes = {
  item?: TeacherT;
  updateItem?: (id: string, item: TeacherT) => void;
  createItem?: (item: TeacherT) => void;
};

const schema = yup.object().shape({
  fullName: yup.string().required("Прізвище та ім'я обов'язкові"),
  description: yup.string().required("Опис обов'язковий"),
});

const CreateEditTeacher: FC<CreateEditTeacherPropTypes> = (props) => {
  const { item, updateItem, createItem } = props;
  const { removePopup } = usePopup();
  const [file, setFile] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TeacherT>({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  useEffect(() => {
    if (item) {
      setFile(item.photo as string);
    }
  }, [item]);

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = () => {
      setFile(reader.result as any);
    };
  };

  const onSubmit = async (data: TeacherT) => {
    if (item) {
      await updateItem!(item!.id, { ...data, photo: file });
    } else {
      await createItem!({ ...data, photo: file });
    }
    removePopup();
  };

  return (
    <>
      <Alert severity="info" sx={{ margin: 2 }}>
        {
          'При редагуванні поля "Опис" ви можете використовувати послідовність символів "<br>", щоб розбити текст на абзаци.'
        }
      </Alert>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.wrapper}
        id="update-teacher"
      >
        <TextField
          {...register("fullName")}
          label="Прізвище та ім'я"
          error={!!errors.fullName}
          helperText={errors?.fullName?.message}
        />
        <TextField
          {...register("description")}
          label="Опис"
          error={!!errors.description}
          helperText={errors?.description?.message}
          multiline
          rows={8}
        />
        <FormControl>
          {/* <InputLabel htmlFor="photo-input">Фото</InputLabel> */}
          <Input
            id="photo-input"
            type="file"
            onChange={handleFileChange}
            inputProps={{ accept: "image/*" }}
          />
        </FormControl>
        {!!file && <img src={file} alt="" width={300}/>}
      </form>
    </>
  );
};

export default CreateEditTeacher;
