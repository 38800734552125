import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import photos from "../../../../assets/images/gallery";
import { Pagination } from "swiper";
import { isMobileOrTablet } from "../../../../utils/helpers/mediaQueries";
import SwipeHand from "../../../../components/SwipeHand/SwipeHand";
import SwiperNav from "../../../../components/SwiperNav/SwiperNav";

//@ts-ignore
import styles from "./styles.module.scss";
import "./gallery.scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import scrollIFSectionVisible from "../../../../utils/helpers/scrollIFSectionVisible";

const GallerySection = () => {
  const [isShown, setIsShown] = useState(
    !localStorage.getItem("gallery-section")
  );
  const handleSlideChange = () => {
    localStorage.setItem("gallery-section", "gallery-section");
    setIsShown(false);

    const section = document.getElementById("gallery-section");
    if (!section) return;
    scrollIFSectionVisible(section);
  };
  return (
    <div className={styles.wrapper} id="gallery-section">
      {isShown && <SwipeHand />}
      <h2 className={styles.header}>Галерея</h2>
      <Swiper
        onSlideChange={handleSlideChange}
        pagination={{ clickable: true }}
        modules={[Pagination]}
        grabCursor
        slidesPerView={isMobileOrTablet() ? 1 : 2}
      >
        {!isMobileOrTablet() && <SwiperNav />}
        {Object.values(photos).map((photo, index) => (
          <SwiperSlide
            className={styles.swiperSlide}
            style={{ backgroundImage: `url(${photo})` }}
            key={index}
          ></SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default GallerySection;
