import { ClientSubscriptionT } from "./../../models/models";
import { getFirestore, doc, updateDoc, arrayRemove } from "firebase/firestore";

const removeSubscriptionAdmin = async (
  userId: string,
  subscriptionId: string,
  subscriptions: Partial<ClientSubscriptionT>[]
) => {
  const db = getFirestore();
  const userRef = doc(db, "users", userId);
  try {
    await updateDoc(userRef, {
      subscriptions:
        subscriptions.filter((sub) => sub.id !== subscriptionId) || [],
    });
    const refetchUsers = new CustomEvent('refetch-users');
    document.dispatchEvent(refetchUsers);
  } catch (err: any) {
    console.log(err);
  }
};

export default removeSubscriptionAdmin;
