import dayjs from 'dayjs';
import { ClientSubscriptionT } from "./../../models/models";
import { getFirestore, doc, updateDoc, Firestore } from "firebase/firestore";

const addVisitAdmin = async (
  userId: string,
  subscriptionId: string,
  visitDate: Date | string,
  subscriptions: ClientSubscriptionT[]
) => {
  const db = getFirestore();
  const userRef = doc(db, "users", userId);

  const updatedSubscriptions = subscriptions.map(
    (subscription: ClientSubscriptionT) => {
      if (subscription.id === subscriptionId) {
        return {
          ...subscription,
          visitsPassed: {
            ...subscription.visitsPassed,
            [visitDate as any]: true,
          },
        };
      }
      return subscription;
    }
  );

  await updateDoc(userRef, {
    subscriptions: updatedSubscriptions,
  });
};

export default addVisitAdmin;
