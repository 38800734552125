import React, { useEffect } from "react";
import SplashSection from "./sections/SplashSection/SplashSection";
import PromoNav from "./sections/PromoNav";
import AboutUsSection from "./sections/AboutUsSection/AboutUsSection";
import WhySection from "./sections/WhySection/WhySection";
import SubscriptionsSection from "./sections/SubscriptionsSection/SubscriptionsSection";
import GallerySection from "./sections/GallerySection/GallerySection";
import TeachersSection from "./sections/TeachersSection/TeachersSection";
import ReviewsSection from "./sections/ReviewsSection/ReviewsSection";
import ContactsSection from "./sections/ContactsSection/ContactsSection";
import FloatingSubscribeButton from "../../components/FloatingSubscribeButton/FloatingSubscribeButton";
import NewsSection from "./sections/NewsSection/NewsSection";

//@ts-ignore
import styles from "./styles.module.scss";

const Promo = () => {
  return (
    <div className={styles.pageWrapper} id="promo">
      <FloatingSubscribeButton />
      {<PromoNav />}
      <div className={styles.accountWrapper}></div>
      <SplashSection />
      <AboutUsSection />
      <WhySection />
      <SubscriptionsSection />
      <TeachersSection />
      <GallerySection />
      <NewsSection />
      <ReviewsSection />
      <ContactsSection />
    </div>
  );
};

export default Promo;
