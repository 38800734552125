import React, { createContext, FC, ReactNode } from "react";
import { Firestore } from "firebase/firestore";
import { db } from "../firebase";

type FirebasePropTypes = {
  children: ReactNode;
};

type FirebaseContextT = {
  db: Firestore;
};

export const FirebaseContext = createContext<FirebaseContextT>({ db: null as unknown as Firestore});

const FirebaseProvider: FC<FirebasePropTypes> = ({ children }) => {
  return (
    <FirebaseContext.Provider value={{ db }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
