import { IconButton, Paper } from "@mui/material";
import React, { FC, SetStateAction } from "react";
import QRCode from "react-qr-code";
import CloseIcon from "@mui/icons-material/Close";

//@ts-ignore
import styles from "./styles.module.scss";

type AddVisitQrCodePropTypes = {
  userId: string;
  subscriptionId: string;
  setIsQRVisible: SetStateAction<any>;
};

const AddVisitQrCode: FC<AddVisitQrCodePropTypes> = (props) => {
  const { userId, subscriptionId, setIsQRVisible } = props;

  const handleClose = () => {
    const customEvent = new CustomEvent("update-current-user");
    document.dispatchEvent(customEvent);
    setIsQRVisible(false);
  };

  return (
    <>
      <div className={styles.overlay}>
      <IconButton
        color="primary"
        aria-label="close qr"
        component="label"
        className={styles.closeIcon}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
        <Paper className={styles.body} elevation={5}>
          <p>Покажіть QR код адміністратору для зарахування відвідування</p>
          <QRCode value={JSON.stringify({ userId, subscriptionId })} />
        </Paper>
      </div>
    </>
  );
};

export default AddVisitQrCode;
