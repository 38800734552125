import React, { useEffect } from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import {
  Button,
  Tooltip,
  ListItemIcon,
  Alert,
} from "@mui/material";
import useFirestore from "../../utils/hooks/useFirestoreCollection";
import { NewsT } from "../../models/models";
import usePopup from "../../utils/hooks/usePopup";
import { Edit, Delete, Add } from "@mui/icons-material";
import AdminTable from "../../components/AdminTable/AdminTable";
import CreateEditNews from "./components/CreateEditNews/CreateEditNews";

//@ts-ignore
import styles from "./styles.module.scss";

const tableData = [
  { value: "title", label: "Заголовок" },
  { value: "article", label: "Текст" },
  { value: "creationDateTime", label: "Дата створення", type: 'timestamp' },
];

const News = () => {
  const {
    items: news,
    updateItem,
    deleteItem,
    addItem,
  } = useFirestore<NewsT>("news");
  const { createPopup, removePopup } = usePopup();

  useEffect(() => {
    document.title = "Новини";
  }, []);

  const handleEdit = (item: NewsT) => {
    createPopup({
      header: <>Редагувати новину</>,
      body: <CreateEditNews item={item} updateItem={updateItem} />,
      footer: (
        <>
          <Button onClick={removePopup}>Відміна</Button>
          <Button form="update-news" type="submit">
            Зберегти
          </Button>
        </>
      ),
    });
  };

  const handleDelete = async ({ id }: any) => {
    const confirmDelete = async (id: string) => {
      await deleteItem(id);
      removePopup();
    };

    createPopup({
      header: <>Увага!</>,
      body: (
        <Alert severity="warning">
          Ви дійсно бажаєте видалити новину? Видалені
          дані не можливо буде повернути.
        </Alert>
      ),
      footer: (
        <>
          <Button variant="outlined" onClick={removePopup}>
            Відміна
          </Button>
          <Button
            variant="contained"
            sx={{ color: "#fff" }}
            onClick={() => confirmDelete(id)}
          >
            Видалити
          </Button>
        </>
      ),
    });
  };

  const handleAdd = () => {
    createPopup({
      header: <>Додати новину</>,
      body: <CreateEditNews createItem={addItem} />,
      footer: (
        <>
          <Button onClick={removePopup}>Відміна</Button>
          <Button form="update-news" type="submit">
            Зберегти
          </Button>
        </>
      ),
    });
  };

  const actions = [
    {
      icon: (item: any) => (
        <Tooltip title={"Редагувати новину"}>
          <ListItemIcon>
            <Edit onClick={() => handleEdit(item)} />
          </ListItemIcon>
        </Tooltip>
      ),
      label: "Редагувати новину",
      action: handleEdit,
    },
    {
      icon: (item: any) => (
        <Tooltip title={"Видалити новину"}>
          <ListItemIcon>
            <Delete onClick={() => handleDelete(item)} />
          </ListItemIcon>
        </Tooltip>
      ),
      label: "Видалити відгук",
      action: handleDelete,
    },
  ];

  return (
    <Dashboard>
      <AdminTable
        tableData={tableData as any}
        actions={actions}
        searchFieldName="creationDateTime"
        items={news}
      />
      <div className={styles.addWrapper}>
        <Button
          startIcon={<Add sx={{ color: "#fff" }} />}
          variant="contained"
          sx={{ color: "#fff" }}
          onClick={handleAdd}
        >
          Додати новину
        </Button>
      </div>
    </Dashboard>
  );
};

export default News;
