import * as React from "react";
import {
  Box,
  Typography,
  Stack,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import { AccessTime, Group } from "@mui/icons-material";
import { BasketItemT, SubscriptionT } from "../../models/models";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import Groups2Icon from "@mui/icons-material/Groups2";
import GradeIcon from "@mui/icons-material/Grade";
import CopyIcon from "@mui/icons-material/ContentCopy";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import dayjs from "dayjs";
import { GroupTypeUkrE } from "../../models/enums";
import usePopup from "../../utils/hooks/usePopup";
import { BasketContext } from "../../services/Basket";
import { toast } from "react-toastify";
import { clss } from "../../utils/helpers/styleHelpers";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import AddVisitQrCode from "../AddVisitQrCode/AddVisitQrCode";
import { AuthContext } from "../../services/Auth";
import { useCallback, useContext, useEffect, useState } from "react";
import ExportSubscription from "../ExportSubscription/ExportSubscription";
import InfoIcon from "@mui/icons-material/Info";

//@ts-ignore
import styles from "./styles.module.scss";
import { isMobileOrTablet } from "../../utils/helpers/mediaQueries";

const BasketCard: React.FC<{
  item: any;
  index: number;
  withCopyItem?: boolean;
  isClientView?: boolean;
}> = (props) => {
  const {
    item: {
      label,
      visits,
      duration,
      groupType,
      expireDate,
      startDate,
      price,
      id,
      comment,
      ...item
    },
    index,
    withCopyItem,
    isClientView,
  } = props;
  const [hovered, setHovered] = React.useState(false);
  const { createPopup, removePopup } = usePopup();
  const { removeItem } = useContext(BasketContext);
  const [isQrVisible, setIsQRVisible] = React.useState(false);
  const { currentUser } = useContext(AuthContext);
  const [starTooltipIndex, setStarTooltipIndex] = useState(-1);

  const handleClick = useCallback((e: any) => {
    setTimeout(() => {
      setStarTooltipIndex(-1);
    }, 3000);
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleRemove = () => {
    const confirmRemove = () => {
      removeItem(id);
      removePopup();
    };
    createPopup({
      header: <>Видалити абонемент</>,
      body: <p>Ви дійсно бажаєте видалити абонеменет "{label}"?</p>,
      footer: (
        <>
          <Button onClick={removePopup}>Відміна</Button>
          <Button
            onClick={confirmRemove}
            variant="contained"
            sx={{ color: "#fff" }}
          >
            Видалити
          </Button>
        </>
      ),
      className: styles.removePopup,
    });
  };

  const handleCopy = async () => {
    const subData = `Абонемент: ${label}; Тривалість: ${duration}хв; Ціна: ${price}грн; Кільк. відвідувань: ${visits}; Діє з ${dayjs(
      expireDate
    ).format("DD.MM.YYYY")} до ${dayjs(expireDate)
      .add(1, "month")
      .format("DD.MM.YYYY")}`;
    navigator.clipboard.writeText(subData);
    toast("Дані про абонемент скопійовано в буфер обміну.", {
      type: "success",
      autoClose: 3000,
    });
  };

  const handleShowComment = () => {
    createPopup({
      header: <>Коментар адміністратора</>,
      body: (
        <div className={styles.commentBody}>
          <InfoIcon sx={{ color: "#d495aa66" }} />
          <p>{comment}</p>
        </div>
      ),
      footer: <Button onClick={removePopup}>Закрити</Button>,
    });
  };

  const visitsPassed = Object.keys(item?.visitsPassed || {})?.length || 0;
  const visitsLeft = visits - visitsPassed;

  return (
    <>
      {isQrVisible && (
        <AddVisitQrCode
          userId={currentUser?.id as string}
          subscriptionId={id}
          setIsQRVisible={setIsQRVisible}
        />
      )}
      <Box
        sx={{
          color: "white",
          borderRadius: 2,
          position: "relative",
          overflow: "hidden",
          boxShadow: hovered
            ? "0px 0px 5px rgba(0, 0, 0, 0.3)"
            : "0px 0px 5px rgba(0, 0, 0, 0.1)",
          transform: hovered ? "translateX(-5px)" : "none",
          transition: "all 0.3s ease-out",
          animationDelay: "." + index + "s",
        }}
        className={clss([
          styles.wrapper,
          isClientView ? styles.clientCard : "",
        ])}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={withCopyItem ? () => handleCopy() : () => {}}
      >
        <Typography sx={{ fontFamily: "serif", fontSize: 30 }} variant="h2">
          {label}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <AccessTimeIcon sx={{ color: "#fff", fontSize: "1rem" }} />
          <Typography variant="subtitle1">Тривалість {duration} хв.</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          {groupType === 0 && (
            <PersonIcon sx={{ color: "#fff", fontSize: "1rem" }} />
          )}
          {groupType === 1 && (
            <PeopleIcon sx={{ color: "#fff", fontSize: "1rem" }} />
          )}
          {groupType === 2 && (
            <Groups2Icon sx={{ color: "#fff", fontSize: "1rem" }} />
          )}
          <Typography variant="subtitle1">
            {GroupTypeUkrE[groupType]}
          </Typography>
        </Stack>
        {isClientView ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle1">
              {visitsPassed}/{visits} відвідувань(ня)
            </Typography>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle1">
              {visits} відвідувань(ня)
            </Typography>
          </Stack>
        )}
        {isClientView ? (
          <div>
            {Object.keys(item?.visitsPassed || {})
              .sort((a, b) => dayjs(a).diff(dayjs(b)))
              .map((visit, index) => (
                <Tooltip
                  title={dayjs(visit).format("DD.MM.YYYY")}
                  disableHoverListener={isMobileOrTablet()}
                  disableFocusListener={isMobileOrTablet()}
                  disableTouchListener={isMobileOrTablet()}
                  onClick={
                    isMobileOrTablet()
                      ? () => setStarTooltipIndex(index)
                      : undefined
                  }
                  open={
                    isMobileOrTablet() ? index === starTooltipIndex : undefined
                  }
                >
                  <GradeIcon
                    sx={{ color: "#fff", fontSize: "2rem" }}
                    key={index}
                  />
                </Tooltip>
              ))}
            {[...new Array(visitsLeft || 0)].map((_, index) => (
              <GradeIcon
                sx={{ color: "#00000033", fontSize: "2rem" }}
                key={index}
              />
            ))}
          </div>
        ) : (
          <div>
            {[...new Array(visits)].map((_, index) => (
              <GradeIcon sx={{ color: "#fff", fontSize: "1rem" }} key={index} />
            ))}
          </div>
        )}
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ marginTop: 1 }}
        >
          {/*@ts-ignore*/}
          <Typography variant="subtitle1">
            Діє з {dayjs(startDate).format("DD.MM.YYYY")} до{" "}
            {dayjs(expireDate).format("DD.MM.YYYY")}
          </Typography>
        </Stack>
        {!isClientView && <p className={styles.price}>{price} грн.</p>}
        {!isClientView ? (
          <>
            {withCopyItem ? (
              <CopyIcon
                sx={{ color: "#fff", fontSize: "1.5rem" }}
                className={styles.binIcon}
              />
            ) : (
              <DeleteForeverIcon
                sx={{ color: "#fff", fontSize: "1.5rem" }}
                className={styles.binIcon}
                onClick={handleRemove}
              />
            )}
          </>
        ) : (
          <>
            <IconButton
              aria-label="qr code"
              onClick={() => setIsQRVisible(true)}
              className={styles.qrCodeButton}
            >
              <QrCode2Icon sx={{ fontSize: "3rem", color: "#fff" }} />
            </IconButton>
            {!!comment && (
              <Tooltip title="Коментар адміністратора">
                <IconButton
                  aria-label="comment"
                  onClick={handleShowComment}
                  className={styles.commentButton}
                >
                  <InfoIcon sx={{ fontSize: "2rem", color: "#fff" }} />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
        {isClientView && (
          <ExportSubscription subscription={{ ...props.item, visits }} />
        )}
      </Box>
    </>
  );
};

export default BasketCard;
