import React, { useEffect } from "react";
import { clss } from "../../../../utils/helpers/styleHelpers";
import NextButton from "../../../../components/NextButton/NextButton";
import AnimatedText from "react-animated-text-content";
import useScroll from "../../../../utils/hooks/useScroll";
//@ts-ignore
import styles from "./styles.module.scss";

const SplashSection = () => {
  const scrollValue = useScroll("splash-section", ["backgroundSize"]);

  return (
    <div
      className={clss([styles.wrapper, styles.sectionWrapper])}
      id="splash-section"
    >
      <AnimatedText
        type="words"
        animation={{
          x: "200px",
          y: "-20px",
          scale: 1.1,
          ease: "ease-in-out",
        }}
        animationType="float"
        interval={0.3}
        duration={0.8}
        tag="h1"
        className={styles.title}
        includeWhiteSpaces
        threshold={.2}
        rootMargin="20%"
      >
        Why Not Ballet
      </AnimatedText>
      <NextButton nextElementId="about-us-section" />
    </div>
  );
};

export default SplashSection;
