import React from "react";
import usePopup from "../../utils/hooks/usePopup";
import PhoneIcon from "@mui/icons-material/Phone";
import SubscribePopup from "../../Popups/SubscribePopup/SubscribePopup";
import { Button } from "@mui/material";

//@ts-ignore
import styles from "./styles.module.scss";

const FloatingSubscribeButton = () => {
  const { createPopup, removePopup } = usePopup();

  const handleCLick = () => {
    createPopup({
      header: <>Записатись на заняття</>,
      body: <SubscribePopup />,
      footer: <Button onClick={removePopup}>Закрити</Button>,
    });
  };

  return (
    <div className={styles.wrapper} onClick={handleCLick}>
      <PhoneIcon sx={{ color: "#fff" }} />
      <span>Запис</span>
    </div>
  );
};

export default FloatingSubscribeButton;
