import React, { useCallback, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import AnimatedText from "react-animated-text-content";
import {
  isMobile,
  isMobileOrTablet,
} from "../../../../utils/helpers/mediaQueries";
import SwipeHand from "../../../../components/SwipeHand/SwipeHand";
import SwiperNav from "../../../../components/SwiperNav/SwiperNav";
import scrollIFSectionVisible from "../../../../utils/helpers/scrollIFSectionVisible";

//@ts-ignore
import styles from "./styles.module.scss";
import "swiper/css";

const WhySection = () => {
  const [isShown, setIsShown] = useState(!localStorage.getItem("why-section"));
  const handleScroll = useCallback(() => {
    const slide1 = document.getElementById("slide-bg-1");
    const slide2 = document.getElementById("slide-bg-2");
    const slide3 = document.getElementById("slide-bg-3");
    const slide4 = document.getElementById("slide-bg-4");
    const slide5 = document.getElementById("slide-bg-5");
    const slide6 = document.getElementById("slide-bg-6");

    const percent = window.outerHeight / 100;
    const value = isMobile()
      ? (slide1!.getBoundingClientRect().bottom / percent) * 1.2 + 100 + "vw"
      : slide1!.getBoundingClientRect().bottom / percent / 16 + 100 + "vw";

    slide1!.style.backgroundSize = value;
    slide2!.style.backgroundSize = value;
    slide3!.style.backgroundSize = value;
    slide4!.style.backgroundSize = value;
    slide5!.style.backgroundSize = value;
    slide6!.style.backgroundSize = value;
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  const handleSlideChange = () => {
    localStorage.setItem("why-section", "why-section");
    setIsShown(false);
    const section = document.getElementById("why-section");
    if (!section) return;
    scrollIFSectionVisible(section);
  };

  return (
    <div className={styles.wrapper} id="why-section">
      {isShown && <SwipeHand />}
      <Swiper
        slidesPerView={1}
        pagination={{ clickable: true }}
        modules={[Pagination, Autoplay]}
        grabCursor
        loop
        className={styles.swiperWrapper}
        onSlideChange={handleSlideChange}
      >
        {!isMobileOrTablet() && <SwiperNav />}
        <SwiperSlide className={styles.swiperSlide}>
          <div className={styles.gradient}></div>
          <div className={styles.swiperBody}>
            <AnimatedText
              type="words"
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType="float"
              interval={0.2}
              duration={0.5}
              tag="h2"
              className={styles.title}
              includeWhiteSpaces
              threshold={1}
              rootMargin="0%"
            >
              Чому ми?
            </AnimatedText>
            <AnimatedText
              type="words"
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType="blocks"
              interval={0.3}
              duration={0.8}
              tag="h3"
              className={styles.title}
              includeWhiteSpaces
              threshold={1}
              rootMargin="0%"
            >
              Ми професіонали
            </AnimatedText>
            <p>
              В студії викладають діючі артисти балету - солісти Національної
              Опери України, заслужені артисти України та балерини театрів, які
              мають великий танцювальний досвід та педагогічну практику і з
              радістю готові ділитись з вами своїми знаннями.
            </p>
          </div>
          <div className={styles.slideBackground} id="slide-bg-1" />
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <div className={styles.gradient}></div>
          <div className={styles.swiperBody}>
            <AnimatedText
              type="words"
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType="float"
              interval={0.2}
              duration={0.5}
              tag="h2"
              className={styles.title}
              includeWhiteSpaces
              threshold={1}
              rootMargin="0%"
            >
              Чому ми?
            </AnimatedText>
            <AnimatedText
              type="words"
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType="blocks"
              interval={0.2}
              duration={0.5}
              tag="h3"
              className={styles.title}
              includeWhiteSpaces
              threshold={1}
              rootMargin="0%"
            >
              Заняття в зручний час
            </AnimatedText>
            <p>
              Обирай тренування в зручному графіку, у нас є ранкові, вечірні
              групи, а також групи вихідного дня.
            </p>
          </div>
          <div className={styles.slideBackground} id="slide-bg-2" />
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <div className={styles.gradient}></div>
          <div className={styles.swiperBody}>
            <AnimatedText
              type="words"
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType="float"
              interval={0.2}
              duration={0.5}
              tag="h2"
              className={styles.title}
              includeWhiteSpaces
              threshold={1}
              rootMargin="0%"
            >
              Чому ми?
            </AnimatedText>
            <AnimatedText
              type="words"
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType="blocks"
              interval={0.3}
              duration={0.8}
              tag="h3"
              className={styles.title}
              includeWhiteSpaces
              threshold={1}
              rootMargin="0%"
            >
              Індивідуальний підхід
            </AnimatedText>
            <p>Ми враховуємо всі ваші побажання і рівень підготовки.</p>
          </div>
          <div className={styles.slideBackground} id="slide-bg-3" />
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <div className={styles.gradient}></div>
          <div className={styles.swiperBody}>
            <AnimatedText
              type="words"
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType="float"
              interval={0.2}
              duration={0.5}
              tag="h2"
              className={styles.title}
              includeWhiteSpaces
              threshold={1}
              rootMargin="0%"
            >
              Чому ми?
            </AnimatedText>
            <AnimatedText
              type="words"
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType="blocks"
              interval={0.2}
              duration={0.5}
              tag="h3"
              className={styles.title}
              includeWhiteSpaces
              threshold={1}
              rootMargin="0%"
            >
              Зручне розташування
            </AnimatedText>
            <p>
              Студія в історичному центрі Києва в декількох хвилинах від метро
            </p>
          </div>
          <div className={styles.slideBackground} id="slide-bg-4" />
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <div className={styles.gradient}></div>
          <div className={styles.swiperBody}>
            <AnimatedText
              type="words"
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType="float"
              interval={0.2}
              duration={0.5}
              tag="h2"
              className={styles.title}
              includeWhiteSpaces
              threshold={1}
              rootMargin="0%"
            >
              Чому ми?
            </AnimatedText>
            <AnimatedText
              type="words"
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType="blocks"
              interval={0.2}
              duration={0.5}
              tag="h3"
              className={styles.title}
              includeWhiteSpaces
              threshold={1}
              rootMargin="0%"
            >
              Жива музика
            </AnimatedText>
            <p>
              Всі заняття проводяться під акомпанемент фортепіано. Ви будете
              вивчати балет та отримувати естетичне задоволення від живої
              музики, яку вам даруватимуть наші професійні концертмейстери.
            </p>
          </div>
          <div className={styles.slideBackground} id="slide-bg-5" />
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <div className={styles.gradient}></div>
          <div className={styles.swiperBody}>
            <AnimatedText
              type="words"
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType="float"
              interval={0.2}
              duration={0.5}
              tag="h2"
              className={styles.title}
              includeWhiteSpaces
              threshold={1}
              rootMargin="0%"
            >
              Чому ми?
            </AnimatedText>
            <AnimatedText
              type="words"
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType="blocks"
              interval={0.2}
              duration={0.5}
              tag="h3"
              className={styles.title}
              includeWhiteSpaces
              threshold={1}
              rootMargin="0%"
            >
              Красивий зал
            </AnimatedText>
            <p>
              Зал з високими стелями, який надихає на заняття класичною
              хореографією.
            </p>
          </div>
          <div className={styles.slideBackground} id="slide-bg-6" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default WhySection;
