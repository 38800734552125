import { isMobile } from "./../helpers/mediaQueries";
import { useCallback, useEffect, useState } from "react";

const values: any = {
  backgroundSize: (value: number) =>
    isMobile()
      ? window.outerHeight / 1.8 + value * 2 + "px"
      : window.outerHeight / 1.2 + value * 2 + "px",
  backgroundPositionX: (value: number) =>
    isMobile() ? (-60 + value / 2) / 2 + "vw" : (50 + value / 2) / 2 + "vw",
};

const useScroll = (elementId: string, cssPropertyNames: string[]) => {
  const handleScroll = useCallback((e: any) => {

    const sectionEl = document.getElementById(elementId);
    if (sectionEl) {
      const percent = window.outerHeight / 100;
      const value = +(
        sectionEl.getBoundingClientRect().bottom / percent
      ).toFixed(1);
      for (let i = 0; i <= cssPropertyNames.length; i++) {
        sectionEl.style[cssPropertyNames[i] as any] =
          values[cssPropertyNames[i]]?.(value);
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  return 0;
};

export default useScroll;
