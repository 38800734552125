import dayjs from "dayjs";
import {
  signInWithPopup,
  GoogleAuthProvider,
  Auth,
  AuthCredential,
  updateProfile,
} from "firebase/auth";
import { useContext, useEffect } from "react";
import { auth } from "../../firebase";
import { UserRoleE } from "../../models/enums";
import { AuthContext } from "../../services/Auth";
import getUserById from "../../utils/queries/getUserById";

//@ts-ignore
import googleIcon from "../../assets/images/google.svg";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router";
import { RoutesE } from "../../routes/routes";

//@ts-ignore
import styles from "./styles.module.scss";

const GoogleSignInButton = () => {
  const { addNewUser, currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate(RoutesE.CLIENT_SUBSCRIPTIONS);
    }
  }, [currentUser]);

  const signInWithGoogle = async (auth: Auth) => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const credential: AuthCredential | null =
        GoogleAuthProvider.credentialFromResult(result);
      const user = result.user;
      const existingUser = await getUserById(user.uid);

      if (!existingUser) {
        await addNewUser!(user.uid, {
          email: user.email,
          fullName: user.displayName,
          birthDate: "",
          role: UserRoleE.CLIENT,
          registrationDate: dayjs().toISOString(),
          subscriptions: [],
          phone: user.phoneNumber,
          photo: user.photoURL,
          visits: 0,
        });
        await updateProfile(user, {
          displayName: UserRoleE.CLIENT + "",
        });
        window.location.pathname = RoutesE.HOME;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return (
    <IconButton onClick={() => signInWithGoogle(auth)}>
      <img src={googleIcon} className={styles.icon} />
    </IconButton>
  );
};

export default GoogleSignInButton;
