import { Button, Paper } from "@mui/material";
import { Dayjs } from "dayjs";
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import RegisterForm from "../../components/RegistrationForm/RegistrationForm";
import { AuthContext } from "../../services/Auth";

//@ts-ignore
import styles from "./styles.module.scss";

type FormDataT = {
  email: string;
  password: string;
  birthDate: Dayjs;
  fullName: string;
};

const SignUp = () => {
  const { signUp } = useContext(AuthContext);
  const navigate = useNavigate();

  const onSubmit = ({ email, password, ...rest }: FormDataT) => {
    const trimmedEmail = email?.trim().toLowerCase();
    const trimmedPassword = password?.trim();
    if (!trimmedEmail || !trimmedPassword) {
      return toast.error("Невірний пароль або email");
    }

    signUp!(trimmedEmail, trimmedPassword, {
      ...rest,
      fullName: rest.fullName.trim(),
      email: trimmedEmail,
      birthDate: rest.birthDate.toISOString(),
    });
  };

  return (
    <div className={styles.wrapper}>
      <h1>Реєстрація</h1>
      <div className={styles.formWrapper}>
        <Paper elevation={3} className={styles.paper}>
          <RegisterForm onSubmit={onSubmit} />
        </Paper>
        <Button onClick={() => navigate("/")} sx={{ color: "#fff" }}>
          На головну
        </Button>
      </div>
    </div>
  );
};

export default SignUp;
