import React, { FC } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Alert, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { GroupTypeE, SubscriptionTypeE } from "../../models/enums";
import { SubscriptionT } from "../../models/models";
import usePopup from "../../utils/hooks/usePopup";
import { VISITS_AMOUNT } from "../../utils/constants";

//@ts-ignore
import styles from "./styles.module.scss";
import { isMobileOrTablet } from "../../utils/helpers/mediaQueries";

interface FormDataI extends Partial<SubscriptionT> {}

type CreateEditSubscriptionPropTypes = {
  item?: SubscriptionT;
  onCreate?: (data: Partial<SubscriptionT>) => void;
  onUpdate?: (id: string, data: any) => void;
};

const CreateEditSubscription: FC<CreateEditSubscriptionPropTypes> = (props) => {
  const { item, onCreate, onUpdate } = props;
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormDataI>({
    defaultValues: item,
  });
  const { removeAllPopups } = usePopup();

  const onSubmit = async (data: FormDataI) => {
    if (!item) {
      await onCreate?.(data);
    }
    const { id, ...formData } = data;
    await onUpdate?.(item?.id as string, formData);
    removeAllPopups();
  };
  return (
    <>
      <Alert severity="info" sx={{ margin: 2 }}>
        {
          'При редагуванні поля "Опис" ви можете використовувати послідовність символів "<br>", щоб розбити текст на абзаци.'
        }
      </Alert>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="subscription-form"
        className={styles.wrapper}
      >
        <Controller
          name="label"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Назва"
              variant="outlined"
              defaultValue={item?.label}
            />
          )}
        />
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="type-select-label" className={styles.label}>
                Тип занять
              </InputLabel>
              <Select
                {...field}
                labelId="type-select-label"
                id="type-select"
                defaultValue={item?.type}
              >
                <MenuItem value={SubscriptionTypeE.BALLET}>Балет</MenuItem>
                <MenuItem value={SubscriptionTypeE.STRETCHING}>
                  Стретчінг
                </MenuItem>
                <MenuItem value={SubscriptionTypeE.POINTES}>Пуанти</MenuItem>
                <MenuItem value={SubscriptionTypeE.MIX}>Мікс</MenuItem>
                <MenuItem value={SubscriptionTypeE.ANY}>На вибір</MenuItem>
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Опис"
              variant="outlined"
              defaultValue={item?.description}
              multiline
              rows={8}
              sx={!isMobileOrTablet() ? { gridArea: "2 / span 2" } : undefined}
            />
          )}
        />
        <Controller
          name="price"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={`Ціна (за ${VISITS_AMOUNT} занять)`}
              variant="outlined"
              defaultValue={item?.price}
            />
          )}
        />
        <Controller
          name="duration"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Тривалість (хв.)"
              variant="outlined"
              type="number"
              defaultValue={item?.duration}
              inputProps={{
                step: 15,
              }}
            />
          )}
        />
        <Controller
          name="groupType"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="group-type-select-label" className={styles.label}>
                Кільк. учасників
              </InputLabel>
              <Select
                {...field}
                labelId="group-type-select-label"
                id="group-type-select"
                defaultValue={item?.groupType}
              >
                <MenuItem value={GroupTypeE.INDIVIDUAL}>Індивідуально</MenuItem>
                <MenuItem value={GroupTypeE.SPLIT}>Спліт</MenuItem>
                <MenuItem value={GroupTypeE.GROUP}>Група</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </form>
    </>
  );
};

export default CreateEditSubscription;
