import React, { FC, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { BasketItemT, SubscriptionT } from "../../models/models";
import { AuthContext } from "../../services/Auth";
import usePopup from "../../utils/hooks/usePopup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import ItemAddedPopup from "../ItemAddedToBasketPopup/index";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BasketContext } from "../../services/Basket";
import SubscribePopup from "../SubscribePopup/SubscribePopup";
import { DesktopDatePicker } from "@mui/x-date-pickers";

//@ts-ignore
import styles from "./styles.module.scss";
import { VISITS_AMOUNT } from "../../utils/constants";

interface FormData {
  numberOfLessons: string;
  duration: string;
}

type OrderSubPropTypes = {
  subscription: SubscriptionT;
};

const schema = yup.object().shape({
  numberOfLessons: yup.number().required("Обов'язкове поле."),
});

const OrderSub: FC<OrderSubPropTypes> = (props) => {
  const { subscription } = props;
  const { currentUser } = useContext(AuthContext);
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    register,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      numberOfLessons: '1',
    },
  });
  const [date, setDate] = useState(dayjs());
  const [price, setPrice] = useState(0);
  const { createPopup, removeAllPopups } = usePopup();
  const { addItem } = useContext(BasketContext);

  const onSubmit = (data: FormData) => {
    const newItem: BasketItemT = {
      visits: +data.numberOfLessons,
      subscriptionId: subscription.id as string,
      startDate: date.toISOString(),
      price,
    };
    //TODO: add item to basket
    // addItem(newItem);
    // createPopup(ItemAddedPopup);
    createPopup({
      header: <>Замовити абонемент</>,
      body: (
        <SubscribePopup
          subscription={
            { ...subscription, ...newItem } as unknown as SubscriptionT
          }
        />
      ),
      footer: <Button onClick={removeAllPopups}>Закрити</Button>,
    });
  };

  const priceArray = subscription.price.split(",");
  const selectedValue = watch("numberOfLessons");

  useEffect(() => {
    const visitIndex = VISITS_AMOUNT.findIndex((val) => val === +selectedValue);
    setPrice(+priceArray[visitIndex]);
  }, [selectedValue]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.wrapper}
      id="add-to-basket"
    >
      <FormControl
        variant="outlined"
        fullWidth
        error={!!errors.numberOfLessons}
      >
        <InputLabel id="number-of-lessons-label">Кількість занять</InputLabel>
        <Controller
          name="numberOfLessons"
          control={control}
          rules={{ required: true }}
          defaultValue=""
          render={({ field }) => (
            <Select
              {...field}
              labelId="number-of-lessons-label"
              label="Кількість занять"
            >
              {priceArray.map((price, index) =>
                !!parseInt(price) ? (
                  <MenuItem key={index} value={VISITS_AMOUNT[index]}>
                    {VISITS_AMOUNT[index]}
                  </MenuItem>
                ) : null
              )}
            </Select>
          )}
        />
        {errors.numberOfLessons && (
          <FormHelperText>Вкажіть кількість занять</FormHelperText>
        )}
      </FormControl>
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
        {/*@ts-ignore*/}
        {/* <DesktopDatePicker */}
          {/* label="Початок занять" */}
          {/* inputFormat="DD.MM.YYYY" */}
          {/* value={date} */}
          {/* onChange={setDate as any} */}
          {/* // onBlur={onBlur} */}
          {/* renderInput={(params) => <TextField {...params} />} */}
        {/* /> */}
      {/* </LocalizationProvider> */}
      {!!price && <h2>Ціна: {price} грн.</h2>}
    </form>
  );
};

export default OrderSub;
