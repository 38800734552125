type ThrottleOptions = {
  leading?: boolean;
  trailing?: boolean;
};

const throttle = <T extends any[]>(
  func: (...args: T) => void,
  wait: number,
  options?: ThrottleOptions
): ((...args: T) => void) => {
  let timeout: ReturnType<typeof setTimeout> | null;
  let previous = 0;

  const leading = options?.leading ?? true;
  const trailing = options?.trailing ?? true;

  const throttled = (...args: T) => {
    const now = Date.now();
    if (!previous && !leading) previous = now;
    const remaining = wait - (now - previous);
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      func(...args);
    } else if (trailing && !timeout) {
      timeout = setTimeout(() => {
        previous = leading ? 0 : Date.now();
        timeout = null;
        func(...args);
      }, remaining);
    }
  };

  return throttled;
};

export default throttle;
