import React, { FC } from "react";
import { IconButton } from "@mui/material";
import { isMobileOrTablet } from "../../utils/helpers/mediaQueries";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { RoutesE } from "../../routes/routes";
import { NavLink } from "react-router-dom";

//@ts-ignore
import styles from "./styles.module.scss";

type ClientNavPropTypes = {
  mobileNavX: number;
  toggleVisibility: () => void;
};

const ClientNav: FC<ClientNavPropTypes> = (props) => {
  const { mobileNavX, toggleVisibility } = props;

  const Nav = () => (
    <nav>
      {mobileNavX === 0 && (
        <IconButton
          color="primary"
          aria-label="menu"
          className={styles.closeMenuIcon}
          onClick={toggleVisibility}
        >
          <CloseIcon sx={{ color: "#fff" }} />
        </IconButton>
      )}
      <ul>
        <li>
          <NavLink
            to={RoutesE.SUBSCRIPTIONS}
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            Мої абонементи
          </NavLink>
        </li>
        <li>
          <NavLink
            to={RoutesE.CLIENT_SUBSCRIPTIONS}
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            Наявні абонементи
          </NavLink>
        </li>
        <li>
          <NavLink
            to={RoutesE.CLIENT_NEWS}
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            Новини
          </NavLink>
        </li>
        <li>
          <NavLink
            to={RoutesE.CLIENT_CONTACTS}
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            Контакти
          </NavLink>
        </li>
        <li>
          <NavLink
            to={RoutesE.PROFILE}
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            Профіль
          </NavLink>
        </li>
      </ul>
    </nav>
  );
  return isMobileOrTablet() ? (
    <>
      <div className={styles.mobileNawWrapper}>
        <div className={styles.mobileMenuHeader}>
          <IconButton
            color="primary"
            aria-label="menu"
            className={styles.mobileMenuIcon}
            onClick={toggleVisibility}
          >
            <MenuIcon sx={{ color: "#fff" }} />
          </IconButton>
        </div>
        <div className={styles.mobileNav} style={{ left: `${mobileNavX}%` }}>
          <Nav />
        </div>
      </div>
    </>
  ) : (
    <div className={styles.navWrapper}>
      <Nav />
    </div>
  );
};

export default ClientNav;
