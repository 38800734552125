import { Dispatch, SetStateAction, useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

//@ts-ignore
import styles from "./styles.module.scss";

interface Props {
  onSearch: (searchTerm: string) => void;
}

export const SearchBar = ({ onSearch }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
    setSearchTerm(event.target.value);
  };

  return (
    <TextField
      placeholder="Пошук"
      value={searchTerm}
      onChange={handleSearch}
      className={styles.field}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
