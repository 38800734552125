import React from "react";
import AnimatedText from "react-animated-text-content";

//@ts-ignore
import styles from "./styles.module.scss";

const AboutUsSection = () => {
  // const scrollValue = useScroll("about-us-section", ["backgroundPositionX"]);

  return (
    <div className={styles.wrapper} id="about-us-section">
      <div className={styles.aboutDescription}>
        {/* <h2 id="about-us-header">Про нас</h2> */}
        <AnimatedText
          type="words"
          animation={{
            x: "200px",
            y: "-20px",
            scale: 1.1,
            ease: "ease-in-out",
          }}
          animationType="float"
          interval={0.3}
          duration={0.6}
          tag="h2"
          includeWhiteSpaces
          threshold={1}
          rootMargin="0%"
        >
          Про нас
        </AnimatedText>
        <div className={styles.comment}>
          {/* <h3 className={styles.greeting}>
            Привіт! Давай знайомитись!
          </h3> */}
          <h3>Ми — перший балетний простір в Україні.</h3>
          <p>
            <em>WhyNotBallet</em> ми створили для того, щоб довести, що мрії
            збуваються!
          </p>
          <p>
            Маючи великий власний досвід та бажання в дорослому віці займатись
            балетом ми чудово розуміємо всі ваші сумніви та страхи, тож тепер
            намагаємось дати можливість всім іншим дівчатам втілити свої бажання
            в реальність!
          </p>
          <p>
            Для нас важливо знайти індивідуальний підхід до кожного. Надати свою
            підтримку, мотивацію та відкрити світ балету з середини!
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
