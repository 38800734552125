import { Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";
import { RoutesE } from "../../routes/routes";
import { useEffect, useRef, useState } from "react";
import { sendEmailVerification } from "firebase/auth";
import { auth } from "../../firebase";

//@ts-ignore
import styles from "./styles.module.scss";

const MAX_TIME = 60;

const EmailNotVerified = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const user = auth.currentUser;

  useEffect(() => {
    if (timeLeft <= 0 && timerRef.current) {
      clearInterval(timerRef.current!);
    }
  }, [timeLeft]);

  const handleResendEmailVerification = async () => {
    if (!user) return;

    setIsLoading(true);
    await sendEmailVerification(user);
    setIsLoading(false);
    setTimeLeft(MAX_TIME);
    timerRef.current = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
  };
  return (
    <div className={styles.wrapper}>
      <h2>Email не верифіковано</h2>
      <p>
        На вашу email адресу було відправлено лист з підтвердженням. Будь ласка,
        перевірте свою електронну пошту та дотримуйтесь інструкцій для
        підтвердження email.
      </p>
      <p>
        Якщо ви не бачите лист, перевірте розділ "Спам"
        {user && user.emailVerified ? (
          <>
            <span>, або </span>
            <Button
              onClick={handleResendEmailVerification}
              disabled={isLoading || !!(timeLeft && timeLeft < MAX_TIME)}
              startIcon={
                isLoading && (
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                )
              }
            >
              відправте листа знову {timeLeft ? `через ${timeLeft} секунд` : ""}
            </Button>
          </>
        ) : (
          <span>.</span>
        )}
      </p>
      <Button
        onClick={() => navigate(RoutesE.LOGIN)}
        variant="contained"
        sx={{ color: "#fff" }}
      >
        Повернутись на сторінку входу
      </Button>
    </div>
  );
};

export default EmailNotVerified;
