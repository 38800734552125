import { ClientSubscriptionT } from "./../../models/models";
import { getFirestore, doc, updateDoc } from "firebase/firestore";

const updateClientSubscription = async (
  userId: string,
  subscriptionId: string,
  payload: Partial<ClientSubscriptionT>,
  subscriptions: ClientSubscriptionT[]
) => {
  const db = getFirestore();
  const userRef = doc(db, "users", userId);

  const updatedSubscriptions = subscriptions.map(
    (subscription: ClientSubscriptionT) => {
      if (subscription.id === subscriptionId) {
        return {
          ...subscription,
          ...payload,
        };
      }
      return subscription;
    }
  );

  await updateDoc(userRef, {
    subscriptions: updatedSubscriptions,
  });
};

export default updateClientSubscription;
