import React from 'react';
//@ts-ignore
import spinner from '../../assets/images/spinner.svg';

//@ts-ignore
import styles from './styles.module.scss';

const LoadingScreen = () => {
    return (
        <div className={styles.wrapper}>
            <img src={spinner} alt="loading" />
        </div>
    );
};

export default LoadingScreen;