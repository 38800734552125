import React from 'react';
import ClientPageWrapper from '../../components/ClientPageWrapper/ClientPageWrapper';
import ContactsSection from '../Promo/sections/ContactsSection/ContactsSection';

const ClientContacts = () => {
    return (
        <ClientPageWrapper>
            <ContactsSection/>
        </ClientPageWrapper>
    );
};

export default ClientContacts;