import React, { FC, FormEventHandler, useContext, useState } from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import dayjs, { Dayjs } from "dayjs";
import { TextField } from "@mui/material";
import { InputLabel } from "@material-ui/core";
import addVisitAdmin from "../../utils/queries/addVisitAdmin";
import { ClientSubscriptionT } from "../../models/models";
import { toast } from "react-toastify";
import usePopup from "../../utils/hooks/usePopup";
import { FirebaseContext } from "../../services/Firebase";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type AddVisitAdminPropTypes = {
  userId: string;
  subscriptionId: string;
  subscriptions: ClientSubscriptionT[];
  getClientSubscriptions: () => void;
};

const AddVisitAdmin: FC<AddVisitAdminPropTypes> = (props) => {
  const { userId, subscriptionId, subscriptions, getClientSubscriptions } =
    props;
  const [date, setDate] = useState<Dayjs>(dayjs());
  const { removePopup } = usePopup();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await addVisitAdmin(userId, subscriptionId, date.toISOString(), subscriptions);
    toast("Відвідування додано", {
      type: "success",
      autoClose: 3000,
    });
    await getClientSubscriptions();
    const refetchUsers = new CustomEvent("refetch-users");
    document.dispatchEvent(refetchUsers);
    removePopup();
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          label="Дата відвідування"
          inputFormat="DD-MM-YYYY"
          value={date}
          onChange={setDate as any}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <form id="add-visit-admin" onSubmit={handleSubmit}></form>
    </div>
  );
};

export default AddVisitAdmin;
