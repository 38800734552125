import { memo } from "react";

//@ts-ignore
import styles from "./styles.module.scss";

const MessengerBrowserNotAllowed = memo(() => {
  return (
    <div className={styles.wrapper}>
      <p>
        Для коректної роботи сайту, будь ласка, відкрийте його у звичайному
        браузері.
      </p>
    </div>
  );
});

export default MessengerBrowserNotAllowed;
