import React, { FC } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import dayjs from "dayjs";
import getDateFromTimestamp from "../../utils/helpers/getDateFromTimestamp";

//@ts-ignore
import styles from "./styles.module.scss";

interface Props {
  items: any[];
  data: {
    value: string;
    label: string;
    type?: "date";
    list?: any;
  }[];
  actions?: {
    icon: any;
    label: string;
    action: any;
  }[];
}

const DesktopAdminGrid: FC<Props> = (props) => {
  const { items, data, actions } = props;
  return (
    <TableContainer component={Paper}>
      <Table className={styles.table} aria-label="user table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {data.map(({ label }, index) => (
              <TableCell key={label + index}>{label}</TableCell>
            ))}
            <TableCell width={180}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={item?.id + index} className={styles.tableRow}>
              <TableCell>{index + 1}. </TableCell>
              {data.map(({ value, label, type, list }, dataIndex) => (
                <TableCell key={value + dataIndex}>
                  {type === "date"
                    ? dayjs(item[value]).format("DD.MM.YYYY")
                    : type === "timestamp"
                    ? getDateFromTimestamp(item[value]).format("DD.MM.YYYY")
                    : list
                    ? list[item[value]]
                    : item[value]}
                </TableCell>
              ))}
              <TableCell className={styles.actions}>
                {actions?.map((action) => action.icon(item) as any)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DesktopAdminGrid;
