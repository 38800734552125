const scrollIFSectionVisible = (section: Element) => {
  const topOffset = section?.getBoundingClientRect()?.top;
  const screenHeight = window.innerHeight;
  const endEdge = 0 - screenHeight;
  const startEdge = screenHeight;

  if (topOffset < startEdge && topOffset > endEdge) {
    section.scrollIntoView({
      behavior: "smooth",
    });
  }
};

export default scrollIFSectionVisible;
