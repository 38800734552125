import React, { FC, ReactNode } from "react";
import FloatingSubscribeButton from "../FloatingSubscribeButton/FloatingSubscribeButton";
import ClientHeader from "./components/ClientHeader/ClientHeader";

//@ts-ignore
import styles from "./styles.module.scss";

type ClientPageWrapperPropTypes = {
  children: ReactNode;
};

const ClientPageWrapper: FC<ClientPageWrapperPropTypes> = (props) => {
  const { children } = props;

  return (
    <div className={styles.wrapper}>
      <ClientHeader />
      {children}
      <FloatingSubscribeButton />
    </div>
  );
};

export default ClientPageWrapper;
