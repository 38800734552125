import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { RoutesE } from "../../routes/routes";

//@ts-ignore
import styles from "./styles.module.scss";

const DeleteAccount = () => {
  const navigate = useNavigate();
  
  return (
    <div className={styles.wrapper}>
      <h2>Видалення аккаунту</h2>
      <p>
        Для видалення аккаунту зв'яжіться з адміністратором балетної студії або
        напишіть листа з вказаною темою "Видалення аккаунту" на адресу{" "}
        <a href="mailto:whynotballet.official@gmail.com">
          whynotballet.official@gmail.com
        </a>
      </p>
      <Button onClick={() => navigate(RoutesE.HOME)}>На головну</Button>
    </div>
  );
};

export default DeleteAccount;
