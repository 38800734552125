import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { NewsT } from "../../../../models/models";
import { Pagination } from "swiper";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import useFirestore from "../../../../utils/hooks/useFirestoreCollection";
import SwipeHand from "../../../../components/SwipeHand/SwipeHand";
import SwiperNav from "../../../../components/SwiperNav/SwiperNav";
import { isMobileOrTablet } from "../../../../utils/helpers/mediaQueries";
import scrollIFSectionVisible from "../../../../utils/helpers/scrollIFSectionVisible";
import getDateFromTimestamp from "../../../../utils/helpers/getDateFromTimestamp";

//@ts-ignore
import styles from "./styles.module.scss";
import AnimatedText from "react-animated-text-content";

const NewsSection = () => {
  const [isShown, setIsShown] = useState(!localStorage.getItem("news-section"));
  const { items } = useFirestore<NewsT>("news");

  const handleSlideChange = () => {
    localStorage.setItem("news-section", "news-section");
    setIsShown(false);

    const section = document.getElementById("news-section");
    if (!section) return;
    scrollIFSectionVisible(section);
  };
  return (
    <div className={styles.wrapper} id="news-section">
      <div className={styles.blurWrapper}>
        {isShown && <SwipeHand />}
        <h2 className={styles.header}>Новини</h2>
        <Swiper
          onSlideChange={handleSlideChange}
          slidesPerView={1}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          grabCursor
        >
          {!isMobileOrTablet() && <SwiperNav />}
          {items.map((item, index) => (
            <SwiperSlide
              className={styles.swiperSlide}
              key={index}
              style={{ backgroundImage: `url(${item.image})` }}
            >
              <div className={styles.newsWrapper}>
                <img src={item.image} alt="" className={styles.newsSplash} />
                <AnimatedText
                  type="words"
                  animation={{
                    x: "200px",
                    y: "-20px",
                    scale: 1.1,
                    ease: "ease-in-out",
                  }}
                  animationType="rifle"
                  interval={0.03}
                  duration={0.6}
                  tag="h3"
                  includeWhiteSpaces
                  threshold={1}
                  rootMargin="0%"
                >
                  {item.title}
                </AnimatedText>
                {item.article.split("<br>").map((text) => (
                  <p>{text}</p>
                ))}
                <p className={styles.time}>
                  {getDateFromTimestamp(item.creationDateTime).format(
                    "DD.MM.YYYY HH:mm"
                  )}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default NewsSection;
