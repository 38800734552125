import React, { useState } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import Location from "@mui/icons-material/LocationOn";
import Phone from "@mui/icons-material/Phone";
import Email from "@mui/icons-material/Email";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
//@ts-ignore
import titkok from "../../../../assets/images/tiktok.png";
import { RoutesE } from "../../../../routes/routes";

//@ts-ignore
import styles from "./styles.module.scss";

const ContactsSection = () => {
  return (
    <div className={styles.wrapper} id="contacts-section">
      <h2>Контакти</h2>
      <ul>
        <li>
          <Phone />
          <a href="tel:+380971837000">097 183 7000</a>
        </li>
        <li>
          <Email />
          <a href="mailto:whynotballet.official@gmail.com">
            whynotballet.official@gmail.com
          </a>
        </li>
        <li>
          <Location />
          <a target="_blank" href="https://maps.app.goo.gl/vfmPE4UjnEtjEfdD8" rel="noreferrer">
            М.Київ, вул.Велика Васильківська, 34
          </a>
        </li>
        <li>
          <InstagramIcon />
          <a target="_blank" href="https://instagram.com/whynotballet" rel="noreferrer">
            Instagram
          </a>
        </li>
        <li>
          <img src={titkok} alt="tiktok" className={styles.tiktok} />
          <a
            target="_blank"
            href="https://www.tiktok.com/@whynotballet?_t=8ZxSQRm4nkT&_r=1" rel="noreferrer"
          >
            TikTok
          </a>
        </li>
        <li>
          <TextSnippetIcon />
          <a target="_blank" href={RoutesE.PUBLIC_OFFER} rel="noreferrer">
            Публічна оферта
          </a>
        </li>
      </ul>
      <div className={styles.author}>
        <span>Автор сайту: </span>
        <a href="https://www.instagram.com/ivanp.tv" target="_blank" rel="noreferrer">
          Іван Пятоволенко
        </a>
      </div>
    </div>
  );
};

export default ContactsSection;
