import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { FC, useEffect } from "react";
import { useSwiper } from "swiper/react";
import FrameAnimation from "../../../../../../components/FrameAnimation/FrameAnimation";
import { SubscriptionT } from "../../../../../../models/models";
import OrderSub from "../../../../../../Popups/OrderSub/OrderSub";
import usePopup from "../../../../../../utils/hooks/usePopup";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import Groups2Icon from "@mui/icons-material/Groups2";
import SubscribePopup from "../../../../../../Popups/SubscribePopup/SubscribePopup";
import dayjs from "dayjs";

//@ts-ignore
import styles from "./styles.module.scss";
import { isMobileOrTablet } from "../../../../../../utils/helpers/mediaQueries";

type SubscriptionSlidePropTypes = {
  sub: SubscriptionT;
};

const SubscriptionSlide: FC<SubscriptionSlidePropTypes> = (props) => {
  const { sub } = props;
  const swiper = useSwiper();
  const { createPopup, removePopup, removeAllPopups } = usePopup();

  const handleOrder = () => {
    createPopup({
      header: <>{`Виберіть параметри`}</>,
      body: <OrderSub subscription={sub} />,
      footer: (
        <>
          <Button onClick={removePopup}>Закрити</Button>
          {/* <Button
            type="submit"
            form="add-to-basket"
            variant="contained"
            sx={{ color: "#fff" }}
          >
            Додати до кошика
            Замовити
          </Button> */}
        </>
      ),
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <h3>{sub.label}</h3>
        <div className={styles.timeWrapper}>
          <AccessTimeIcon sx={{ color: "#fff", fontSize: "3rem" }} />
          <span>{sub.duration}хв.</span>
        </div>
        <div className={styles.groupWrapper}>
          {sub.groupType === 0 && (
            <PersonIcon sx={{ color: "#fff", fontSize: "3rem" }} />
          )}
          {sub.groupType === 1 && (
            <PeopleIcon sx={{ color: "#fff", fontSize: "3rem" }} />
          )}
          {sub.groupType === 2 && (
            <Groups2Icon sx={{ color: "#fff", fontSize: "3rem" }} />
          )}
        </div>
        <div className={styles.description}>
          {sub.description.split("<br>").map((par, index) => (
            <p key={index}>{par}</p>
          ))}
        </div>
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            sx={{
              color: "#9bb6f8",
              background: "#fff",
              margin: isMobileOrTablet() ? 4 : undefined,
            }}
            onClick={handleOrder}
            className={styles.orderButton}
          >
            Вартість
          </Button>
        </Box>
      </div>
      <FrameAnimation animationName={sub.label} />
      <div
        id="frame-animation-trigger"
        style={{ transform: "translateY(60vh)" }}
      ></div>
    </div>
  );
};

export default SubscriptionSlide;
