import IconButton from "@mui/material/IconButton";
import React, { FC, ReactElement, useState } from "react";
import { clss } from "../../utils/helpers/styleHelpers";
import usePopup from "../../utils/hooks/usePopup";
import CloseIcon from "@mui/icons-material/Close";

//@ts-ignore
import styles from "./popup.module.scss";

interface PropTypes {
  header?: ReactElement;
  body: ReactElement;
  footer?: ReactElement;
  className?: string;
  handleClose?: () => void;
}

enum AnimationTypeE {
  IN = "in",
  OUT = "out",
}

/**
 * Define popup window
 * */

const Popup: FC<PropTypes> = (props) => {
  const { header, body, footer = "", className = "" } = props;

  // animation state
  const [animState, setAnimState] = useState<AnimationTypeE>(AnimationTypeE.IN);
  const { removePopup } = usePopup();

  const onClose = () => {
    // change animation
    setAnimState((prev) =>
      prev === AnimationTypeE.IN ? AnimationTypeE.OUT : prev
    );
    // close popup after animation end
    removePopup();
  };

  return (
    <div
      // overlay layer
      className={clss([styles.popupBackground, styles[`fade-${animState}`]])}
      // onClick={(e) => e.target === e.currentTarget && onClose()}
      id="popup-overlay"
    >
      <div
        // popup window
        className={clss([
          styles.modal,
          className,
          styles[`slide-${animState}-top`],
        ])}
      >
        {header && (
          <div className={styles.modalHeader}>
            <div>{header}</div>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{ color: "#fff", height: 40, width: 40 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
        <span
          className={styles.modalClose}
          onClick={onClose}
          id="popup-close-icon"
        />
        <div className={styles.modalBody}>{body}</div>
        {footer && <div className={styles.modalFooter}>{footer}</div>}
      </div>
    </div>
  );
};

export default Popup;
