import React, { FC } from "react";
import { UserT } from "../../../../models/models";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, MenuItem } from "@mui/material";
import usePopup from "../../../../utils/hooks/usePopup";
import InputMask from "react-input-mask";

//@ts-ignore
import styles from "./styles.module.scss";

type CreateEditUserPropTypes = {
  item?: UserT;
  updateItem: (id: string, item: UserT) => void;
};

const schema = yup.object().shape({
  fullName: yup.string().required("Прізвище та ім'я обов'язкові"),
  email: yup
    .string()
    .required("Email обов'язковий")
    .email("Email має бути валідним"),
  role: yup
    .number()
    .oneOf([0, 1, 2, 3])
    .required("Тип користувача обов'язковий"),
  phone: yup.string(),
  comment: yup.string(),
});

const CreateEditUser: FC<CreateEditUserPropTypes> = (props) => {
  const { item, updateItem } = props;
  const { removePopup } = usePopup();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserT>({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  const onSubmit = async (data: UserT) => {
    await updateItem(item!.id, data);
    removePopup();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.wrapper}
      id="update-user"
    >
      <TextField
        {...register("fullName")}
        label="Прізвище та ім'я"
        error={!!errors.fullName}
        helperText={errors?.fullName?.message}
      />
      <TextField
        {...register("email")}
        label="Email"
        error={!!errors.email}
        helperText={errors?.email?.message}
      />
      <TextField
        {...register("role")}
        label="Тип"
        select
        error={!!errors.role}
        helperText={errors?.role?.message}
        defaultValue={item?.role}
      >
        <MenuItem value={0}>Адміністратор</MenuItem>
        <MenuItem value={1}>Менеджер</MenuItem>
        <MenuItem value={2}>Клієнт</MenuItem>
        <MenuItem value={3}>Вчитель</MenuItem>
      </TextField>
      <Controller
            control={control}
            name={"phone"}
            render={({ field }) => (
              <InputMask
                mask="+38 (099) 999 99 99"
                value={field.value}
                onChange={field.onChange}
              >
                {/*@ts-ignore*/}
                {() => <TextField label="Телефон" sx={{ width: "100%" }} />}
              </InputMask>
            )}
          />
      <TextField
        {...register("comment")}
        label="Коментар"
        error={!!errors.comment}
        helperText={errors?.comment?.message}
      />
    </form>
  );
};

export default CreateEditUser;
