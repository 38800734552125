import dayjs from "dayjs";
import { getFirestore, doc, updateDoc, arrayUnion } from "firebase/firestore";

const addSubscriptionAdmin = async (
  userId: string,
  subscription: { id: string; expireDate: string }
) => {
  const db = getFirestore();
  const userRef = doc(db, "users", userId);
  
  await updateDoc(userRef, {
    subscriptions: arrayUnion({ ...subscription, visitsPassed: [] }),
  });
};

export default addSubscriptionAdmin;
