import React, { FC, useContext, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router";
import { auth } from "../../firebase";
import { RoutesE } from "../../routes/routes";
import { AuthContext } from "../../services/Auth";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { UserRoleE } from "../../models/enums";
import { isMobileOrTablet } from "../../utils/helpers/mediaQueries";

//@ts-ignore
import styles from "./styles.module.scss";
import usePopup from "../../utils/hooks/usePopup";
import MarkVisitAdmin from "../../Popups/MarkVisitAdmin/MarkVisitAdmin";
import { Button } from "@mui/material";

type HeaderPropTypes = {
  open: boolean;
  handleDrawerOpen: () => void;
};

const Header: FC<HeaderPropTypes> = (props) => {
  const { open, handleDrawerOpen } = props;
  const { currentUser, logOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [title, setTitle] = useState("WhyNotBallet ");
  const { createPopup, removePopup } = usePopup();

  useEffect(() => {
    setTitle(document.title);
  }, [document.title]);

  const handleSignUp = () => {
    navigate(RoutesE.SIGN_UP);
  };

  const handleChange = async () => {
    if (currentUser) {
      try {
        await signOut(auth);
      } catch (err) {
        console.log(err);
      }
    } else {
      navigate(RoutesE.LOGIN);
    }
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigateToProfile = () => {
    setAnchorEl(null);
    navigate(RoutesE.PROFILE);
  };

  const handleSignOut = async () => {
    await logOut!();
  };

  const scanQrCode = () => {
    createPopup({
      header: <>Відмітити відвідування</>,
      body: <MarkVisitAdmin />,
      footer: <Button onClick={removePopup}>Закрити</Button>,
    });
  };

  return (
    <Toolbar className={styles.toolbar}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 2, ...(open && { display: "none" }), color: "#fff" }}
      >
        <MenuIcon />
      </IconButton>
      <Typography
        variant="h6"
        component="div"
        sx={{ color: "#fff", flexGrow: 1 }}
      >
        {title}
      </Typography>
      {auth && (
        <div className={styles.profileWrapper}>
          {!isMobileOrTablet() && (
            <Typography
              variant="subtitle2"
              component="div"
              sx={{ flexGrow: 1, color: "#fff" }}
            >
              {currentUser?.fullName}
            </Typography>
          )}
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            sx={{ color: "#fff", padding: 1, margin: 0 }}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleNavigateToProfile}>Мій профіль</MenuItem>
            <MenuItem onClick={handleSignOut}>Вийти</MenuItem>
          </Menu>
          {currentUser?.role === UserRoleE.ADMIN && (
            <IconButton
              size="large"
              aria-label="scan qr code"
              aria-controls="menu-appbar"
              onClick={scanQrCode}
              color="inherit"
              sx={{ color: "#fff", padding: 1, margin: 0 }}
            >
              <QrCodeScannerIcon />
            </IconButton>
          )}
        </div>
      )}
    </Toolbar>
  );
};

export default Header;
