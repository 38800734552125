import { useEffect, useState } from "react";

const useBlockSiteOnMessengerBrowser = () => {
  const [isNotAllowed, setIsNotAllowed] = useState(false);

  useEffect(() => {
    if (window.navigator.userAgent.includes("Messenger")) {
      setIsNotAllowed(true);
    }
  }, []);

  return isNotAllowed;
};

export default useBlockSiteOnMessengerBrowser;
