import React, { FC, useEffect } from "react";
import { NewsT } from "../../../../models/models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, FormControl, Input, Alert } from "@mui/material";
import usePopup from "../../../../utils/hooks/usePopup";
import { Timestamp } from "firebase/firestore";

//@ts-ignore
import styles from "./styles.module.scss";
import dayjs from "dayjs";

type CreateEditNewsPropTypes = {
  item?: NewsT;
  updateItem?: (id: string, item: NewsT) => void;
  createItem?: (item: NewsT) => void;
};

const schema = yup.object().shape({
  title: yup.string().required("Заголовок обов'язковий"),
  article: yup.string().required("Текст обов'язковий"),
});

const CreateEditNews: FC<CreateEditNewsPropTypes> = (props) => {
  const { item, updateItem, createItem } = props;
  const { removePopup } = usePopup();
  const [file, setFile] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewsT>({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  useEffect(() => {
    if (item) {
      setFile(item.image as string);
    }
  }, [item]);

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = () => {
      setFile(reader.result as any);
    };
  };

  const onSubmit = async (data: NewsT) => {
    if (item) {
      await updateItem!(item!.id, { ...data, image: file });
    } else {
      await createItem!({ ...data, image: file, creationDateTime: Timestamp.fromDate(dayjs().toDate()) });
    }
    removePopup();
  };

  return (
    <>
      <Alert severity="info" sx={{ margin: 2 }}>
        {
          'При редагуванні поля "Текст" ви можете використовувати послідовність символів "<br>", щоб розбити текст на абзаци.'
        }
      </Alert>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.wrapper}
        id="update-news"
      >
        <TextField
          {...register("title")}
          label="Заголовок"
          error={!!errors.title}
          helperText={errors?.title?.message}
        />
        <TextField
          {...register("article")}
          label="Текст"
          error={!!errors.article}
          helperText={errors?.article?.message}
          multiline
          rows={8}
        />
        <FormControl>
          <Input
            id="photo-input"
            type="file"
            onChange={handleFileChange}
            inputProps={{ accept: "image/*" }}
          />
        </FormControl>
        {!!file && <img src={file} alt="" width={100} />}
      </form>
    </>
  );
};

export default CreateEditNews;
