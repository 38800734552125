import dayjs from "dayjs";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  Auth,
  AuthCredential,
  updateProfile,
  FacebookAuthProvider,
  getAdditionalUserInfo,
} from "firebase/auth";
import { useContext } from "react";
import { auth } from "../../firebase";
import { UserRoleE } from "../../models/enums";
import { AuthContext } from "../../services/Auth";
import getUserById from "../../utils/queries/getUserById";
//@ts-ignore
import facebookIcon from "../../assets/images/facebook.svg";
import { Button, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import { RoutesE } from "../../routes/routes";
import { useNavigate } from "react-router";

//@ts-ignore
import styles from "./styles.module.scss";

const FacebookSignInButton = () => {
  const { addNewUser } = useContext(AuthContext);
  const navigate = useNavigate();

  async function signInWithFacebook(auth: Auth) {
    const provider = new FacebookAuthProvider();
    
    try {
      const result = await signInWithPopup(auth, provider);
      const credential: AuthCredential | null =
        FacebookAuthProvider.credentialFromResult(result);
      const user = result.user;
      const existingUser = await getUserById(user.uid);

      if (!existingUser) {
        await addNewUser!(user.uid, {
          email: user.email,
          fullName: user.displayName,
          birthDate: "",
          role: UserRoleE.CLIENT,
          registrationDate: dayjs().toISOString(),
          subscriptions: [],
          phone: user.phoneNumber,
          photo: user.photoURL,
          visits: 0,
        });
        updateProfile(user, {
          displayName: UserRoleE.CLIENT + "",
        });
      }

      navigate(RoutesE.HOME);
    } catch (error: any) {
      toast.error(
        error?.code === "auth/operation-not-allowed"
          ? "Наразі авторизація через Facebook не працює"
          : error?.code
      );
      console.error(error);
      return null;
    }
  }

  return (
    <IconButton onClick={() => signInWithFacebook(auth)}>
      <img src={facebookIcon} className={styles.icon} />
    </IconButton>
  );
};

export default FacebookSignInButton;
