import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { RoutesE } from "../../routes/routes";

//@ts-ignore
import styles from "./styles.module.scss";

const DefaultPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(RoutesE.HOME);
  }, []);

  return <div className={styles.wrapper}></div>;
};

export default DefaultPage;
